import Types from "./Types";

export const LoginSuccess = (payload) => ({
  type: Types.LOGIN_SUCCESS,
  payload,
});
export const LogoutSuccess = (payload) => ({
  type: Types.LOGOUT_SUCCESS,
  payload,
});
export const OTPRequestSuccess = (payload) => ({
  type: Types.OTPREQUEST_SUCCESS,
  payload,
});
export const OTPVerifySuccess = (payload) => ({
  type: Types.OTPVERIFY_SUCCESS,
  payload,
});

export const ResetPasswordSuccess = (payload) => ({
  type: Types.RESETPASSWORD_SUCCESS,
  payload,
});



export const GetUserProfileSuccess = (payload) => ({
  type: Types.GET_USER_PROFILE,
  payload,
});

export const AuthLoading = (payload) => ({
  type: Types.AUTH_LOADING,
  payload,
});
