import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { formatDate } from "common/utils/helpers";
import MapDirectionsButton from "components/Atoms/Buttons/MapDirectionsButton";
import AvatarCard from "components/Molecules/Avatar/AvatarCard";
import Map from "components/Molecules/Map/GoogleMap";
import dayjs from "dayjs";

export const detailsData = ({ data }) => {
  const isEquipment = data?.equipment?.length > 0;
  const isParticipants = data?.participants?.length > 0;
  const mainParicipantId = data?.user?.id;

  return [
    {
      head: "Images",
      icon: icons?.imageIcon,
      images: data?.photos,
    },
    {
      head: "General details ",
      icon: icons?.detailsIcon,
      details: [
        {
          label: "Trip name ",
          name: data?.name,
        },
        {
          label: "Category ",
          name: data?.sub_category?.name,
        },
        {
          label: "Date / Duration ",
          name: (
            <>
              {formatDate(data?.start_datetime, "DD MMM YYYY")} - To {formatDate(data?.end_datetime, "DD MMM YYYY")},{" "}
              {data?.duration_days && data?.duration_days + " days"}
            </>
          ),
        },
        {
          label: "Start time ",
          name: <> {formatDate(data?.start_datetime, "h A")} </>,
        },
        {
          label: "Age range ",
          name: (
            <>
              From {data?.age_from} To {data?.age_to}
            </>
          ),
        },
        {
          label: "Group type ",
          name: (
            <>
              {data?.group_type} / {data?.number_of_seats}
            </>
          ),
        },
        {
          label: "Location",
          name: (
            <>
              {data?.city?.name}, {data?.country?.name}
            </>
          ),
        },
        {
          label: "Risk level",
          name: (
            <Stack direction="row" gap={2} alignItems="center">
              <Box component="img" src={icons[`${data?.risk_level}RiskLevel`]} />
              <Typography fontSize="18px" textTransform="capitalize">
                {data?.risk_level}
              </Typography>
            </Stack>
          ),
        },
        {
          label: "Expert",
          name: (
            <AvatarCard
              data={data?.expert}
              style={{
                avatar: { width: "64px", height: "64px" },
                name: { fontSize: "18px", fontWeight: 700 },
                content: { fontSize: "16px" },
              }}
              showWallet={false}
            />
          ),
        },
        data?.brochures?.[0]?.url && {
          label: "Brochure",
          name: data?.brochures?.[0]?.url && (
            <a href={data?.brochures[0]?.url} download target="_blank">
              <Stack
                sx={{ border: "1px solid black", borderRadius: "6px", width: "53%", p: 1, cursor: "pointer" }}
                direction="row"
                gap={2}
              >
                <Box component="img" src={icons.DownloadDocument} width="42px" />
                <Typography sx={{ lineHeight: "19px" }}>
                  Download attached a brochure that includes more details & comprehensive information
                </Typography>
              </Stack>
            </a>
          ),
        },
        {
          label: "Address",
          name: (
            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <Typography sx={{ fontSize: "18px" }}>{data?.full_address}</Typography>
                <MapDirectionsButton lat={data?.lat} long={data?.lng} />
              </Stack>
              <Map location={{ lat: data?.lat, lng: +data?.lng }} />
            </Stack>
          ),
        },
        {
          label: "Description",
          name: data?.description,
        },
      ],
    },
    isEquipment && {
      head: "Equipment",
      icon: icons.equipmentIcon,
      details: data?.equipment?.map((item) => {
        return {
          name: (
            <Stack direction="row" gap={1}>
              <Box component="img" src={item?.media?.[0]?.url} width="58px" height="58px" />
              <Stack justifyContent="space-between">
                <Typography fontSize="14px">{item?.name}</Typography>
                {item?.is_rentable && (
                  <Typography fontSize="14px">
                    $ {item?.rental_price} For Rent {item?.rent_quantity >= 0 && `/ Quantity :${item?.rent_quantity}`}
                  </Typography>
                )}
                {item?.is_purchasable && (
                  <Typography fontSize="14px">
                    $ {item?.purchase_price} For Buy {item?.purchase_quantity >= 0 && `/ Quantity :${item?.purchase_quantity}`}
                  </Typography>
                )}
              </Stack>
            </Stack>
          ),
        };
      }),
    },
    isParticipants && {
      head: "Participants ",
      icon: icons.Participants,
      details: data?.participants?.map((user) => {
        return {
          name: (
            <Stack direction="row" alignItems={"start"} gap={2}>
              <AvatarCard
                data={user}
                style={{
                  avatar: { width: "48px", height: "48px" },
                  name: { fontSize: "14px", fontWeight: 700 },
                  content: { fontSize: "12px" },
                }}
                showWallet={false}
                showRate={false}
              />
              {mainParicipantId == user?.id && (
                <Typography p={0.5} bgcolor="#FFF6EE" color="#FF7900" fontWeight={700} fontSize="14px">
                  Main Participant
                </Typography>
              )}
            </Stack>
          ),
        };
      }),
    },
  ];
};

// that I understand, what I'm talking about is that
