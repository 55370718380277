import { Stack } from "@mui/material";
import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import { detailsData } from "templates/experts/experts-list/details";

export default function Details({ details }) {
  return (
    <Stack>
      <DetailsUse
        data={{
          deleteBtn: false,
          editBtn: false,
          data: {
            list: detailsData({ data: details }),
          },
        }}
      />
    </Stack>
  );
}
