export const BaseURL = process.env.REACT_APP_BASE_URL;
export const GoogleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
//firebase
export const ApiKey = process.env.REACT_APP_API_KEY;
export const AuthDomain = process.env.REACT_APP_AUTH_DOMAIN;
export const DatabaseUrl = process.env.REACT_APP_DATABASE_URL;
export const ProjectId = process.env.REACT_APP_PROJECT_ID;
export const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
export const MessagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const AppId = process.env.REACT_APP_APP_ID;
export const MeasurementId = process.env.REACT_APP_MEASUREMENT_ID;
export const VapidKey = process.env.REACT_APP_VAPID_KEY;
