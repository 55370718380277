import Types from "./Types";

export const GetMainCategoriesSuccess = (payload) => ({
  type: Types.GET_MAIN_CATEGORIES_SUCCESS,
  payload,
});

export const GetMainCategoriesDetailSuccess = (payload) => ({
  type: Types.GET_MAIN_CATEGORIES_DETAIL,
  payload,
});

export const GetSubCategoriesSuccess = (payload) => ({
  type: Types.GET_SUB_CATEGORIES_SUCCESS,
  payload,
});

export const GetScrollSubCategoriesSuccess = (payload) => ({
  type: Types.GET_SCROLL_SUB_CATEGORIES_SUCCESS,
  payload,
});

export const CreateSubCategoriesSuccess = (payload) => ({
  type: Types.CREATE_SUB_CATEGORIES_SUCCESS,
  payload,
});

export const GetSubCategoriesDetailsSuccess = (payload) => ({
  type: Types.GET_SUB_CATEGORIES_DETAILS_SUCCESS,
  payload,
});

export const AddSubCategory = (payload) => ({
  type: Types.ADD_SUB_CATEGORIES_SUCCESS,
  payload,
});

export const GetLookUpLoading = (payload) => ({
  type: Types.LOOKUP_LOADING,
  payload,
});

export const GetCountriesSuccess = (payload) => ({
  type: Types.GET_COUNTRIES_SUCCESS,
  payload,
});

export const GetScrollCountriesSuccess = (payload) => ({
  type: Types.GET_SCROLL_COUNTRIES_SUCCESS,
  payload,
});

export const GetCountryDetailsSuccess = (payload) => ({
  type: Types.GET_COUNTRY_DETAILS_SUCCESS,
  payload,
});

export const GetFreeTextQuestionsSuccess = (payload) => ({
  type: Types.GET_FREETEXT_QUESTIONS_SUCCESS,
  payload,
});

export const GetFreeTextQuestionDetailsSuccess = (payload) => ({
  type: Types.GET_FREETEXT_QUESTION_DETAIL_SUCCESS,
  payload,
});

export const AddFreeTextQuestionSuccess = (payload) => ({
  type: Types.ADD_FREETEXT_QUESTIONS_SUCCESS,
  payload,
});

export const GetCitiesSuccess = (payload) => ({
  type: Types.GET_CITIES_SUCCESS,
  payload,
});

export const GetCitiesDetailSuccess = (payload) => ({
  type: Types.GET_CITIES_DETAILS,
  payload,
});

export const GetReportReasonsSuccess = (payload) => ({
  type: Types.GET_REPORT_REASONS_SUCCESS,
  payload,
});

export const GetReportReasonDetailsSuccess = (payload) => ({
  type: Types.GET_REPORT_REASON_DETAIL_SUCCESS,
  payload,
});

export const AddReportReasonSuccess = (payload) => ({
  type: Types.ADD_REPORT_REASON_SUCCESS,
  payload,
});

export const GetMultipleQuestionsSuccess = (payload) => ({
  type: Types.GET_MULTIPLE_QUESTIONS_SUCCESS,
  payload,
});

export const GetMultipleQuestionDetailsSuccess = (payload) => ({
  type: Types.GET_MULTIPLE_QUESTION_DETAIL_SUCCESS,
  payload,
});

export const AddMultipleQuestionSuccess = (payload) => ({
  type: Types.ADD_MULTIPLE_QUESTIONS_SUCCESS,
  payload,
});
