/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  OnBoardings: [],
  OnBoarding: {},
  count: 0,
  isloading: {loading:null,loadingType:""},
  error: null,
};

export default function OnBoardingReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ONBOARDING_SUCCESS: {
      return {
        ...state,
        OnBoardings: payload?.data?.onboardings,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_ONBOARDING_DETAIL_SUCCESS: {
      return {
        ...state,
        OnBoarding: payload?.data?.onboarding
      };
    }


    case Types.ONBOARDING_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
