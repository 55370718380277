import Types from "./Types";

export const GetNotificationsSuccess = (payload) => ({
  type: Types.GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const SetUnreadCount = (payload) => ({
  type: Types.SET_UNREAD_COUNT,
  payload,
});

export const SetFCMTokenSucces = (payload) => ({
  type: Types.SET_FCM_TOKEN,
  payload,
});

export const CreateFirebaseAuthTokenSuccess = (payload) => ({
  type: Types.CREATE_FIREBASE_AUTH_TOKEN_SUCCESS,
  payload,
});

export const SetNotificationIsSeen = (payload) => ({
  type: Types.SET_NOTIFICATION_IS_SEEN,
  payload,
});


export const NotificationsLoading = (payload) => ({
  type: Types.NOTIFICATIONS_LOADING,
  payload,
});
