import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import CommunityDetailsPage from "pages/community/PostDetails";

const CommunityTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
}) => {
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data, // data
            listTitle: "Posts List", //title
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              {
                accessor: "content",
                Header: "Post description",
                sort: true,
              },
              {
                accessor: "sub_category.main_category.name",
                Header: "Category",
                sort: true,
              },
              {
                accessor: "sub_category.name",
                Header: "Sub Category",
                sort: true,
              },
              { accessor: "expert.username", Header: "Expert", sort: true },
              { accessor: "created_at", Header: "Date", sort: true },
              { accessor: "status_rendered", Header: "Status", sort: true },

              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search by post content",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
          }}
          DetailsComponent={<CommunityDetailsPage details={details} />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default CommunityTemplate;
