import Types from "./Types";

export const GetMoneyTransferRequestsSuccess = (payload) => ({
  type: Types.GET_MONEY_TRANSFER_REQUESTS_SUCCESS,
  payload,
});
export const GetMoneyTransferRequestDetailsSuccess = (payload) => ({
  type: Types.GET_MONEY_TRANSFER_REQUEST_DETAILS_SUCCESS,
  payload,
});

export const FulfillMoneyTransferSuccess = (payload) => ({
  type: Types.FULFILL_MONEY_TRANSFER_SUCCESS,
  payload,
});


export const MoneyTransferRequestsLoading = (payload) => ({
  type: Types.MONEY_TRANSFERS_REQUESTS_LOADING,
  payload,
});
