import React, { useState } from "react";
import { Stack ,Typography,Box} from "@mui/material";
import QuillTextEditor from "components/Atoms/Input/quill/Quill";

export function EditorInput({ label, name, formik, changeAction }) {
  return (
    <Stack >
      {label && (
        <Typography as="span" sx={{
          color: "#797979",
          fontSize: "18px",
          mb: 2,
        }}>
          {label}
        </Typography>
      )}
            <QuillTextEditor name={name} formik={formik} changeAction={changeAction}/>
    </Stack>
  );
}
