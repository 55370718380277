import { makeFilterString } from "../utils/Helper";
import {
GetDocumentDetailSuccess,
GetTermsAndCondtionsSuccess,
GetDocumentsLoading,
GetPrivacyPolicySuccess,
GetCancellationPolicySuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetTermsAndCondtionsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Terms And Condtions",
    isLoading: GetDocumentsLoading,
    successFunction: GetTermsAndCondtionsSuccess,
    url: `/admin/lookups/documents/terms_and_conditions`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetPrivacyPolicyRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "privacy policy",
    isLoading: GetDocumentsLoading,
    successFunction: GetPrivacyPolicySuccess,
    url: `/admin/lookups/documents/privacy_policy`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetCancellationPolicyRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "cancellation policy",
    isLoading: GetDocumentsLoading,
    successFunction: GetCancellationPolicySuccess,
    url: `/admin/lookups/documents/cancellation_policy`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const GetDocumentDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetDocumentsLoading,
    loadingType: "document details",
    successFunction: GetDocumentDetailSuccess ,
    url: `/admin/lookups/documents/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const updateDocumentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetDocumentsLoading,
    loadingType: "update document",
    url: `/admin/lookups/documents/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "PATCH",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
