/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  MainCategories: [],
  MainCategory: {},
  SubCategories: [],
  SubCategory: {},
  MultipleQuestions: [],
  MultipleQuestion: {},
  Countries: [],
  Country: {},
  Cities: [],
  City: {},
  FreeTextQuestions: [],
  FreeTextQuestion: {},
  ReportReasons: [],
  ReportReason: {},
  count: 0,
  city_count: 0,
  countries_count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function lookupReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_MAIN_CATEGORIES_SUCCESS: {
      return {
        ...state,
        MainCategories: payload?.data?.main_categories,
        MainCategory: payload?.data?.main_categories?.[0],
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_MAIN_CATEGORIES_DETAIL: {
      return {
        ...state,
        MainCategory: payload?.data?.main_category,
      };
    }

    case Types.GET_SUB_CATEGORIES_SUCCESS: {
      return {
        ...state,
        SubCategories: payload?.data?.sub_categories,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_SCROLL_SUB_CATEGORIES_SUCCESS: {
      return {
        ...state,
        SubCategories:
          payload?.extra?.page_number == 1
            ? payload?.data?.sub_categories
            : [...state.SubCategories, ...payload?.data?.sub_categories],
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_SUB_CATEGORIES_DETAILS_SUCCESS: {
      return {
        ...state,
        SubCategory: payload?.data?.sub_category,
      };
    }

    case Types.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        Countries: payload?.data?.countries,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_SCROLL_COUNTRIES_SUCCESS: {
      return {
        ...state,
        Countries:
          payload?.extra?.page_number == 1 ? payload?.data?.countries : [...state.Countries, ...payload?.data?.countries],
        countries_count: payload?.extra?.total_count,
      };
    }

    case Types.GET_COUNTRY_DETAILS_SUCCESS: {
      return {
        ...state,
        Country: payload?.data?.country,
      };
    }

    case Types.GET_FREETEXT_QUESTIONS_SUCCESS: {
      return {
        ...state,
        FreeTextQuestions: payload?.data?.expert_request_questions,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_FREETEXT_QUESTION_DETAIL_SUCCESS: {
      return {
        ...state,
        FreeTextQuestion: payload?.data?.expert_request_question,
      };
    }

    case Types.GET_CITIES_SUCCESS: {
      return {
        ...state,
        Cities: payload?.data?.cities,
        city_count: payload?.extra?.total_count,
      };
    }

    case Types.GET_CITIES_DETAILS: {
      return {
        ...state,
        City: payload?.data?.city,
      };
    }

    case Types.GET_REPORT_REASONS_SUCCESS: {
      return {
        ...state,
        ReportReasons: payload?.data?.report_reasons,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_REPORT_REASON_DETAIL_SUCCESS: {
      return {
        ...state,
        ReportReason: payload?.data?.report_reason,
      };
    }

    case Types.LOOKUP_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    case Types.GET_MULTIPLE_QUESTIONS_SUCCESS: {
      return {
        ...state,
        MultipleQuestions: payload?.data?.expert_request_questions,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_MULTIPLE_QUESTION_DETAIL_SUCCESS: {
      return {
        ...state,
        MultipleQuestion: payload?.data?.expert_request_question,
      };
    }

    default: {
      return state;
    }
  }
}
