const Types = {
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  SET_UNREAD_COUNT: "SET_UNREAD_COUNT",
  SET_FCM_TOKEN: "SET_FCM_TOKEN",
  CREATE_FIREBASE_AUTH_TOKEN_SUCCESS: "CREATE_FIREBASE_AUTH_TOKEN_SUCCESS",
  SET_NOTIFICATION_IS_SEEN: "SET_NOTIFICATION_IS_SEEN",
  NOTIFICATIONS_LOADING: "NOTIFICATIONS_LOADING",
};

export default Types;
