import { icons } from 'assets/AssetHelper'
import { formatDate } from 'common/utils/helpers';
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Question',
                name: data?.question,
            },
            {
                label: 'Follow up description ',
                name: data?.description,
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.dates,
        details: [
            {
                label: 'Created At',
                name:formatDate(data?.created_at),
            },
            {
                label: 'Updated At',
                name: formatDate(data?.updated_at),
            },
        ]
    },
];


