import { formatDate } from "common/utils/helpers";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetJourneyDetailsRequest, GetJourneysRequest, GetParticipantsRequest } from "services/modules/journeys";
import { GetCitiesRequest, GetCountriesRequest, GetScrollSubCategoriesRequest } from "services/modules/lookups";
import { GetScrollUsersRequest } from "services/modules/users/users";
import JourneysTemplate from "templates/journeys";

const groupMap = new Map([
  ["solo", "solo"],
  ["couple", "couple"],
  ["women only", "women_only"],
  ["men only", "men_only"],
  ["mixed gender", "mixed_gender"],
]);

const groupMapTable = new Map([
  ["solo", "solo"],
  ["couple", "couple"],
  ["women_only", "women only"],
  ["men_only", "men only"],
  ["mixed_gender", "mixed gender"],
]);

function JourneysPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const lookup_loading = useSelector((state) => state.lookup?.isloading);
  const list = useSelector((state) => state.Journeys.Journeys);
  const count = useSelector((state) => state.Journeys.count);
  const details = useSelector((state) => state.Journeys.Journey);
  const load = useSelector((state) => state.Journeys.isloading);
  const participants = useSelector((state) => state.Journeys.Participants);

  const subCategoriesList = useSelector((state) => state.lookup.SubCategories);
  const subCategoriesCount = useSelector((state) => state.lookup.count);

  const expertsList = useSelector((state) => state.users.users);
  const expertsCount = useSelector((state) => state.users.count);

  const countriesList = useSelector((state) => state.lookup.Countries);
  const countriesCount = useSelector((state) => state.lookup.countries_count);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(GetJourneyDetailsRequest({ id }));
    dispatch(GetParticipantsRequest({ id }));
  };
  // details of a single user
  useEffect(() => {
    console.log(id);
    if (id) getDetails();
  }, [id]);

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: page,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetJourneysRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    const compounded_status = data?.compounded_status;
    return {
      ...data,
      status_rendered: (
        <ColoredText
          color={
            compounded_status == "finished"
              ? "green"
              : compounded_status == "canceled"
              ? "red"
              : compounded_status === "upcoming"
              ? "blue"
              : "yellow"
          }
        >
          {compounded_status}
        </ColoredText>
      ),
      group_type: groupMapTable.get(data.group_type),
      start_datetime: <>{formatDate(data?.start_datetime)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/journeys",
      title: "Journeys / Journeys List",
    },
    type && {
      active: false,
      title: type === "details" ? "Journey details" : id ? "Edit screen" : "Add screen",
    },
  ];

  const [riskLevel, setRiskLevel] = useState(null);
  // countries
  const [country, setCountry] = useState(null);
  const [countryInput, setCountryInput] = useState("");

  const countries = useSelector((state) => state.lookup.Countries);

  const [CountriesFilterObj, setCountriesFilterObj] = useState({
    page_size: 300,
    keyword: "",
    sort_by: "id",
  });

  const getCountries = async (CountriesFilterObj) => {
    const requestData = {
      params: { ...CountriesFilterObj, keyword: countryInput },
    };

    dispatch(GetCountriesRequest(requestData));
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (CountriesFilterObj && countryInput.length > 0) getCountries(CountriesFilterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [countryInput]);
  // countries

  //cities
  const [city, setCity] = useState(null);
  const [cityInput, setCityInput] = useState("");

  const cities = useSelector((state) => state.lookup?.Cities);

  const [CitiesFilterObj, setCitiesFilterObj] = useState({
    page_size: 10000,
    keyword: "",
    sort_by: "id",
  });

  const getCities = async (CitiesFilterObj) => {
    const requestData = {
      params: { ...CitiesFilterObj, keyword: cityInput },
    };

    dispatch(GetCitiesRequest(requestData));
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (CitiesFilterObj && cityInput.length > 0) getCities(CitiesFilterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [cityInput]);

  const geSubCategoriesList = (filterObj) => {
    const defaultData = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    dispatch(GetScrollSubCategoriesRequest({ ...defaultData, ...filterObj }));
  };

  // sub categories filter section

  //expert filter section
  const [expertInput, setExpertInput] = useState("");
  const [expertFilterObj, setExpertFilterObj] = useState({
    page_size: 10000, //set to be very large by default
    page_number: 1,
    keyword: "", //should hold the value in the select (will only call if there is a char)
    classification: "expert",
  });

  const getUsersList = (filterObj) => {
    const defaultData = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      classification: "expert",
      sort_by: "id",
    };
    dispatch(GetScrollUsersRequest({ ...defaultData, ...filterObj }));
  };
  // from  & to dates
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // handlers
  const handleApplyFilter = () => {
    setFilterObj({
      ...filterObj,
      risk_level: riskLevel?.label,
      country_id: country?.value,
      city_id: city?.value,
      "date[from]": fromDate ? dayjs(fromDate).format("DD-MM-YYYY") : null,
      "date[to]": toDate ? dayjs(toDate).format("DD-MM-YYYY") : null,
    });
  };

  const extraFilters = [
    {
      title: "Risk Level",
      type: "searchableSelect",
      options: [
        { id: 0, name: "low" },
        { id: 1, name: "medium" },
        { id: 2, name: "high" },
      ],
      placeholder: "Risk level",
      width: "100%",
      value: riskLevel,
      onChange: setRiskLevel,
    },
    {
      title: "Country",
      type: "searchableSelect",
      options: countries?.map((country) => {
        return {
          name: country.name,
          id: country.id,
        };
      }),
      placeholder: "Country",
      width: "100%",
      value: country,
      onChange: setCountry,
      onInputChange: (value) => {
        setCountryInput(value);
      },
      isLoading: lookup_loading?.type === "Countries" ? lookup_loading?.loading : null,
    },
    {
      title: "City",
      type: "searchableSelect",
      options: cities?.map((country) => {
        return {
          name: country.name,
          id: country.id,
        };
      }),
      placeholder: "City",
      width: "100%",
      value: city,
      onChange: setCity,
      onInputChange: (value) => {
        setCityInput(value);
      },
      isLoading: lookup_loading?.type === "Cities" ? lookup_loading?.loading : false,
    },
    {
      title: "From date",
      type: "date",
      maxDate: toDate,
      value: fromDate,
      onChange: (value) => {
        setFromDate(value);
      },
    },
    {
      title: "To date",
      type: "date",
      minDate: fromDate,
      value: toDate,
      onChange: (value) => {
        setToDate(value);
      },
    },
  ];

  const filterList = [
    {
      type: "SearchableSelect",
      placeholder: "Category",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, main_category_id: value?.value };
        });
      },
      options: [
        { id: 1, name: "Land" },
        { id: 2, name: "Air" },
        { id: 3, name: "Water" },
      ],
    },
    {
      type: "SearchableSelect",
      placeholder: "Sub category",
      onChange: (value) => {
        const sub_category_ids = `[${value?.value ?? ""}]`;
        setFilterObj((s) => {
          return { ...s, sub_category_ids };
        });
      },
      onMenuOpen: () => {
        subCategoriesList?.length == 0 && geSubCategoriesList({});
      },
      options: subCategoriesList?.map((subCat) => {
        return {
          name: subCat.name,
          id: subCat.id,
        };
      }),
      getList: geSubCategoriesList,
      count: subCategoriesCount,
      applySearchFromRequest: true,
    },
    {
      type: "SearchableSelect", // this isn't important anyways
      placeholder: "Group Type",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, group_type: groupMap.get(value?.label) };
        });
      },

      options: [
        { id: 1, name: "solo" },
        { id: 2, name: "couple" },
        { id: 3, name: "women only" },
        { id: 4, name: "men only" },
        { id: 5, name: "mixed gender" },
      ],
    },
    {
      type: "SearchableSelect", // this isn't important anyways
      placeholder: "status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, compounded_status: value?.label };
        });
      },
      options: [
        { id: 0, name: "canceled" },
        { id: 1, name: "upcoming" },
        { id: 2, name: "finished" },
        { id: 3, name: "inprogress" },
      ],
    },
    {
      type: "SearchableSelect",
      placeholder: "Expert",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, expert_id: value?.value };
        });
      },
      onMenuOpen: () => {
        expertsList?.length == 0 && getUsersList({});
      },
      options: expertsList?.map((expert) => {
        return {
          name: expert.username,
          id: expert.id,
        };
      }),
      getList: getUsersList,
      count: expertsCount,
      applySearchFromRequest: true,
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading} />
      <JourneysTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={{ ...details, participants }}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        extraFilters={extraFilters}
        handleApplyFilter={handleApplyFilter}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default JourneysPage;
