import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import JourneyDetailsPage from "pages/journey-details/JourneyDetails";

const JourneysTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  filterList,
  extraFilters,
  handleApplyFilter,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "Journeys list",
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              {
                accessor: "name",
                Header: "Journey name",
                sort: true,
              },
              {
                accessor: "sub_category.main_category.name",
                Header: "Category",
                sort: true,
              },
              {
                accessor: "sub_category.name",
                Header: "Sub Category",
                sort: true,
              },
              {
                accessor: "group_type",
                Header: "Group type",
                sort: true,
              },
              {
                accessor: "risk_level",
                Header: "Risk level",
                sort: true,
              },

              {
                accessor: "start_datetime",
                Header: "Date",
                sort: true,
              },

              {
                accessor: "status_rendered",
                Header: "Status",
                sort: true,
              },
              {
                accessor: "expert.fullname",
                Header: "Expert Name",
                sort: true,
              },

              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search by Journey name",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            extraFilters,
            handleApplyFilter,
            className: "main-table",
            actionsList,
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          DetailsComponent={<JourneyDetailsPage details={details} title="Journey details " />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default JourneysTemplate;
