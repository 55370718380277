import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetNotificationsReuqest } from "services/modules/notifications/notifications";
import NotificationsList from "./NotificationsList";
import { isScrolledToBottom } from "common/utils/helpers";

export default function Notifications() {
  const dispatch = useDispatch();
  const { orange } = useThemePalette();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const list = useSelector((state) => state.notifications.notifications);
  const count = useSelector((state) => state.notifications.count);
  const unreadCount = useSelector((state) => state.notifications.unreadCount);
  const load = useSelector((state) => state.notifications.isloading);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    (list?.length === 0 || unreadCount !== 0) && getList();
  };

  const handleMenuScroll = (e) => {
    const isBottom = isScrolledToBottom(e);
    if (isBottom) {
      let page_number = Math?.ceil(list?.length / 10 + 1);
      if (list?.length < count) {
        getList({ page_number });
      }
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const notificationTypes = (id) => {
    return {
      expert_request: {
        path: `experts/requests?type=details&id=${id}`,
        icon: icons.ExpertRequest,
      },
      money_transfer_request: {
        path: `experts/transfer-money-requests`,
        icon: icons.MoneyTransfer,
      },
      account_verification_request: {
        path: `experts/verification-requests?type=details&id=${id}`,
        icon: icons.VerificationRequest,
      },
    };
  };

  const getList = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetNotificationsReuqest(requestData));
  };

  // useEffect(() => {
  //   unreadCount !== 0 && open && list?.length !== 0 && getList({ page_number: 1 });
  // }, [unreadCount, open, list]);

  return (
    <Box sx={{ position: "relative" }}>
      <Stack sx={{ cursor: "pointer" }} onClick={handleMenuOpen}>
        {unreadCount !== 0 && (
          <Stack
            sx={{
              position: "absolute",
              backgroundColor: orange,
              borderRadius: "50%",
              width: "18px",
              height: "18px",
              justifyContent: "center",
              alignItems: "center",
              right: "-2px",
              color: "white",
            }}
          >
            <Typography fontSize={"12px"}>{unreadCount}</Typography>
          </Stack>
        )}
        <Box component="img" src={icons.notificationsHeader} />
      </Stack>
      <NotificationsList
        open={open}
        anchorEl={anchorEl}
        handleClose={handleMenuClose}
        notifications={list}
        load={load}
        count={count}
        isFetch={count > list?.length}
        notificationTypes={notificationTypes}
        countOfUnread={unreadCount}
        handleMenuScroll={handleMenuScroll}
      />
    </Box>
  );
}
