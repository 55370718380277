import { Stack } from "@mui/material";
import Header from "components/Atoms/SharedComponents/Details/header";
import CustomTabs from "components/Organisms/tabs/customTabs";

export default function AdventurerDetailsTemplate({  tabs, selectedTab, handleTabChange }) {
  return (
    <Stack>
      <Header title="Adventurer details" deleteBtn={false} editBtn={false} />
      <CustomTabs tabs={tabs} selectedTab={selectedTab} handleTabChange={handleTabChange} />
    </Stack>
  );
}
