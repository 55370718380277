import Types from "./Types";

export const GetBookingsSuccess = (payload) => ({
  type: Types.GET_BOOKINGS_SUCCESS,
  payload,
});

export const GetBookingDetailsSuccess = (payload) => ({
  type: Types.GET_BOOKING_DETAILS_SUCCESS,
  payload,
});

export const RefundSuccess = (payload) => ({
  type: Types.REFUND_SUCCESS,
  payload,
});

export const BookingsLoading = (payload) => ({
  type: Types.BOOKINGS_LOADING,
  payload,
});


