import { Box, Stack, Typography } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import { formatDate } from "common/utils/helpers";
import dayjs from "dayjs";

const QuestionAnswers = ({ question }) => {
  const questionHeader = question?.expert_request_question?.question;
  const options = question?.expert_request_question_option;
  return (
    <Stack sx={{ border: "1px solid #F9D1AD", borderRadius: "6px", p: 2, height: "80%" }} gap={2}>
      <Stack gap={2}>
        <Typography sx={{ color: "#969696", fontSize: "18px" }}>{questionHeader}</Typography>
        <Stack direction="row" gap={2} justifyContent="space-evenly" flexWrap="wrap">
          {options?.map((opt) => (
            <Stack key={opt.id} sx={{ width: "fit-content" }} direction="row" gap={1}>
              {opt?.selected && <Box component="img" src={icons.Checked} />}
              <Typography
                sx={{
                  color: opt?.selected ? "#1A1A1A" : "#969696",
                  fontSize: "20px",
                  fontWeight: opt?.selected ? 600 : 400,
                }}
              >
                {opt?.option}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
export const detailsData = ({ data }) => {
  return [
    {
      head: "Request Information",
      icon: icons.detailsIcon,
      details: [
        {
          label: "Expert Image",
          name: (
            <Box
              component="img"
              src={data?.user?.avatar || icons.AnonymousUser}
              width="158px"
              height="158px"
              sx={{ borderRadius: "50%" }}
            />
          ),
          width: "100%",
        },
        { label: "Expert full name", name: data?.user?.fullname },
        { label: "User name", name: data?.user?.username },
        { label: "Category", name: data?.main_category?.name },
        {
          label: "Languages",
          name: (
            <Stack gap={2} direction="row">
              {data?.user?.languages?.map((lang) => (
                <Typography
                  sx={{
                    bgcolor: "#F3F3F3",
                    px: 1,
                    py: 0.5,
                    borderRadius: "15px",
                    color: "#1A1A1A",
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                  key={lang.id}
                >
                  {lang.name}
                </Typography>
              ))}
            </Stack>
          ),
        },
        {
          label: "Sub category",
          name: (
            <Stack gap={2} direction="row">
              {data?.sub_categories?.map((cat) => (
                <Typography
                  sx={{
                    bgcolor: "#F3F3F3",
                    px: 1,
                    py: 0.5,
                    borderRadius: "15px",
                    color: "#1A1A1A",
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                  key={cat.id}
                >
                  {cat.name}
                </Typography>
              ))}
            </Stack>
          ),
        },
      ],
    },
    {
      head: "Certifications",
      icon: icons?.CertificatesIcon,
      details: data?.certificates?.map((certificate, i) => {
        return {
          label: certificate?.name,
          name: (
            <Stack direction="row" sx={{ gap: 2, height: "auto" }}>
              {certificate?.media?.map((media) => (
                <a href={media?.url} target="_blank" rel="noreferrer noopener">
                  <Stack
                    sx={{
                      backgroundImage: `url(${media?.url})`,
                      height: "230px",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                    key={media?.id}
                    component="img"
                    width="542px"
                    height="auto"
                  />
                </a>
              ))}
            </Stack>
          ),
          width: "100%",
        };
      }),
    },
    {
      head: "General info",
      icon: icons?.Info,
      details: [
        {
          name: (
            <Stack sx={{ border: "1px solid #F9D1AD", borderRadius: "6px", p: 2 }}>
              <Typography sx={{ color: "#969696", fontSize: "18px" }}>Expert bio</Typography>
              <Typography sx={{ color: "#1A1A1A", fontSize: "20px", fontWeight: 500 }}>{data?.user?.expert_bio}</Typography>
            </Stack>
          ),
          width: "100%",
        },
      ].concat(
        data?.general_question_answers?.map((Q) => {
          return {
            name: (
              <Stack sx={{ border: "1px solid #F9D1AD", borderRadius: "6px", p: 2 }} key={Q?.id}>
                <Typography sx={{ color: "#969696", fontSize: "18px" }}>
                  {Q?.expert_request_general_question?.question}
                </Typography>
                <Typography sx={{ color: "#1A1A1A", fontSize: "20px", fontWeight: 500 }}>{Q?.answer}</Typography>
              </Stack>
            ),
            width: "100%",
          };
        })
      ),
    },
    {
      head: "Expert answers",
      icon: icons?.QuestionMark,
      details: data?.question_answers?.map((Q) => {
        return {
          name: <QuestionAnswers question={Q} key={Q?.id} />,
          width: "47%",
        };
      }),
    },
    {
      head: "Dates",
      icon: icons?.dates,
      details: [
        {
          label: "Created At",
          name: formatDate(data?.created_at),
        },
        {
          label: "Updated At",
          name: formatDate(data?.updated_at),
        },
      ],
    },
  ];
};
