import { makeFilterString } from "../../utils/Helper";
import { GetCommentDetailSuccess, GetCommentsSuccess, CommentsLoading, UpdateCommentSuccess } from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetCommentsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: CommentsLoading,
    successFunction: GetCommentsSuccess,
    url: `/admin/posts/${variables?.id}/comments${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetCommentsDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CommentsLoading,
    loadingType: "details",
    successFunction: GetCommentDetailSuccess,
    url: `/admin/posts/${variables?.post_id}/comments/${variables?.comment_id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdateCommentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CommentsLoading,
    loadingType: "update",
    successFunction: UpdateCommentSuccess,
    url: `/admin/posts/${variables?.post_id}/comments/${variables?.comment_id}`,
    method: "patch",
    data: variables?.body,
    action: variables?.action,
    variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
