import { useThemePalette } from "common/hooks/theme_palette";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import RefundAndCompensationModal from "components/Molecules/Models/RefundAndCompensationModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToggleConfirmationModal, ToggleRefundModal } from "services/modules/modal/Actions";
import BookingDetailsTemplate from "templates/bookingDetails";
import { icons } from "assets/AssetHelper";
import { toast } from "react-toastify";
import { RefundRequest } from "services/modules/bookings";

export default function BookingDetailsPage({ details, joruneyStatuses }) {
  const dispatch = useDispatch();
  const { vivideOrange } = useThemePalette();
  const transactionType = useSelector((state) => state.modal.refundModal?.type); //refund or refund/compensation
  
  const [amounts, setAmounts] = useState({
    adventurer: 0,
    expert: 0,
  });

  const [error, setError] = useState({
    adventurer: null,
    expert: null,
  });

  const { total_amount, currency } = details;

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setAmounts({
      ...amounts,
      [key]: value,
    });
  };

  const validateAmount = () => {
    let errorMsg = "Invalid amount";
    const isInValidAdventurerAmount = amounts.adventurer < 0 || amounts.adventurer === "";
    const isInValidExpertAmount = amounts.expert < 0 || amounts.expert === "";
    const isInvalidSum = Number(amounts.adventurer) + Number(amounts.expert) > total_amount;

    if (isInvalidSum) {
      errorMsg = "The total refunded amount can't be more than the total amount ";
      setError(() => {
        return {
          adventurer: errorMsg,
          expert: errorMsg,
        };
      });
      return false;
    } else if (isInValidAdventurerAmount || isInValidExpertAmount) {
      if (isInValidAdventurerAmount) {
        setError(() => {
          return {
            ...error,
            adventurer: errorMsg,
          };
        });
      }
      if (isInValidExpertAmount) {
        setError(() => {
          return {
            ...error,
            expert: errorMsg,
          };
        });
      }
      return false;
    } else {
      setError(() => {
        return {
          adventurer: null,
          expert: null,
        };
      });
      return true;
    }
  };

  const resetForm = () => {
    setAmounts({ adventurer: 0, expert: 0 });
    setError({ adventurer: null, expert: null });
  };

  const onConfirm = () => {
    const isValidAmount = validateAmount();
    if (isValidAmount) {
      // keeping the type: transactionType >> as it's used to determine the content of ConfirmationModal
      dispatch(ToggleRefundModal({ isOpen: false, type: transactionType }));
      dispatch(ToggleConfirmationModal());
    }
  };

  const processRefund = () => {
    const action = () => {
      toast.success("The refunded amount has been successfully sent");
      resetForm();
    };
    const requestData = {
      body: {
        rectification: {
          reservation_id: details?.id,
          adventurer_refund_amount: Number(amounts.adventurer),
          expert_compensation_amount: Number(amounts.expert),
        },
      },
      action,
    };
    dispatch(RefundRequest(requestData));
  };

  return (
    <>
      <RefundAndCompensationModal
        totalAmount={total_amount}
        currency={currency?.lookup_key}
        journey={details?.journey}
        amounts={amounts}
        onChange={handleInputChange}
        error={error}
        setError={setError}
        onConfirm={onConfirm}
        onCancel={resetForm}
      />
      <ConfirmationModal
        text={[
          transactionType == "refund" ? "Refund Confirmation" : "Refund / Compensation confirmation",
          transactionType == "refund"
            ? "Are you sure you want to refund this amount?"
            : "Are you sure you want to refund / compensate this amount?",
        ]}
        onCancel={resetForm}
        titleStyle={{ color: "#000" }}
        mainColor={vivideOrange}
        confirmBtnTitle="Yes"
        icon={icons.ExclamationMark}
        onConfirm={processRefund}
      />
      <BookingDetailsTemplate details={details} joruneyStatuses={joruneyStatuses} />
    </>
  );
}
