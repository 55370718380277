import { reduxRequest } from "common/utils/reduxRequest";
import { makeFilterString } from "../../../utils/Helper";
import {
  GetVerificationRequestDetailsSuccess,
  GetVerificationRequestsSuccess,
  VerificationRequestsLoading,
  UpdateVerificationRequestSuccess,
} from "./Actions";

export const GetVerificationRequestsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: VerificationRequestsLoading,
    successFunction: GetVerificationRequestsSuccess,
    url: `/admin/account_verification_requests/${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetVerificationRequestDetailsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "details",
    isLoading: VerificationRequestsLoading,
    successFunction: GetVerificationRequestDetailsSuccess,
    url: `/admin/account_verification_requests/${variables?.id}${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const VerifyVerificationRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "update",
    isLoading: VerificationRequestsLoading,
    successFunction: UpdateVerificationRequestSuccess,
    url: `/admin/account_verification_requests/${variables?.id}`,
    method: "patch",
    action: variables?.action,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const RejectVerificationRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "update",
    isLoading: VerificationRequestsLoading,
    successFunction: UpdateVerificationRequestSuccess,
    url: `/admin/account_verification_requests/${variables?.id}`,
    method: "delete",
    action: variables?.action,
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
