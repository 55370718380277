import Types from "./Types";

export const GetEquipmentSuccess = (payload) => ({
  type: Types.GET_EQUIPMENT_SUCCESS,
  payload,
});

export const GetExpertsSuccess=(payload)=>({
  type:Types.GET_EXPERTS,
  payload
});
export const GetEquipmentDetailSuccess = (payload) => ({
  type: Types.GET_EQUIPMENT_DETAIL_SUCCESS,
  payload,
});


export const EquipmentLoading = (payload) => ({
  type: Types.EQUIPMENT_LOADING,
  payload,
});
