import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_attributes,
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Category name (EN)",
        width: "47%",
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Category name (AR)",
        width: "47%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
  });
