import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [

      {
        name: `content`,
        kind: "editorInput",
        label: "Description",
        placeholder:"Write Description"
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    content: Yup.string().required("required"),    
  });
