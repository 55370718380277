import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    question:data?.question,
    description:data?.description,
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `question`,
        kind: "input",
        type: "text",
        label: "Question (EN)",
        width: "47%",
        placeholder:"Write question here"
      },
      {
        name: `description`,
        kind: "textArea",
        type: "textarea",
        colSpan:4,
        maxCharLength:200,
        label: "Add follow up description to the question (EN)",
        width: "47%",
        placeholder:"Add follow up  description"

      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    question: Yup.string().required("required"),
    description: Yup.string().required("required"),
    
  });
