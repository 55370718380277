import { makeFilterString } from "../../utils/Helper";
import {
  AddJourneyCancellationReasonSuccess,
  GetJourneyCancellationReasonDetailsSuccess,
  GetJourneyCancellationReasonsSuccess,
  JourneyCancellationReasonsLoading,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetJourneyCancellationReasonsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: JourneyCancellationReasonsLoading,
    successFunction: GetJourneyCancellationReasonsSuccess,
    url: `/admin/lookups/journey_cancellation_reasons${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetJourneyCancellationReasonDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: JourneyCancellationReasonsLoading,
    loadingType: "details",
    successFunction: GetJourneyCancellationReasonDetailsSuccess,
    url: `/admin/lookups/journey_cancellation_reasons/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdateJourneyCancellationReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: JourneyCancellationReasonsLoading,
    loadingType: "update",
    url: `/admin/lookups/journey_cancellation_reasons/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const AddJourneyCancellationReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: JourneyCancellationReasonsLoading,
    successFunction: AddJourneyCancellationReasonSuccess,
    loadingType: "add",
    action: variables?.action,
    url: `admin/lookups/journey_cancellation_reasons`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteJourneyCancellationReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: JourneyCancellationReasonsLoading,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/lookups/journey_cancellation_reasons/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
