import { makeFilterString } from "../utils/Helper";
import { GetStatisticsSuccess, StatisticsLoading } from "./Actions";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetStatisticsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: StatisticsLoading,
    successFunction: GetStatisticsSuccess,
    url: `/admin/statistics${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
