import { Stack } from "@mui/material";
import Graph from "components/Atoms/Statistics/Graph";
import NoData from "components/Atoms/Statistics/NoData";

export default function Graphs({ data }) {
  return (
    <Stack gap={3}>
      {data?.map((section, i) =>
        section?.report?.total_amount !== 0 ? <Graph section={section} key={i} /> : <NoData section={section} key={i} />
      )}
    </Stack>
  );
}
