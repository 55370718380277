import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
const CTextArea = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  colSpan,
  maxCharLength,
  labelStyle,
  ...rest
}) => {
  const multinames = name?.split(".")?.map((data) => data);
  const value =
    multinames?.length == 2
      ? formik?.values?.[multinames[0]]?.[multinames[1]]
        ? formik?.values?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik?.values[name];
  const errorsMsg =
    multinames?.length == 2
      ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik.errors[name];

  return (
    <Box mb="5">
    <TextFieldInput
      id={name}
      type={type}
      label={label}
      labelStyle={{
        color: "#797979",
        fontSize: "18px",
        mb: 2,
      }}
      placeholder={placeholder}
      inputProps={{ maxLength: maxCharLength }}
      sx={{
        background:'#FBFBFB',
        "& label.Mui-focused": {
          color: "white",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: '#D92C32',
          },
        },
      }}
      onChange={formik?.handleChange}
      value={value || ""}
      onBlur={formik.handleBlur}
      name={name}
      multiline
      rows={colSpan}

      {...rest}
    />
    <Typography color={'#A0A0A0'} fontSize={'14px'} sx={{float:'right'}}>{value?.length?value?.length:0}/{maxCharLength}</Typography>
    <CFormErrors errorsMsg={errorsMsg} />
  </Box>
  );
};

export default CTextArea;
