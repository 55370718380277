import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import AdventurerDetails from "components/Organisms/adventurer/details";
import Categories from "components/Organisms/users/Categories";
import JourneyDetailsPage from "pages/journey-details/JourneyDetails";
import ParticipatingJourneys from "pages/participating-journeys/ParticipatingJourneys";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetailsRequest } from "services/modules/users/users";
import AdventurerDetailsTemplate from "templates/adventurer/adventurerDetails";
import { GetJourneyDetailsRequest } from "services/modules/users/users";

export default function AdventurerDetailsPage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const details = useSelector((state) => state.users.user);
  const journeyDetails = useSelector((state) => state.users.journey);

  const selectedTab = searchParams.get("tab");
  const id = searchParams.get("id");
  const journeyid = searchParams.get("journeyid");

  const [value, setValue] = useState(() => {
    return selectedTab ? parseInt(selectedTab) : 0;
  });

  const getDetails = async () => {
    const requestData = { id };
    dispatch(GetUserDetailsRequest(requestData));
  };

  const tabs = [
    { title: "Details", children: <AdventurerDetails details={details} /> },
    { title: "Participating journeys ", children: <ParticipatingJourneys id={id} /> },
    { title: "Categories", children: <Categories id={id} classification="adventurer" /> },
  ];

  const handleTabChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
    setValue(newValue);
  };

  useEffect(() => {
    if (id && !journeyid) getDetails();
  }, [id, journeyid]);

  useEffect(() => {
    journeyid && dispatch(GetJourneyDetailsRequest({ id: journeyid }));
  }, [journeyid]);

  return (
    <>
      {journeyid ? (
        <JourneyDetailsPage
          title="Participating journey"
          details={{
            ...journeyDetails,
            ...journeyDetails?.journey,
            equipment: journeyDetails?.reservation_equipment?.map((item) => {
              return {
                ...item,
                ...item.equipment,
              };
            }),
          }}
        />
      ) : (
        <AdventurerDetailsTemplate selectedTab={value} tabs={tabs} handleTabChange={handleTabChange} />
      )}
    </>
  );
}
