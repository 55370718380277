import Categroies from "components/Organisms/users/Categroies";
import Details from "components/Organisms/experts/Details";
import Reviews from "components/Organisms/experts/Reviews";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ExpertDetailsTemplate from "templates/experts/expert-details/ExpertDetails";
import ParticipatingJourneys from "pages/participating-journeys/ParticipatingJourneys";
import { GetJourneyDetailsRequest } from "services/modules/users/users";
import { useDispatch, useSelector } from "react-redux";
import JourneyDetailsPage from "pages/journey-details/JourneyDetails";

export default function ExpertDetailsPage({ details }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");
  const id = searchParams.get("id");
  const journeyid = searchParams.get("journeyid");
  const dispatch = useDispatch();
  const journeyDetails = useSelector((state) => state.users.journey);

  const [value, setValue] = useState(() => {
    return selectedTab ? parseInt(selectedTab) : 0;
  });

  const tabs = [
    { title: "Details", children: <Details details={details} /> },
    { title: "Categories", children: <Categroies id={id} classification="expert" /> },
    { title: "Participating journeys ", children: <ParticipatingJourneys id={id} /> },
    { title: "Reviews", children: <Reviews id={id} /> },
  ];

  const handleTabChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
    setValue(newValue);
  };

  useEffect(() => {
    journeyid && dispatch(GetJourneyDetailsRequest({ id: journeyid }));
  }, [journeyid]);

  return (
    <>
      {journeyid ? (
        <JourneyDetailsPage
          title="Participating journey"
          details={{
            ...journeyDetails,
            ...journeyDetails?.journey,
            equipment: journeyDetails?.reservation_equipment?.map((item) => {
              return {
                ...item,
                ...item.equipment,
              };
            }),
          }}
        />
      ) : (
        <ExpertDetailsTemplate selectedTab={value} tabs={tabs} handleTabChange={handleTabChange} />
      )}
    </>
  );
}
