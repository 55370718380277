import Types from "./Types";

const INIT_STATE = {
  openAddAddress: false,
  openConfirationModal: false,
  isOpenCancellationReasonModal: false,
  isOpenTransferMoneyRequestModal: false,
  rejectionReasonModal: {
    isOpen: false,
    type: "",
  },
  refundModal: {
    isOpen: false,
    type: "",
  },
};

export default function modalReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.IS_OPEN_ADD_ADDRESS: {
      return {
        ...state,
        openAddAddress: !state.openAddAddress,
      };
    }
    case Types.TOGGLE_CONFIRMATION_MODAL: {
      return {
        ...state,
        openConfirationModal: !state.openConfirationModal,
      };
    }
    case Types.TOGGLE_CANCELLATION_REASON_MODAL: {
      return {
        ...state,
        isOpenCancellationReasonModal: !state.isOpenCancellationReasonModal,
      };
    }
    case Types.TOGGLE_TRANSFER_MONEY_REQUEST_MODAL: {
      return {
        ...state,
        isOpenTransferMoneyRequestModal: !state.isOpenTransferMoneyRequestModal,
      };
    }
    case Types.TOGGLE_REJECTION_REASON_MODAL: {
      return {
        ...state,
        rejectionReasonModal: {
          isOpen: payload?.isOpen,
          type: payload?.type,
        },
      };
    }
    case Types.TOGGLE_REFUND_MODAL: {
      return {
        ...state,
        refundModal: {
          isOpen: payload?.isOpen,
          type: payload?.type,
        },
      };
    }
    default: {
      return state;
    }
  }
}
