import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Header from "../Details/header";
import Images from "../Details/images";
import DetailsList from "../Details/detailsList";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";
const DetailsUse = ({ data, setOpenDeleteModal, children }) => {
  const { borderColor, orange, offWhite } = useThemePalette();
  return (
    <Stack gap={3}>
      {data?.pageTitle && (
        <Header
          title={data?.pageTitle}
          children={data?.headerChildren}
          deleteBtn={data?.deleteBtn}
          editBtn={data?.editBtn}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}

      {data?.data?.images && (
        <Stack
          direction="row"
          p="20px"
          sx={{ boxSizing: "border-box" }}
          bgcolor={"white"}
          borderRadius=" 4px"
          width={data?.data?.images?.length > 1 ? "100%" : "35%"}
          justifyContent="space-between"
        >
          {data?.data?.images?.map((data) => (
            <Images data={data} />
          ))}
        </Stack>
      )}

      <Stack bgcolor={"white"} borderRadius="4px">
        <Stack direction="row" justifyContent={"end"} gap="12px">
          {data?.data?.checkBox?.map(
            (checkBox, index, array) =>
              checkBox?.checked && (
                <Stack
                  direction="row"
                  gap="4px"
                  sx={{ bgcolor: orange, px: "21px", py: "8px" }}
                  borderRadius={array?.length - 1 == index && "0 4px 0 0"}
                >
                  <Box>
                    <Box component="img" src={checkBox?.checked && icons?.checkedfilled} />
                  </Box>
                  <Typography color={offWhite} fontSize="18px">
                    {checkBox?.label}
                  </Typography>
                </Stack>
              )
          )}
        </Stack>
        {data?.data?.list?.map((data, index, array) => (
          <Box borderBottom={index !== array?.length - 1 && `1px solid ${borderColor}`} key={index}>
            <DetailsList data={data} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default DetailsUse;
