import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `key`,
        kind: "input",
        type: "text",
        label: "Key",
        width: "47%",
        placeholder: "key",
        disabled: true,
      },
      {
        name: `value`,
        kind: "input",
        type: "text",
        label: "Value",
        width: "47%",
        placeholder: "Write value",
      },
      {
        name: `description`,
        kind: "input",
        type: "text",
        label: "Description",
        width: "47%",
        placeholder: "Write Description",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    key: Yup.string().required("required"),
    value: Yup.string().required("required"),
    description: Yup.string().required("required"),
  });
