import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const ReportedPostsTemplate = ({
  handlePageChange,
  handleCommentsPageChange,
  data,
  commentsData,
  CommentsCount,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  CommentsactionsList,
  breadcrumbs,
  setFilterObj,
  setCommentsfilterObj,
  rowData,
  paginationPage,
  commentsPaginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [supportedLang, setSupportedLang] = useState("en");

  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data, // data
            listTitle: "Reported posts", //title
            columns: [
              { accessor: "post.id", Header: "#", clickable: true },
              {
                accessor: "post.content",
                Header: "Post description",
                sort: true,
              },
              {
                accessor: "user.username",
                Header: "User name",
                sort: true,
              },
              {
                accessor: "report_reason.name",
                Header: "Reason",
                sort: true,
              },
              { accessor: "created_at", Header: "Date", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search by post content",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
            propertyName: "delete_field",
          }}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ReportedPostsTemplate;
