import Types from "./Types";

export const GetTermsAndCondtionsSuccess = (payload) => ({
  type: Types.GET_TERMS_AND_CONDTIONS_SUCCESS,
  payload,
});

export const GetPrivacyPolicySuccess = (payload) => ({
  type: Types.GET_PRIVACY_POLICY_SUCCESS,
  payload,
});

export const GetCancellationPolicySuccess = (payload) => ({
  type: Types.GET_CANCELLATION_POLICY_SUCCESS,
  payload,
});


export const GetDocumentDetailSuccess = (payload) => ({
  type: Types.GET_DOCUMENT_DETAIL,
  payload,
});

export const GetDocumentsLoading = (payload) => ({
  type: Types.DOCUMENTS_LOADING,
  payload,
});
