import Types from "./Types";



export const GetJourneySuccess = (payload) => ({
  type: Types.GET_JOURNEYS_SUCCESS,
  payload,
});


export const GetJourneyDetailSuccess = (payload) => ({
  type: Types.GET_JOURNEY_DETAIL_SUCCESS,
  payload,
});


export const GetParticipantsSuccess = (payload) => ({
  type: Types.GET_PARTICIPANTS_SUCCESS,
  payload,
});


export const JourneysLoading = (payload) => ({
  type: Types.JOURNEYS_LOADING,
  payload,
});

export const GetExpertsSuccess=(payload)=>({
  type:Types.GET_EXPERTS,
  payload
});
