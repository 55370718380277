import { Box, Menu, Stack, Typography } from "@mui/material";
import NotificationSkelaton from "components/Atoms/SharedComponents/Notifications/NotificationSkelaton";
import Notification from "components/Atoms/SharedComponents/Notifications/Notification";

export default function NotificationsList({
  open,
  anchorEl,
  handleClose,
  notifications,
  load,
  handleMenuScroll,
  notificationTypes,
  count,
}) {
  return (
    <Box>
      <Menu id="notifications-menu" aria-labelledby="notifications-button" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* HEADER START */}
        <Stack
          sx={{
            p: 2,
            borderBottom: "1px solid #E2E2E2",
            minWidth: "400px",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontSize="24px" fontWeight="500" fontFamily="Jost">
            Notifications
          </Typography>
        </Stack>
        {/* HEADER END */}
        {/* LISTING NOTIFICATIONS */}
        <Stack gap={2} sx={{ overflowY: "auto", height: 600, m: 2 }} onScroll={handleMenuScroll}>
          {notifications?.map((notification, i) => (
            <Notification key={i} notification={notification} notificationTypes={notificationTypes} handleClose={handleClose} />
          ))}
          {/* loader */}
          {((load.type == "list" && count == 0) || count > notifications?.length) && <NotificationSkelaton num={1} />}
        </Stack>
      </Menu>
    </Box>
  );
}
