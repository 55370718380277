import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import BookingDetailsPage from "pages/bookingDetails/BookingDetails";

const BookingsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  joruneyStatuses,
}) => {
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "Booking List",
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              { accessor: "reservation_number", Header: "Booking Num" },
              { accessor: "user.username", Header: "Adventurer" },
              { accessor: "journey.name", Header: "Journey name" },
              { accessor: "number_of_participants", Header: "Num of participants", sort: true },
              { accessor: "cost", Header: "Cost", sort: true },
              { accessor: "journey.expert.username", Header: "Expert", sort: true },
              { accessor: "created_at", Header: "Date", sort: true },
              { accessor: "status", Header: "status", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search by booking Num",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList,
            headerChildren,
            details,
          }}
          DetailsComponent={<BookingDetailsPage details={details} joruneyStatuses={joruneyStatuses} />}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default BookingsTemplate;
