import { Stack } from "@mui/material";
import { icons } from "assets/AssetHelper";
import Graphs from "components/Molecules/Statistics/Graphs";
import Summary from "components/Molecules/Statistics/Summary";

export default function Charts({ statistics }) {
  const modifiedStatistics = [
    {
      name: "Total Earning",
      icon: icons.Earnings,
      report: statistics?.revenue,
    },
    {
      name: "Reservations",
      icon: icons.Reservations,
      report: statistics?.reservation,
    },
    {
      name: "Registrations",
      icon: icons.Registerations,
      report: statistics?.registration,
    },
    {
      name: "Added Journeys",
      icon: icons.Route,
      report: statistics?.journey,
    },
    {
      name: "Added Equipment",
      icon: icons.EquipmentReports,
      report: statistics?.equipment,
    },
  ];

  return (
    <Stack gap={3}>
      {/* summary */}
      <Summary data={modifiedStatistics} />
      {/* graphs */}
      <Graphs data={modifiedStatistics} />
    </Stack>
  );
}
