import { Button, Stack, Typography } from "@mui/material";
import Header from "components/Atoms/SharedComponents/Details/header";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch } from "react-redux";
import { ToggleRefundModal } from "services/modules/modal/Actions";

export default function BookingDetailsHeader({ title, details, joruneyStatuses }) {
  const { vivideOrange } = useThemePalette();
  const dispatch = useDispatch();

  const status = details?.compounded_status;
  const isShowRefundBtn =
    !details?.rectifications?.adventurer_refund_transaction && !details?.rectifications?.expert_compensation_transaction;

  const openRefundModal = () => {
    dispatch(ToggleRefundModal({ isOpen: true, type: "refund" }));
  };

  const openCompensationModal = () => {
    dispatch(ToggleRefundModal({ isOpen: true, type: "refund/compensation" }));
  };

  return (
    <Stack direction="row" alignItems="center">
      <Header editBtn={false} deleteBtn={false} title={title} />
      <Stack direction="row" gap={1} alignItems="center">
        <Typography sx={{ color: "#9C9C9C" }}>Status:</Typography>
        <Typography
          sx={{
            color:
              status === "finished"
                ? "#34C759"
                : status === "inprogress"
                ? "#FFCC00"
                : status === "upcoming"
                ? "#00CBD2"
                : "#FF3B30",
            textTransform: "capitalize",
            fontWeight: 700,
            fontSize: "18px",
          }}
        >
          {joruneyStatuses[status]}
        </Typography>

        {isShowRefundBtn && status == "canceled_by_user" && (
          <Button
            onClick={openCompensationModal}
            sx={{ width: "fit-content", px: 3, bgcolor: vivideOrange, color: "white", "&:hover": { bgcolor: vivideOrange } }}
          >
            Refund / Compensation
          </Button>
        )}
        {isShowRefundBtn && status == "canceled_by_expert" && (
          <Button
            onClick={openRefundModal}
            sx={{ width: "fit-content", px: 3, bgcolor: vivideOrange, color: "white", "&:hover": { bgcolor: vivideOrange } }}
          >
            Refund to adventurer
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
