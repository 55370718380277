import { Accordion, AccordionDetails, AccordionSummary, Typography, Stack, Box } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleIcon from "@mui/icons-material/Circle";
import { images } from "assets/AssetHelper";
import { useState } from "react";
import { useThemePalette } from "common/hooks/theme_palette";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import { icons } from "assets/AssetHelper";

const SideNavbar = () => {
  const { textGray, vivideOrange } = useThemePalette();
  const location = useLocation();
  const path = location.pathname;
  const [expanded, setExpanded] = useState(false);
  const [nestedexpanded, setnestedExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNestedChange = (panel) => (event, isExpanded) => {
    setnestedExpanded(isExpanded ? panel : false);
  };

  const sidebarNavs = [
    { title: "Dashboard", path: "/", type: "normal", icon: icons.dashboard },
    { title: "Adventurer", path: "/adventurer", type: "normal", icon: icons.adventurer },
    {
      title: "Experts",
      path: "",
      type: "",
      icon: icons.expert,
      list: [
        { title: "Experts list ", path: "/experts", type: "normal" },
        {
          title: "Transfer money requests ",
          path: "/experts/transfer-money-requests",
          type: "normal",
        },
        { title: "Experts requests ", path: "/experts/requests", type: "normal" },
        { title: "Verification requests ", path: "/experts/verification-requests", type: "normal" },
        { title: "Reported experts ", path: "/experts/reported", type: "normal" },
      ],
    },
    { title: "Equipment", path: "/equipment", type: "normal", icon: icons.equipment },
    {
      title: "Journeys",
      icon: icons.Journeys,
      path: "",
      type: "",
      list: [
        { title: "Journeys list", path: "/journeys", type: "normal" },
        { title: "Reported journey chat", path: "/journeys/reported_chat", type: "normal" },
      ],
    },

    { title: "Bookings", path: "/bookings", type: "normal", icon: icons.Booking },
    // { title: "Transcations", path: "/transcations", type: "normal", icon: icons.Transactions },
    {
      title: "Community",
      icon: icons.Community,
      path: "",
      type: "",
      list: [
        { title: "Posts List", path: "/community/postlist", type: "normal" },
        { title: "Reported posts", path: "/community/reportedposts", type: "normal" },
      ],
    },

    { title: "Reports", path: "/reports", type: "normal", icon: icons.Reports },
    { title: "Notifications", path: "/system-notifications", type: "normal", icon: icons.Notifications },
    {
      title: "Lookups",
      icon: icons.lookups,
      path: "",
      type: "",
      list: [
        {
          title: "Categories",
          path: "",
          type: "normal",
          list: [
            { title: "Main categories ", path: "/categories/maincategories", type: "normal" },
            { title: "Sub categories ", path: "/categories/subcategories", type: "normal" },
          ],
        },

        { title: "Countries", path: "/countries", type: "normal" },
        { title: "Cities", path: "/cities", type: "normal" },
        {
          title: "Expert Questions",
          path: "",
          type: "normal",
          list: [
            { title: "Free Text", path: "/expertquestions/freetext", type: "normal" },
            { title: "Multi Choices ", path: "/expertquestions/multichoices", type: "normal" },
          ],
        },
        { title: "Report reasons", path: "/reportreasons", type: "normal" },

        { title: "Journey cancelation reasons for expert", path: "/joruneyCancellationReasons", type: "normal" },
      ],
    },
    { title: "Onboarding", path: "/onboarding", type: "normal", icon: icons.Onboarding },
    {
      title: "Sys configuration",
      path: "/system-configurations",
      type: "normal",
      icon: icons.gear,
    },

    {
      title: "Documents",
      icon: icons.Document,
      path: "",
      type: "",
      list: [
        { title: "Terms & Conditions", path: "/documents/terms&conditions", type: "normal" },
        { title: "Privacy policy", path: "/documents/privacy_policy", type: "normal" },
        { title: "Cancellation Policy", path: "/documents/cancellation_policy", type: "normal" },
      ],
    },
    {
      title: "Contact Us",
      path: "/contact-us",
      type: "normal",
      icon: icons.ContactUs,
    },
  ];

  return (
    <Stack width="100%" height="100vh" direction="column" sx={{ backgroundColor: "#1A1A1A", overflowY: "scroll" }}>
      {/* -------------------- LOGO -------------------- */}
      <Stack direction="row" alignItems="center" sx={{ borderBottom: "1px solid #404040", px: 3, pt: 2, pb: 5 }}>
        <Box component="img" src={images.Logo2} loading="lazy" width="150px" alt="" />
      </Stack>
      {/* -------------------- NAVS -------------------- */}
      <Stack direction="column" spacing={2}>
        {sidebarNavs?.map((data, index) => {
          return data?.type === "normal" ? (
            <Stack
              direction="row"
              key={index}
              px={3}
              sx={{
                bgcolor: path === data.path ? vivideOrange : "",
                height: "50px",
                // border:'1px solid red'
              }}
              onClick={() => setExpanded(false)}
            >
              <img src={data?.icon} alt="" width="24px" />
              <NavLink
                to={data?.path}
                style={{
                  fontSize: "16px",
                  fontFamily: "Nunito",
                  textDecoration: "none",
                  color: "white",
                  marginInlineStart: "5px",
                  // border: "1px solid blue",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {data?.title}
              </NavLink>
            </Stack>
          ) : (
            <Accordion
              key={index}
              sx={{
                width: "100%",
                border: "none !important",
                boxShadow: "none",
                background: "transparent",
                padding: "0",
              }}
              className="nav-accordion"
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ArrowForwardIosIcon sx={{ transform: "rotate(90deg)", color: "white" }} fontSize="small" />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{ padding: "0", paddingX: "15px" }}
              >
                <Stack direction="row" alignItems="center">
                  <img src={data?.icon} alt="" width="24px" style={{ marginLeft: "10px" }} />
                  <NavLink
                    to={data?.path ? data?.path : null}
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Nunito",

                      textDecoration: "none",
                      color: "white",
                      marginInlineStart: "5px",
                    }}
                  >
                    {data?.title}
                  </NavLink>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "#F0F3FF",
                  height: "100%",
                  padding: "0",
                  width: "80%",
                  mx: "auto",
                  backgroundColor: "transparent",
                }}
              >
                {data?.list?.map((val, idx) => {
                  return !val?.list ? (
                    <Typography
                      // padding="15px"
                      // paddingX="25px"
                      key={idx}
                      sx={{
                        display: "flex",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                    >
                      <NavLink
                        to={val?.path}
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textDecoration: "none",
                          color: "white",
                          padding: "10px",
                          width: "100%",
                          backgroundColor: path === val?.path && vivideOrange,
                        }}
                      >
                        <Stack flexDirection={"row"} alignItems={"center"}>
                          <CircleIcon
                            sx={{
                              color: path === val?.path ? "white" : "#727272",
                              mr: "10px",
                              fontSize: "8px",
                              textAlign: "start",
                            }}
                          />
                          <Typography> {val?.title}</Typography>
                        </Stack>
                      </NavLink>
                    </Typography>
                  ) : (
                    <Accordion
                      key={idx}
                      sx={{
                        width: "100%",
                        border: "none !important",
                        boxShadow: "none",
                        background: "transparent",
                        padding: "0",
                      }}
                      className="nav-accordion"
                      expanded={nestedexpanded === `panel${idx}`}
                      onChange={handleNestedChange(`panel${idx}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowForwardIosIcon sx={{ transform: "rotate(90deg)", color: "gray" }} fontSize="small" />}
                        aria-controls={`panel${idx}a-content`}
                        id={`panel${idx}a-header`}
                        sx={{ padding: "0", paddingX: "10px" }}
                      >
                        <Stack direction="row" alignItems="center">
                          <NavLink
                            to={val?.path ? val?.path : null}
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              textDecoration: "none",
                              color: "white",
                              // marginInlineStart: "5px",
                            }}
                          >
                            <Stack flexDirection={"row"} alignItems={"center"}>
                              <CircleIcon
                                sx={{
                                  color: path === val?.path ? "white" : "#727272",
                                  mr: "10px",
                                  fontSize: "8px",
                                  textAlign: "start",
                                }}
                              />
                              <Typography> {val?.title}</Typography>
                            </Stack>
                          </NavLink>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          background: "#F0F3FF",
                          height: "100%",
                          padding: "0",
                          width: "80%",
                          mx: "auto",
                          backgroundColor: "transparent",
                        }}
                      >
                        {val?.list?.map((nestedVal, nestedIdx) => {
                          return (
                            <Typography
                              // padding="15px"
                              // paddingX="25px"
                              key={nestedIdx}
                              sx={{
                                display: "flex",
                                borderRadius: "4px",
                                overflow: "hidden",
                              }}
                            >
                              <NavLink
                                to={nestedVal?.path}
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  textDecoration: "none",
                                  color: "white",
                                  padding: "10px",
                                  width: "100%",
                                  backgroundColor: path === nestedVal?.path && vivideOrange,
                                }}
                              >
                                <Stack flexDirection={"row"} alignItems={"center"}>
                                  <CircleIcon
                                    sx={{
                                      color: path === val?.path ? "white" : "#727272",
                                      mr: "10px",
                                      fontSize: "8px",
                                      textAlign: "start",
                                    }}
                                  />
                                  <Typography> {nestedVal?.title}</Typography>
                                </Stack>
                              </NavLink>
                            </Typography>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default SideNavbar;
