/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  statistics: {},
  intervalType: "daily",
  intervalPeriod: 10,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function StatisticsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_STATISTICS_SUCCESS: {
      return {
        ...state,
        statistics: payload?.data?.statistics,
      };
    }

    case Types.SET_INTERVAL_TYPE: {
      return {
        ...state,
        intervalType: payload?.type,
      };
    }

    case Types.STATISTICS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
