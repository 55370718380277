import { Box, Stack, Typography } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => {
  return [
    {
      head: "Details",
      icon: icons.detailsIcon,
      details: [
        { label: "Expert full name", name: data?.user?.fullname },
        { label: "User name", name: data?.user?.username },
      ],
    },
    {
      head: "Document images",
      icon: icons?.imageIcon,
      details: [
        { label: "Document Type", name: data?.document_type, width: "100%" },
        {
          label: "Uploaded Document",
          name: (
            <Stack direction="row" gap={2}>
              {data?.media?.map((media) => (
                <a href={media?.url} target="_blank" rel="noreferrer noopener">
                  <Stack
                    sx={{
                      backgroundImage: `url(${media?.url})`,
                      height: "230px",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                    key={media?.id}
                    component="img"
                    width="542px"
                    height="auto"
                  />
                </a>
              ))}
            </Stack>
          ),
          width: "100%",
        },
      ],
    },

    {
      head: "Social links",
      icon: icons?.SocialLinks,
      details: data?.social_media_links?.map((item, i) => {
        return {
          label: `${i + 1}.Social media platform `,
          name: (
            <>
              {item?.social_medium?.name}:{" "}
              <a href={item?.url} target="_blank">
                {item?.url}
              </a>
            </>
          ),
          width: "100%",
        };
      }),
    },
  ];
};
