import Types from "./Types";

export const GetStatisticsSuccess = (payload) => ({
  type: Types.GET_STATISTICS_SUCCESS,
  payload,
});

export const SetIntervalType = (payload) => ({
  type: Types.SET_INTERVAL_TYPE,
  payload,
});

export const StatisticsLoading = (payload) => ({
  type: Types.STATISTICS_LOADING,
  payload,
});
