import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import RejectionReasonModal from "components/Molecules/Models/RejectionReasonModal";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { ToggleConfirmationModal, ToggleRejectionReasonModal } from "services/modules/modal/Actions";
import {
  ApproveExpertRequest,
  GetRequestDetailsRequest,
  GetRequestsRequest,
  RejectExpertRequest,
} from "services/modules/users/experts/upgradeToExpertRequests";
import UpgradeToExpertsRequestsTemplate from "templates/upgradeToExpert";

function UpgradeToExpertRequestsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { green } = useThemePalette();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.ExpertsRequests.requests);
  const details = useSelector((state) => state.ExpertsRequests.details);
  const count = useSelector((state) => state.ExpertsRequests.count);
  const load = useSelector((state) => state.ExpertsRequests.isloading);

  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  const requestId = selectedRow?.rowData?.id;
  const request = list.find((item) => item?.id == requestId);
  const status = request?.status || details?.status;

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetRequestsRequest(requestData));
  };

  const getDetails = async () => {
    const requestData = {
      params: {
        include: "user",
      },
      id,
    };
    dispatch(GetRequestDetailsRequest(requestData));
  };

  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  // mappedData use for create custom cell to concat multi values or navigate button
  const statuses = {
    pending_approval: "in-review",
    approved: "approved",
    rejected: "rejected",
  };

  const mappedData = list?.map((data, index) => {
    const status = statuses[data?.status];
    return {
      ...data,
      created_at: <>{dayjs(data?.created_at).format("DD-MM-YYYY hh:mm A")}</>,
      status: (
        <>
          <Stack sx={{ width: "fit-content", mx: "auto", gap: 1 }}>
            <ColoredText color={status == "approved" ? "green" : status == "in-review" ? "yellow" : "red"}>{status}</ColoredText>
            {status == "rejected" && (
              <Stack direction="row" alignItems="center" gap={1} mx="auto">
                <Box component="img" width="16px" height="16px" src={icons.eye} />
                <Typography
                  sx={{ fontSize: "12px", color: "#A3A3A3", cursor: "pointer" }}
                  onClick={() => dispatch(ToggleRejectionReasonModal({ type: "display", isOpen: true }))}
                >
                  view reason
                </Typography>
              </Stack>
            )}
          </Stack>
        </>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/experts/requests",
      title: "Experts/Experts requests",
    },
    id && {
      active: false,
      title: "Expert request details",
    },
  ];

  const filterList = [
    {
      type: "SearchableSelect",
      placeholder: "Approval status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value };
        });
      },
      options: [
        { id: "approved", name: "Approved" },
        { id: "rejected", name: "Rejected" },
        { id: "pending_approval", name: "In-review" },
      ],
    },
    {
      type: "SearchableSelect",
      placeholder: "Category ",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, main_category_id: value?.value };
        });
      },
      options: [
        { id: "1", name: "Land" },
        { id: "2", name: "Air" },
        { id: "3", name: "Water" },
      ],
    },
    {
      type: "date",
      onChange: (value) => {
        const created_at = value ? dayjs(value).format("YYYY-MM-DD") : "";
        setFilterObj((s) => {
          return { ...s, created_at };
        });
      },
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return type !== "details";
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return status == "pending_approval" || status == "rejected";
        },
        click: () => {
          handleToggleConfirmationModal();
        },
        text: "Approve",
      },
      {
        condition: () => {
          return status == "pending_approval" || status == "approved";
        },
        click: () => {
          dispatch(ToggleRejectionReasonModal({ type: "new", isOpen: true }));
        },
        text: "Reject",
      },
      {
        condition: () => {
          return status == "rejected";
        },
        click: () => {
          dispatch(ToggleRejectionReasonModal({ type: "edit", isOpen: true }));
        },
        text: "Edit reason",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  const handleInputChange = (e) => {
    setRejectionReason(e.target.value);
  };

  const handleToggleConfirmationModal = () => {
    dispatch(ToggleConfirmationModal());
  };

  const handleApproveRequest = () => {
    const action = () => {
      toast.success("Request has been approved successfully");
      setRejectionReason(() => "");
    };
    //because we use the actions in both the listing and the details
    //if id then we are inside the details
    const requestData = {
      id: id ? id : selectedRow?.rowData?.id,
      action,
    };
    dispatch(ApproveExpertRequest(requestData));
  };

  const handleRejectRequest = (id, reason) => {
    const action = () => {
      toast.success("request has been rejected successfully");
      type !== "details" && setRejectionReason("");
    };
    const requestData = {
      body: {
        expert_request: {
          rejection_reason: reason,
        },
      },
      id,
      action,
    };
    dispatch(ToggleRejectionReasonModal({ type: "", isOpen: false }));
    dispatch(RejectExpertRequest(requestData));
  };

  const handleModalClose = () => {
    //in case the user clears the input, then cancels
    //then we need to populate the modal with the original reason if it's re-opened
    id && setRejectionReason(details?.rejection_reason);
    requestId && setRejectionReason(request?.rejection_reason);
    dispatch(ToggleRejectionReasonModal({ type: "", isOpen: false }));
  };

  const confirmationModal = () => {
    return (
      <ConfirmationModal
        text={[`Approve expert request`, `Are you sure you want to approve this request ?`]}
        mainColor={green}
        onConfirm={handleApproveRequest}
        confirmBtnTitle="Approve"
      />
    );
  };

  const rejectionModal = () => {
    return (
      <RejectionReasonModal
        reason={rejectionReason}
        id={requestId || id}
        onChange={handleInputChange}
        handleRejectRequest={handleRejectRequest}
        onCancel={handleModalClose}
      />
    );
  };

  const modalComponents = [confirmationModal, rejectionModal];

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  useEffect(() => {
    const reason = type == "details" ? details?.rejection_reason : request?.rejection_reason;
    const requestStatus = request?.status || details?.status;

    reason && requestStatus !== "approved" ? setRejectionReason(reason) : setRejectionReason("");
  }, [requestId, details]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <UpgradeToExpertsRequestsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default UpgradeToExpertRequestsPage;
