import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import Section from "./Section";
import AvatarCard from "../Avatar/AvatarCard";

export default function Participants({ details }) {
  const { participants } = details;

  return (
    <Section icon={icons.Participants} title="Participants">
      <Stack direction="row" flexWrap="wrap" gap={3}>
        {participants?.map((user) => (
          <Stack direction="row" alignItems={"start"} gap={2} sx={{ width: "45%" }}>
            <AvatarCard
              data={user}
              style={{
                avatar: { width: "48px", height: "48px" },
                name: { fontSize: "14px", fontWeight: 700 },
                content: { fontSize: "12px" },
              }}
              showWallet={false}
              showRate={false}
            />
          </Stack>
        ))}
      </Stack>
    </Section>
  );
}
