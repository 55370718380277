/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  Journeys:[],
  Journey:{},
  Participants:[],
  Experts:[],
  count: 0,
  isloading: {loading:null,loadingType:""},
  error: null,
};

export default function JourneysReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_JOURNEYS_SUCCESS: {
      return {
        ...state,
        Journeys: payload?.data?.journeys,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_JOURNEY_DETAIL_SUCCESS: {
      return {
        ...state,
        Journey: payload?.data?.journey
      };
    }

    case Types.GET_PARTICIPANTS_SUCCESS:{
      return{
        ...state,
        Participants:payload?.data?.participants
      }
    }

    case Types.JOURNEYS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    case Types.GET_EXPERTS: {
      return {
        ...state,
        Experts: payload?.data?.users
      };
    }


    default: {
      return state;
    }
  }
}
