import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStatisticsRequest } from "services/modules/statistics";
import { SetIntervalType } from "services/modules/statistics/Actions";
import StatisticsTemplate from "templates/statistics";

export default function StatisticsPage() {
  const dispatch = useDispatch();

  const statistics = useSelector((state) => state.statistics.statistics);
  const interval_type = useSelector((state) => state.statistics.intervalType);
  const load = useSelector((state) => state.statistics.isloading);

  const intervalsOptions = [
    { id: "daily", name: "Daily" },
    { id: "weekly", name: "Weekly" },
    { id: "monthly", name: "Monthly" },
    { id: "yearly", name: "Yearly" },
  ];

  const defaultInterval = {
    label: interval_type[0].toUpperCase() + interval_type.substring(1),
    value: interval_type,
  };

  const breadCrumbs = [{ title: "Home", isActive: false }];

  const getStatistics = (interval_type) => {
    const requestData = {
      params: {
        interval_type,
      },
    };
    dispatch(GetStatisticsRequest(requestData));
  };

  const handleIntervalChange = (e) => {
    const type = e.value;
    dispatch(SetIntervalType({ type }));
  };

  useEffect(() => {
    getStatistics(interval_type);
  }, [interval_type]);

  return (
    <>
      <Loader open={load?.loading} />
      <StatisticsTemplate
        breadCrumbs={breadCrumbs}
        intervals={intervalsOptions}
        handleIntervalChange={handleIntervalChange}
        defaultValue={defaultInterval}
        statistics={statistics}
      />
    </>
  );
}
