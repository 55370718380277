import { Box, Stack, Typography, Divider,Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { forgetPasswordSchema } from "common/utils/schemas";
import { OTPRequest } from "services/modules/auth";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import FormUse from "components/Atoms/SharedComponents/CRUD/form-use";
import { structure } from "./structure";
import "./style.css";
import Cookies from "js-cookie";
export default function ForgetPasswordForm() {
  const { gray,vivideOrange } = useThemePalette();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state?.auth);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: (values, { setSubmitting }) => {
      const action = (data) => {
        Cookies.set("forgetPasswordEmail", values.email);
        navigate("/verify-otp");
      };
      setSubmitting(false);
      const requestBody = {
        body: {
          user: {
            email: values.email,
          },
        },
        action,
      };
      dispatch(OTPRequest(requestBody));
      },
    validationSchema:forgetPasswordSchema
  });

  return (
    <Box>
      <Loader open={isLoading?.loading} />
      <Stack flexDirection={'column'} gap={2}>

        <Box
          component="img"
          src={icons.lockIcon}
          sx={{ width: "60px", height: "80px" }}
        />
        
        <Stack sx={{ gap: 2 }}>
          <Typography  fontSize="32px" fontWeight="800">
            Forgot password?
          </Typography>
          <Typography  fontSize="16px" color={gray}>
            No worries ,We will send you reset instruction
          </Typography>
          <Divider sx={{ border: "1px solid #F5F5F5" }} />
        </Stack>
        {/* --------------------- FORM --------------------- */}
        <FormUse
          formStructure={structure}
          className="forget-password-form"
          formik={formik}
        >
            <Stack sx={{mt:5}}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontSize: "20px",
                  p: 2,
                  backgroundColor: vivideOrange,
                  "&:hover": {
                    backgroundColor: vivideOrange,
                  },
                }}
              >
                Reset Password
            </Button>

            </Stack>
            </FormUse>
        </Stack>
    </Box>
  );
}
