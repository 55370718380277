import { Button, Stack, Box, Typography, Divider } from "@mui/material";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OTPRequest, OTPVerify } from "services/modules/auth";
import OTPInput, { ResendOTP } from "otp-input-react";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import "./style.css";
import { useFormik } from "formik/dist";
import Cookies from "js-cookie";
import { useEffect } from "react";
export default function VerifyOTPForm() {
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  
  const { gray, orange,vivideOrange } = useThemePalette();
  const { isLoading } = useSelector((state) => state.auth);
  
  const email = Cookies.get("forgetPasswordEmail");
  
  useEffect(()=>{
    if(!email)
    {
      navigate("/");
    }
  },[])

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      const action = (data) => {
        Cookies.set("verification-token", data?.data?.token);
        navigate("/reset-password");
      };
      const requestData = {
        body: {
          verification_code: OTP,
          user: {
            email,
          },
        },
        action,
      };
      dispatch(OTPVerify(requestData));  
    },
  });

  const handleResendEmail = () => {
    const requestBody = {
      body: {
        user: {
          email,
        },
      },
    };
    dispatch(OTPRequest(requestBody));
  };



  const renderButton = (buttonProps) => {
    return (
      <Typography
        sx={{
          color: buttonProps.remainingTime !== 0 ? "#727272" : orange,
          cursor: buttonProps.remainingTime !== 0 ? " not-allowed" : "pointer",
          fontWeight: "600",
          position: "absolute",
          top: 0,
          right: "70px",
          fontFamily: "Jost",
        }}
        {...buttonProps}
      >
        Resend
      </Typography>
    );
  };

  const renderTime = (remainingTime) => {
    return <Typography>{remainingTime}s</Typography>;
  };

  return (
    <>
      <Loader open={isLoading?.loading} />
      <Stack sx={{ width: "440px" }}>
        <Stack sx={{ gap: 2 }}>
          <Box
            component="img"
            src={icons.resetPassword}
            sx={{ width: "80px" }}
          />
          <Stack sx={{ gap: 1 }}>
            <Typography fontFamily="jost" fontSize="32px" fontWeight="400">
              Check your email
            </Typography>
            <Typography fontFamily="jost" fontSize="18px" color={gray}>
              We have sent OTP to {email}
            </Typography>
            <Divider sx={{ border: "1px solid #F5F5F5" }} />
          </Stack>
        </Stack>

        <Box component='form' onSubmit={formik.handleSubmit}>
          <Stack sx={{ mt: 8, gap: 4 }}>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              className="opt-input-container"
              inputClassName="otp-input"
            />


              <Button
                type="submit"
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontSize: "20px",
                  p: 2,
                  backgroundColor: vivideOrange,
                  "&:hover": {
                    backgroundColor: vivideOrange,
                  },
                }}
              >
                DONE
            </Button>

            <Stack
              sx={{
                color: gray,
                textAlign: "center",
                position: "relative",
              }}
            >
              <Typography fontFamily="Jost">
                Didn't receive the email?
              </Typography>
              <ResendOTP
                maxTime={30}
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={handleResendEmail}
                className="reset-otp-container "
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
