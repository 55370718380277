import Types from "./Types";

export const GetReportedChatsSuccess = (payload) => ({
  type: Types.GET_REPORTED_CHATS_SUCCESS,
  payload,
});

export const DeleteReportedChatSuccess = (payload) => ({
  type: Types.DELETE_REPORTED_CHAT_SUCCESS,
  payload,
});

export const ReportedChatsLoading = (payload) => ({
  type: Types.REPORTED_CHATS_LOADING,
  payload,
});
