import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import Section from "./Section";

export default function Equipment({ details }) {
  const { reservation_equipment } = details;

  return (
    <Section icon={icons.equipmentIcon} title="Equipment">
      <Stack direction="row" flexWrap="wrap" gap={3}>
        {reservation_equipment?.map((item) => (
          <Stack direction="row" gap={1} sx={{ width: "45%" }}>
            <Box component="img" src={item?.equipment?.media?.[0]?.url} width="58px" height="58px" />
            <Stack justifyContent="space-between">
              <Typography fontSize="14px">{item?.equipment?.name}</Typography>
              {item?.equipment?.is_rentable && (
                <Typography fontSize="14px">
                  <span style={{ fontWeight: 700 }}>
                    {item?.equipment?.rental_price} {item?.equipment?.currency?.lookup_key}{" "}
                  </span>
                  For Rent {item?.rent_quantity >= 0 && `/ Quantity :${item?.rent_quantity}`}
                </Typography>
              )}
              {item?.equipment?.is_purchasable && (
                <Typography fontSize="14px">
                  <span style={{ fontWeight: 700 }}>
                    {item?.equipment?.purchase_price} {item?.equipment?.currency?.lookup_key}{" "}
                  </span>
                  For Buy {item?.purchase_quantity >= 0 && `/ Quantity :${item?.purchase_quantity}`}
                </Typography>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Section>
  );
}
