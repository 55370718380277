import { icons } from "assets/AssetHelper";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { formatDate } from "common/utils/helpers";

export const detailsData = ({ data }) => [
  {
    // head: '',
    // icon: icons?.detailsIcon,
    details: [
      {
        // label: '',
        width: "100%",
        name: (
          <Stack flexDirection={"column"} gap={"10px"}>
            <Typography sx={{ marginLeft: "auto", float: "right" }} fontSize={"20px"} fontWeight={600}>
              Updated {formatDate(data?.updated_at)}
            </Typography>
            <div className="ql-editor" style={{ padding: 0 }} dangerouslySetInnerHTML={{ __html: data?.content }} />
          </Stack>
        ),
      },
      // very important step because normally there are ql classes
      //that won't be recognized by usual html we have to use classname ql-editor (react quill)
    ],
  },
];
