import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import {
  GetDocumentDetailsRequest,
  GetTermsAndCondtionsRequest,
  updateDocumentRequest,
} from "services/modules/documents";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import TermsAndConditionsTemplate from "templates/termsAndConditions";
import { formatDate } from "common/utils/helpers";

function TermsAndConditionsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.document.TermsAndConditions);
  const count = useSelector((state) => state.document.count);
  const details = useSelector((state) => state.document.document);
  const load = useSelector((state) => state.document.isloading);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // console.log('selected row is ',selectedRow)
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(GetDocumentDetailsRequest({ id }));
  };
  // details of a single user
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj
      },
    };
    dispatch(GetTermsAndCondtionsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      created_at: <>{formatDate(data?.created_at)}</>, //moseba f kolo
      updated_at: <>{formatDate(data?.updated_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: false,
      path: "/",
      title: "Documents",
    },

    {
      active: !type ? false : true,
      path: "/documents/terms&conditions",
      title: "Terms & Conditions",
    },
    type && {
      active: false,
      title:
        type === "details"
          ? id === "3"
            ? "Adventurer Terms & Conditions Details"
            : "Expert Terms & Conditions Details"
          : id
          ? id === "3"
            ? "Edit Adventurer Terms & Conditions"
            : "Edit Expert Terms & Conditions"
          : id === "3"
          ? "Add Adventurer Terms & Conditions"
          : "Add Expert Terms & Conditions",
    },
  ];
  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/documents/terms&conditions");
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(
        `Terms & Conditions has been ${id ? "updated" : "created"} successfully`
      );
    };
    const requestData = {
      body: {
        document: {
          content: data?.content,
        },
      },
      action,
    };
    if (id) {
      //update call
      dispatch(updateDocumentRequest({ ...requestData, id }));
    }
  };

  const filterList = [];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return data.content
      ? [
          {
            condition: () => {
              return true;
            },
            click: () => {
              navigate(`?type=details&id=${data?.id}`);
            },
            text: "View",
          },
          {
            condition: () => {
              return true;
            },
            click: () => {
              navigate(`?type=form&id=${data?.id}`);
            },
            text: "Edit",
          },
        ]
      : [
          {
            condition: () => {
              return true;
            },
            click: () => {
              navigate(`?type=form&id=${data?.id}`);
            },
            text: "Add",
          },
        ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading && load?.type === "Terms And Condtions"} />
      <TermsAndConditionsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default TermsAndConditionsPage;
