import { formatDate } from "common/utils/helpers";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreateSystemNotificationReuqest,
  GetSystemNotificationsReuqest,
} from "services/modules/notifications/systemNotifications";
import SystemNotificationsTemplate from "templates/systemNotificaitons";

function SystemNotificationsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.systemNotifications.notifications);
  const count = useSelector((state) => state.systemNotifications.count);
  const load = useSelector((state) => state.systemNotifications.isloading);

  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetSystemNotificationsReuqest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      id: <span onClick={() => void 0}>{data?.id}</span>,
      sent_at: <>{formatDate(data?.created_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/system-notifications",
      title: "Notifications",
    },
    type && {
      active: false,
      title: "Add notification",
    },
  ];

  const handelSubmit = (data, formik) => {
    const userClassifications = {
      0: "adventurer",
      1: "expert",
      3: null,
    };
    const user_classification = userClassifications[data?.user_classification];

    const action = () => {
      navigate("/system-notifications");
      formik?.resetForm();
      toast.success(`Notifications has been added successfully!`);
    };
    const requestData = {
      body: {
        notification: {
          ...data,
          user_classification,
        },
      },
      action,
    };

    dispatch(CreateSystemNotificationReuqest(requestData));
  };

  const filterList = [];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <SystemNotificationsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default SystemNotificationsPage;
