import { reduxRequest } from "common/utils/reduxRequest";
import { makeFilterString } from "../../../utils/Helper";
import {
  FulfillMoneyTransferSuccess,
  GetMoneyTransferRequestDetailsSuccess,
  GetMoneyTransferRequestsSuccess,
  MoneyTransferRequestsLoading,
} from "./Actions";

export const GetMoneyTransferRequestsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: MoneyTransferRequestsLoading,
    successFunction: GetMoneyTransferRequestsSuccess,
    url: `/admin/money_transfer_requests${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetMoneyTransferRequestDetailsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: MoneyTransferRequestsLoading,
    successFunction: GetMoneyTransferRequestDetailsSuccess,
    url: `/admin/money_transfer_requests/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const FulfillMoneyTransferRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "update",
    isLoading: MoneyTransferRequestsLoading,
    successFunction: FulfillMoneyTransferSuccess,
    url: `/admin/money_transfer_requests/${variables?.id}`,
    method: "patch",
    data: variables?.body,
    action: variables?.action,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
