import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import Search from "components/Atoms/Input/Search/Search";
import Notifications from "components/Molecules/Notifications/Notifications";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import Cookies from "js-cookie";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutRequest } from "services/modules/auth";
import { SignoutFirebase } from "services/utils/Firebase";
import { formatDate } from "common/utils/helpers";
const Header = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { grayBorder } = useThemePalette();

  const userInfo = useSelector((state) => state.auth.admin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    SignoutFirebase();

    Cookies.remove("token");
    Cookies.remove("firebase_auth_token");
    Cookies.remove("UID");

    localStorage.clear();

    handleClose();
    dispatch(LogoutRequest({}));
    navigate("/");
  };

  //logout must has its own open state, as we cant count on the global (redux) state
  // because if we have another confirmation modal in a page where we try to logout, that will open that modal
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Update the time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <ConfirmationModal
        open={openLogoutModal}
        setOpen={setOpenLogoutModal}
        onConfirm={handleLogout}
        text={["Are you sure you want to logout?"]}
        confirmBtnTitle="Logout"
      />
      <Stack
        bgcolor="white"
        p={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        fontWeight="500"
        sx={{ boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}
      >
        {/* -------------- TITLE -------------- */}
        <Typography gutterBottom component="div" fontSize="16px" fontWeight="600">
          {/* Current time : 2:24 PM, Mar 11, 2024 ( GMT +00.00) */}
          Current time : {formatDate(currentTime, "h:mm A, MMM D, YYYY ( [GMT] Z )")}
        </Typography>
        {/* -------------- SEARCH & NOTIFICATION & USER INFO -------------- */}
        <Stack direction="row" justifyContent="space-between" alignItems="center" width={{ sm: "100%", md: "75%", xl: "75%" }}>
          {/* -------------- SEARCH --------------
          <Search placeholder="Search by  Journey , expert .." orange /> */}
          <Stack direction="row" justifyContent="space-between" alignItems="center" marginLeft={"auto"} gap={2}>
            {/* -------------- NOTIFICATION  -------------- */}
            <Notifications />
            {/* --------------  USER INFO  -------------- */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              sx={{ borderLeft: `1px solid ${grayBorder}`, px: 3 }}
            >
              {/* -------------- AVATAR -------------- */}
              {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ borderRadius: "50%" }} /> */}
              {/* -------------- USERNAME -------------- */}
              <Typography fontFamily="Jost" fontWeight="500" fontSize="18px">
                {userInfo?.name}
              </Typography>
              {/* -------------- DROP DOWN -------------- */}
              <Box>
                <Box
                  sx={{ display: "flex" }}
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <ArrowForwardIosIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
                </Box>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      setOpenLogoutModal(true);
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
