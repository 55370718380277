import AuthLayout from "components/Organisms/login/layout/AuthLayout";
import "firebase/auth";
import Cookies from "js-cookie";
import AdventurerPage from "pages/adventurer/Adventurer";
import ForgetPasswordPage from "pages/auth/forgetPassword";
import LoginPage from "pages/auth/login";
import ResetPasswordPage from "pages/auth/resetPassword";
import ResetPasswordSuccessPage from "pages/auth/resetPasswordSuccess";
import VerifyOTPPage from "pages/auth/verifyOTP";
import BookingsPage from "pages/bookings/Booking";
import CommunityPostsListPage from "pages/community/PostsList";
import CommunityReportedPostsPage from "pages/community/ReportedPosts";
import ContactUsPage from "pages/contactUs/contactUs";
import EarningReportsPage from "pages/earningReports/EarningReports";
import EquipmentPage from "pages/equipment/Equipment";
import ExpertsListPage from "pages/experts/experts-list/ExpertsList";
import JourneysPage from "pages/journeys/Journeys";
import CancellationPolicyPage from "pages/lookups/CancellationPolicyPage";
import CitiesPage from "pages/lookups/Cities";
import CountriesPage from "pages/lookups/Countries";
import ExpertQuestionsFreeTextPage from "pages/lookups/ExpertQuestionsFreeText";
import ExpertQuestionsMultiplePage from "pages/lookups/ExpertQuestionsMultiple";
import JoruneyCancellationReasonsPage from "pages/lookups/JourneyCancelationReasons";
import MainCategoriesPage from "pages/lookups/MainCategories";
import PrivacyPolicyPage from "pages/lookups/PrivacyPolicyPage";
import ReportReasonsPage from "pages/lookups/ReportReasons";
import SubCategoriesPage from "pages/lookups/SubCategories";
import TermsAndConditionsPage from "pages/lookups/TermsAndConditions";
import OnBoardingPage from "pages/onboarding/OnBoarding";
import ReportedChatsPage from "pages/reportedChats/ReportedChats";
import ReportedExpertsPage from "pages/reportedExperts/ReportedExperts";
import StatisticsPage from "pages/statistics/Statistics";
import SystemConfigurationPage from "pages/systemConfiguration/systemConfiguration";
import SystemNotificationsPage from "pages/systemNotifications/SystemNotifications";
import TransferMoneyRequestsPage from "pages/transferMoneyRequests/TransferMoneyRequests";
import UpgradeToExpertRequestsPage from "pages/upgradeToExpert/UpgradeToExpertRequests";
import VerificationRequestsPage from "pages/verificationRequests/VerificationRequests";
import { useNavigate } from "react-router";
import { Route, Routes } from "react-router-dom";

import Layout from "./layout";

const AppRoute = () => {
  const navigate = useNavigate();
  const isAuth = Cookies.get("token");

  function ProtectedRoute({ children }) {
    if (!localStorage.getItem("token")) {
      // Navigate to the login page when the user is not authenticated
      navigate("/login");
      // Return null or some loading indicator if you prefer
      return null;
    } else {
      return children;
    }
  }

  return (
    <Routes>
      {isAuth ? (
        <Route path="/" element={<Layout />}>
          <Route index element={<StatisticsPage />} />
          <Route path="experts" element={<ExpertsListPage />} />
          <Route path="experts/transfer-money-requests" element={<TransferMoneyRequestsPage />} />
          <Route path="experts/requests" element={<UpgradeToExpertRequestsPage />} />
          <Route path="/experts/verification-requests" element={<VerificationRequestsPage />} />
          <Route path="/experts/reported" element={<ReportedExpertsPage />} />
          <Route path="adventurer" element={<AdventurerPage />} />
          <Route path="equipment" element={<EquipmentPage />} />
          <Route path="bookings" element={<BookingsPage />} />
          <Route path="transcations" element={<div>transcations</div>} />
          <Route path="reports" element={<EarningReportsPage />} />
          <Route path="system-notifications" element={<SystemNotificationsPage />} />
          <Route path="/journeys" element={<JourneysPage />} />
          <Route path="/journeys/reported_chat" element={<ReportedChatsPage />} />
          <Route path="/community/postlist" element={<CommunityPostsListPage />} />
          <Route path="/community/reportedposts" element={<CommunityReportedPostsPage />} />

          <Route path="reports" element={<div>reports</div>} />
          <Route path="notifications" element={<div>notifications</div>} />

          <Route path="/journeys/journey_list" element={<JourneysPage />} />
          <Route path="/journeys" element={<JourneysPage />} />
          <Route path="/journeys/reported_chat" element={<ReportedChatsPage />} />

          <Route path="reportreasons" element={<ReportReasonsPage />} />
          <Route path="categories/maincategories" element={<MainCategoriesPage />} />
          <Route path="categories/subcategories" element={<SubCategoriesPage />} />
          <Route path="cities" element={<CitiesPage />} />
          <Route path="countries" element={<CountriesPage />} />
          <Route path="system-configurations" element={<SystemConfigurationPage />} />
          <Route path="expertquestions/freetext" element={<ExpertQuestionsFreeTextPage />} />
          <Route path="expertquestions/multichoices" element={<ExpertQuestionsMultiplePage />} />
          <Route path="joruneyCancellationReasons" element={<JoruneyCancellationReasonsPage />} />

          <Route path="onboarding" element={<OnBoardingPage />} />
          <Route path="documents/terms&conditions" element={<TermsAndConditionsPage />} />
          <Route path="documents/privacy_policy" element={<PrivacyPolicyPage />} />
          <Route path="documents/cancellation_policy" element={<CancellationPolicyPage />} />
          <Route path="contact-us" element={<ContactUsPage />} />
        </Route>
      ) : (
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<LoginPage />} />
          <Route path="forget-password" element={<ForgetPasswordPage />} />
          <Route path="verify-otp" element={<VerifyOTPPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="reset-password-success" element={<ResetPasswordSuccessPage />} />
        </Route>
      )}
    </Routes>
  );
};

export default AppRoute;
