import {  Stack,Box,Button } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import { useThemePalette } from "common/hooks/theme_palette";

import { icons } from "assets/AssetHelper";


const CMultiInput = ({
  label,
  placeholder,
  type = "text",
  index,
  name,
  data,
  onChange,
  handleDeleteOption,
//   formik,
}) => {
  const {  orange } = useThemePalette();
  const {  red } = useThemePalette();

    const deleteIcon=
    <Button
    sx={{
      color: red,
      border: `1px solid ${red}`,
      padding:'10px',
      minWidth:0
    }}
    onClick={() => {
        handleDeleteOption(index)
    }}
  >
    <Box
      component="img"
      src={icons.templateDeleteIcon}
      width="18px"
      height="18px"
    />
  </Button>

  return (
    <Stack mb="5" position="relative">
      <TextFieldInput
        id={index}
        type={type}
        label={label +` ${index+1}`}
        labelStyle={{
          color: "#797979",
          fontSize: "18px",
          mb: 2,
        }}
        placeholder={placeholder}
        EndAdornment={index+1>2?deleteIcon:null}
        sx={{
          background:'#FBFBFB',
          "& label.Mui-focused": {
            color: "white",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: orange,
            },
          },
        }}
        onChange={onChange}
        value={data.option || ""}
        // onBlur={formik.handleBlur}
        name={name}
        // {...rest}
      />
      
      {/* <CFormErrors errorsMsg={errorsMsg} /> */}
    </Stack>
  );
};

export default CMultiInput;
