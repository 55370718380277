import { icons } from "assets/AssetHelper";
import Section from "./Section";
import { Divider, Stack, Typography } from "@mui/material";

export default function PaymentDetails({ details }) {
  const paymentDetails = [
    {
      quantity: details?.number_of_participants,
      name: "participants:",
      value: details?.journey_total,
    },
    {
      quantity: details?.number_of_rented_equipment,
      name: "item for rent :",
      value: details?.rented_equipment_total,
    },
    {
      quantity: details?.number_of_purchased_equipment,
      name: "item for buy:",
      value: details?.purchased_equipment_total,
    },
    {
      quantity: null,
      name: "Vat:",
      value: details?.vat_amount,
    },
  ];
  return (
    <Section icon={icons.PaymentDetails} title="Payment details">
      {paymentDetails?.map(
        (item, idx) =>
          item?.quantity !== 0 && (
            <Stack direction="row" justifyContent="space-between" key={idx}>
              <Typography sx={{ fontSize: "18px", color: "#969696" }}>
                {item?.quantity ? `${item?.quantity} x` : ""} {item?.name}
              </Typography>
              <Typography sx={{ fontSize: "18px" }}>
                {item?.value} {details?.currency?.lookup_key}
              </Typography>
            </Stack>
          )
      )}
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "22px", color: "#969696" }}>Total Cost:</Typography>
        <Typography sx={{ fontSize: "22px", fontWeight: 700 }}>
          {details?.total_amount} {details?.currency?.lookup_key}
        </Typography>
      </Stack>
    </Section>
  );
}
