import { makeFilterString } from "../../utils/Helper";
import {
  GetUsersSuccess,
  GetUserDetailsSuccess,
  UsersLoading,
  UpdateUserStatusSuccess,
  GetCategoriesSuccess,
  GetWalletDetailsSuccess,
  JourneysLoading,
  GetJourneysSuccess,
  GetJourneyDetailsSuccess,
  GetScrollUsersSuccess,
} from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const GetUsersRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: UsersLoading,
    successFunction: GetUsersSuccess,
    url: `/admin/users/user${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetScrollUsersRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: UsersLoading,
    loadingType: "list",
    successFunction: GetScrollUsersSuccess,
    url: `/admin/users/user${makeFilterString(variables)}`,
    method: "get",
    variables: variables,
  };

  return async (dispatch) => {
    await reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdateUserStatusRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "update",
    isLoading: UsersLoading,
    successFunction: UpdateUserStatusSuccess,
    url: `/admin/users/user/${variables?.id}`,
    method: "patch",
    data: variables?.body,
    action: variables?.action,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetUserDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: UsersLoading,
    loadingType: "details",
    successFunction: GetUserDetailsSuccess,
    url: `/admin/users/user/${variables?.id}${makeFilterString(variables?.params || {})}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: UsersLoading,
    loadingType: "list",
    successFunction: GetCategoriesSuccess,
    url: `/admin/users/user/${variables.id}/categories${makeFilterString(variables?.params)}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetWalletDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: UsersLoading,
    loadingType: "list",
    successFunction: GetWalletDetailsSuccess,
    url: `/admin/wallets${makeFilterString(variables?.params)}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetJourneysRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: JourneysLoading,
    successFunction: GetJourneysSuccess,
    url: `/admin/reservations${makeFilterString(variables?.params)}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetJourneyDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: JourneysLoading,
    loadingType: "details",
    successFunction: GetJourneyDetailsSuccess,
    url: `/admin/reservations/${variables?.id}`,
    method: "get",
  };
  return (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
