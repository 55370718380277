import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetReportsRequest } from "services/modules/users/experts/reportedExperts";
import ReportedExpertsTemplate from "templates/reportedExperts";

function ReportedExpertsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.reports.reports);
  const count = useSelector((state) => state.reports.count);
  const load = useSelector((state) => state.reports.isloading);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetReportsRequest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      id: <span onClick={() => navigate(`/experts?type=details&id=${data?.reportee?.id}`)}>{data?.id}</span>,
      created_at: <>{dayjs(data?.created_at).format("DD-MM-YYYY hh:mm A")}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/experts/reported",
      title: "Experts/Reported experts",
    },
    type && {
      active: false,
      title: type === "details" && "Details",
    },
  ];

  const filterList = [
    {
      type: "date",
      onChange: (value) => {
        const created_at = value ? dayjs(value).format("YYYY-MM-DD") : "";
        setFilterObj((s) => {
          return { ...s, created_at };
        });
      },
    },
  ];

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`/experts?type=details&id=${data?.reportee?.id}`);
        },
        text: "View",
      },
    ];
  };

  const headerChildren = <></>;

  const modalComponents = [];

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <ReportedExpertsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default ReportedExpertsPage;
