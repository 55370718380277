import { Box, Stack, Typography } from "@mui/material";

export default function Section({ children, icon, title }) {
  return (
    <Stack sx={{ bgcolor: "#fff", p: 3, maxWidth: "98%", minWidth: "46%", borderRadius: "4px", gap: 3 }}>
      <Stack direction="row" gap={1} alignItems="center">
        <Box component="img" src={icon} width="48px" />
        <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>{title}</Typography>
      </Stack>
      {children}
    </Stack>
  );
}
