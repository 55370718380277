import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import CancellationReasonModal from "components/Molecules/Models/CancellationReasonModal";
import { useDispatch } from "react-redux";
import { ToggleCancellationReasonModal } from "services/modules/modal/Actions";

export default function Status({ status, cancellation }) {
  const dispatch = useDispatch();
  const handleViewReason = () => {
    dispatch(ToggleCancellationReasonModal());
  };

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography sx={{ color: "#9C9C9C", fontSize: "16px" }}>Status:</Typography>
      <Typography
        sx={{
          color: status == "finished" ? "#34C759" : status == "canceled" ? "#FF3B30" : "#FFCC00",
          fontSize: "18px",
          fontWeight: 700,
          textTransform: "capitalize",
        }}
      >
        {status}
      </Typography>
      {status == "canceled" && (
        <Stack onClick={handleViewReason} direction="row" sx={{ color: "#FF3B30", fontWeight: 400, cursor: "pointer" }} gap={0.5}>
          (
          <Box component="img" src={icons.eye} />
          <Typography sx={{ fontWeight: 400 }}> View Reason )</Typography>
        </Stack>
      )}
      <CancellationReasonModal cancellation={cancellation} />
    </Stack>
  );
}
