import { makeFilterString } from "../utils/Helper";
import {
GetEquipmentDetailSuccess,GetEquipmentSuccess,EquipmentLoading, GetExpertsSuccess
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetEquipmentsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Equipments",
    isLoading: EquipmentLoading,
    successFunction: GetEquipmentSuccess,
    url: `/admin/equipment?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetEquipmentsDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: EquipmentLoading,
    loadingType: "Equipments details",
    successFunction: GetEquipmentDetailSuccess ,
    url: `/admin/equipment/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetExpertsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Experts",
    isLoading: EquipmentLoading,
    successFunction: GetExpertsSuccess,
    url: `/admin/users/user${makeFilterString(variables?.params)}`,
    method: "get",
  };
//classification=expert
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
