import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Cookies from "js-cookie";
import { Provider as ReduxProvider } from "react-redux";
import setAuthToken from "services/modules/utils/handel_api";
import "./App.css";
import AppRoute from "./common/Route";
import theme from "./common/theme";
import AlertProvider from "./components/Atoms/Alerts/ToastifyAlert";
import { store } from "./services/store";

function App() {
  const token = Cookies.get("token");
  setAuthToken(token);


  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppRoute />
          </LocalizationProvider>
        </AlertProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
