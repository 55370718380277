import { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { GetEquipmentsDetailsRequest, GetEquipmentsRequest, GetExpertsRequest } from "services/modules/equipments";
import EquipmentsTemplate from "templates/equipments";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { GetScrollUsersRequest } from "services/modules/users/users";
import { formatDate } from "common/utils/helpers";
function EquipmentPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const expertslist = useSelector((state) => state.equipment.Experts);

  const list = useSelector((state) => state.equipment.Equipment);
  const count = useSelector((state) => state.equipment.count);
  const details = useSelector((state) => state.equipment.Equipment_detail);
  const load = useSelector((state) => state.equipment.isloading);

  const experts_list = useSelector((state) => state.users.users);
  const experts_count = useSelector((state) => state.users.count);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };
  const getUsersList = (filterObj) => {
    const defaultData = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      classification: "expert",
      sort_by: "id",
    };
    dispatch(GetScrollUsersRequest({ ...defaultData, ...filterObj }));
  };
  const getDetails = async () => {
    dispatch(GetEquipmentsDetailsRequest({ id }));
  };
  // details of a single user
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const getList = async (filterObj) => {
    const requestData = {
      params: filterObj,
    };
    dispatch(GetEquipmentsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      created_at: <>{formatDate(data?.created_at)}</>,
      updated_at: <>{formatDate(data?.updated_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: type === "details" ? true : false,
      path: "/equipment",
      title: "Equipment",
    },
    type && {
      active: false,
      title: type === "details" ? "Equipment Details" : id ? "Edit Equipment" : "Add Equipment",
    },
  ];

  const filterList = [
    {
      type: "SearchableSelect", // this isn't important anyways
      placeholder: "Equipment status",
      onChange: (value) => {
        console.log(value);
        setFilterObj((s) => {
          if (value?.value === 0) {
            return { ...s, is_rentable: true, is_purchasable: false };
          } else if (value?.value === 1) {
            return { ...s, is_rentable: false, is_purchasable: true };
          } else {
            return { ...s, is_rentable: null, is_purchasable: null };
          }
        });
      },
      options: [
        { id: 0, name: "For rent" },
        { id: 1, name: "For buy" },
      ],
    },
    {
      type: "SearchableSelect",
      placeholder: "Expert",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, expert_id: value?.value };
        });
      },
      onMenuOpen: () => {
        experts_list?.length == 0 && getUsersList({});
      },
      options: experts_list?.map((expert) => {
        return {
          name: expert.username,
          id: expert.id,
        };
      }),
      getList: getUsersList,
      count: experts_count,
      applySearchFromRequest: true,
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj)
      getList({
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      });
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <EquipmentsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default EquipmentPage;
