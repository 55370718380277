import { Button, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

const FormHeader = ({ handleModelClose, form, formikData, refsubmit }) => {
  const { gray ,vivideOrange} = useThemePalette();
  return (
    <Stack
      flexDirection="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      // borderBottom="1px solid rgb(217, 217, 217) "
    >
      <Typography fontSize="30px" fontWeight="600" >
        {form?.title}
      </Typography>
      <Stack
        my={2}
        flexDirection="row"
        gap={2}
        className={form?.className?.actionButton}
      >
        {/* add custom header, add cancel, save to it */}
        <Button
          onClick={(e) => {
            handleModelClose();
          }}
          variant="outlined"
          sx={{
            color: '#2C2C2E',
            borderColor: '#2C2C2E',
            "&:hover": {
              borderColor: '#2C2C2E',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => refsubmit?.current?.click()}
          variant="contained"
          sx={{
            backgroundColor: vivideOrange,
            "&:hover": {
              backgroundColor: vivideOrange,
            },
          }}
        >
          {form?.submitTitle ? form?.submitTitle : "Save Changes"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default FormHeader;
