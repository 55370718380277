import { Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { formatDate } from "common/utils/helpers";
import AvatarCard from "components/Molecules/Avatar/AvatarCard";
import dayjs from "dayjs";
export const detailsData = ({ data }) => {
  return [
    {
      head: <AvatarCard data={data} />,
    },
    {
      head: "Personal Information",
      icon: icons?.detailsIcon,
      details: [
        {
          label: "Phone",
          name: data?.phone_number ? "+" + data?.country_code + data?.phone_number : null,
        },
        {
          label: "Birthdate",
          name: data?.date_of_birth,
        },
        {
          label: "Gender ",
          name: data?.gender,
        },
        {
          label: "Email",
          name: data?.email,
        },
        {
          label: "language",
          name: (
            <Stack gap={2} direction="row">
              {data?.languages?.map((lang) => (
                <Typography
                  sx={{
                    bgcolor: "#F3F3F3",
                    p: 1,
                    borderRadius: "15px",
                    color: "#1A1A1A",
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                  key={lang.id}
                >
                  {lang.name}
                </Typography>
              ))}
            </Stack>
          ),
        },
        {
          label: "Bio",
          name: data?.expert_bio,
        },
      ],
    },
    {
      head: "Bank account",
      icon: icons?.Bank,
      details: [
        {
          label: "Beneficiary name : ",
          name: data?.wallet?.beneficiary_name,
        },
        {
          label: "Bank name",
          name: data?.wallet?.bank_code,
        },
        {
          label: "IBAN  :  ",
          name: data?.wallet?.iban,
        },
        {
          label: "Swift code",
          name: data?.wallet?.swift_code,
        },
        {
          label: "Payout country",
          name: data?.wallet?.payout_country?.name,
        },
        {
          label: "Payout currency : ",
          name: data?.wallet?.payout_currency?.name,
        },
      ],
    },
    {
      head: "Dates",
      icon: icons?.detailsIcon,
      details: [
        {
          label: "Created at",
          name: formatDate(data?.created_at),
        },
        {
          label: "Updated at",
          name: formatDate(data?.updated_at),
        },
      ],
    },
  ];
};
