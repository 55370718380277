import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetPostsDetailsRequest, GetPostsRequest } from "services/modules/community/posts";
import CommunityTemplate from "templates/community";

import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import dayjs from "dayjs";
import { GetScrollSubCategoriesRequest } from "services/modules/lookups";
import { GetScrollUsersRequest } from "services/modules/users/users";
import { formatDate } from "common/utils/helpers";

function PostsListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // dates
  // for some reason dates need to be as it is to be able to do conditions like minDate and maxDate so i will save them in a useState
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.posts.Posts);
  const count = useSelector((state) => state.posts.count);
  const details = useSelector((state) => state.posts.Post);
  const load = useSelector((state) => state.posts.isloading);

  const experts_list = useSelector((state) => state.users.users);
  const experts_count = useSelector((state) => state.users.count);

  const subCategories_list = useSelector((state) => state.lookup.SubCategories);
  const subCategories_count = useSelector((state) => state.lookup.count);

  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getUsersList = (filterObj) => {
    const defaultData = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      classification: "expert",
      sort_by: "id",
    };
    dispatch(GetScrollUsersRequest({ ...defaultData, ...filterObj }));
  };

  const geSubCategoriesList = (filterObj) => {
    const defaultData = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    dispatch(GetScrollSubCategoriesRequest({ ...defaultData, ...filterObj }));
  };

  const getDetails = async () => {
    dispatch(GetPostsDetailsRequest({ id }));
  };

  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetPostsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    const status = data?.status;
    return {
      ...data,
      status_rendered: <ColoredText color={status === "published" ? "green" : "red"}>{status}</ColoredText>,
      created_at: <>{formatDate(data?.created_at)}</>,
      updated_at: <>{formatDate(data?.updated_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/community/postlist",
      title: "Community / Posts List",
    },
    id && {
      active: false,
      title: "Post details",
    },
  ];

  
  const filterList = [
    {
      type: "SearchableSelect", 
      placeholder: "Category",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, main_category_id: value?.value };
        });
      },
      options: [
        { id: 1, name: "Land" },
        { id: 2, name: "Air" },
        { id: 3, name: "Water" },
      ],
    },
    {
      type: "SearchableSelect",
      placeholder: "Sub category",
      onChange: (value) => {
        const sub_category_ids = `[${value?.value ?? ""}]`;
        setFilterObj((s) => {
          return { ...s, sub_category_ids };
        });
      },
      onMenuOpen: () => {
        subCategories_list?.length == 0 && geSubCategoriesList({});
      },
      options: subCategories_list?.map((subCat) => {
        return {
          name: subCat.name,
          id: subCat.id,
        };
      }),
      getList: geSubCategoriesList,
      count: subCategories_count,
      applySearchFromRequest: true,
    },
    {
      type: "SearchableSelect", 
      placeholder: "Expert",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, expert_id: value?.value };
        });
      },
      onMenuOpen: () => {
        experts_list?.length == 0 && getUsersList({});
      },
      options: experts_list?.map((expert) => {
        return {
          name: expert.username,
          id: expert.id,
        };
      }),
      getList: getUsersList,
      count: experts_count,
      applySearchFromRequest: true,
    },
    {
      type: "SearchableSelect", 
      placeholder: "Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.label };
        });
      },
      options: [
        { id: 1, name: "draft" },
        { id: 2, name: "published" },
      ],
    },
    {
      type: "date", 
      maxDate: toDate,
      sx: { width: "190px" },
      onChange: (value) => {
        setFromDate(value);
        setFilterObj((s) => {
          return { ...s, "date[from]": value ? dayjs(value).format("DD-MM-YYYY") : null };
        });
      },
    },
    {
      type: "date", 
      minDate: fromDate,
      sx: { width: "190px" },
      onChange: (value) => {
        setToDate(value);
        setFilterObj((s) => {
          return { ...s, "date[to]": value ? dayjs(value).format("DD-MM-YYYY") : null };
        });
      },
    },
  ];
  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}&tab=0`);
        },
        text: "View",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <CommunityTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default PostsListPage;
