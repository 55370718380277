import Types from "./Types";

export const GetPostsSuccess = (payload) => ({
  type: Types.GET_POSTS_SUCCESS,
  payload,
});

export const GetPostDetailSuccess = (payload) => ({
  type: Types.GET_POST_DETAIL_SUCCESS,
  payload,
});

export const GetReportedPostsSuccess = (payload) => ({
  type: Types.GET_REPORTED_POSTS_SUCCESS,
  payload,
});

export const PostsLoading = (payload) => ({
  type: Types.POSTS_LOADING,
  payload,
});
