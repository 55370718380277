/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  users: [],
  user: {},
  categories: [],
  wallet: {},
  journeys: [],
  journey: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function usersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: payload?.data?.users,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_SCROLL_USERS_SUCCESS: {
      return {
        ...state,
        users: payload?.extra?.page_number == 1 ? payload?.data?.users : [...state.users, ...payload?.data?.users],
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_USERS_DETAILS_SUCCESS: {
      return {
        ...state,
        user: payload?.data?.user,
      };
    }

    case Types.UPDATE_USER_STATUS_SUCCESS: {
      const updatedUser = payload?.data?.user;
      return {
        ...state,
        users: state.users.map((user) => (user.id === updatedUser?.id ? updatedUser : user)),
      };
    }

    case Types.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: payload?.data?.main_categories,
      };
    }

    case Types.GET_WALLET_DETAILS_SUCCESS: {
      return {
        ...state,
        wallet: payload?.data?.wallet,
      };
    }

    case Types.GET_JOURNEYS_SUCCESS: {
      return {
        ...state,
        journeys: payload?.data?.reservations,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_JOURNEY_DETAIL_SUCCESS: {
      return {
        ...state,
        journey: payload?.data?.reservation,
      };
    }
    case Types.USERS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    case Types.JOURNEYS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
