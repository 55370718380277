/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  reports: [],
  data: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function EarningReportsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_EARNING_REPORTS_SUCCESS: {
      return {
        ...state,
        data: payload?.data,
        reports: payload?.data?.earning_reports,
        count: payload?.extra?.total_count,
      };
    }

    case Types.EARNING_REPORTS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
