import { makeFilterString } from "../../utils/Helper";
import { GetPostDetailSuccess, GetPostsSuccess, GetReportedPostsSuccess, PostsLoading } from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetPostsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Posts",
    isLoading: PostsLoading,
    successFunction: GetPostsSuccess,
    url: `/admin/posts${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetPostsDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: PostsLoading,
    loadingType: "Post details",
    successFunction: GetPostDetailSuccess,
    url: `/admin/posts/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetReportedPostsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Reported Posts",
    isLoading: PostsLoading,
    successFunction: GetReportedPostsSuccess,
    url: `/admin/posts/reports${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeletePostRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: PostsLoading,
    loadingType: "delete Post",
    action: variables?.action,
    url: `/admin/posts/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
