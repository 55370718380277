/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  requests: [],
  details: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function moneyTransferRequestsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_MONEY_TRANSFER_REQUESTS_SUCCESS: {
      return {
        ...state,
        requests: payload?.data?.money_transfer_requests,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_MONEY_TRANSFER_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state,
        details: payload?.data?.money_transfer_request,
      };
    }
    case Types.FULFILL_MONEY_TRANSFER_SUCCESS: {
      const transferRequest = payload?.data?.money_transfer_request;

      return {
        ...state,
        requests: state?.requests.map((request) => (request?.id == transferRequest?.id ? transferRequest : request)),
      };
    }
    case Types.MONEY_TRANSFERS_REQUESTS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
