import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography,Button } from "@mui/material";
import axios from "axios";
import { icons } from "assets/AssetHelper";
import { resetPasswordSchema } from "common/utils/schemas";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { resetPasswordRequest } from "services/modules/auth";
import { useThemePalette } from "common/hooks/theme_palette";

import FormUse from "components/Atoms/SharedComponents/CRUD/form-use";
import { structure } from "./structure";

import { useFormik } from "formik/dist";
import { useEffect } from "react";

import Cookies from "js-cookie";
import "./style.css";
export default function ResetPasswordForm() {
  const email = Cookies.get("forgetPasswordEmail");
  const {vivideOrange} = useThemePalette();


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.auth);

  const verifyOTP=Cookies.get("verification-token")

  useEffect(()=>{
    if(!verifyOTP)
    {
      navigate('/');
    }
  },[])
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      const action = () => {
        navigate("/reset-password-success");
        Cookies.remove("verification-token");
        Cookies.remove("forgetPasswordEmail");
        delete axios.defaults.headers.common["verification-token"];

      };
      setSubmitting(false);
      const requestData = {
        body: {
          user: {
            email,
            password: values?.password,
            password_confirmation: values?.confirmPassword,
          },
          device: {
            device_type: "android",
            fcm_token: "dummy",
          },
        },
        action,
      };
      axios.defaults.headers.common["verification-token"]=verifyOTP;

      dispatch(resetPasswordRequest(requestData));  
    },
  });
  return (
    <>
      <Loader open={isLoading?.loading} />
      <Stack sx={{ gap: 5 }}>
        <Box
          component="img"
          src={icons.lockIcon}
          sx={{ width: "60px", height: "80px" }}
        />

        <Typography fontSize="32px" fontWeight="400">
          Set new password
        </Typography>

        <FormUse
          formStructure={structure}
          className="reset-password-form"
          formik={formik}
        >

            <Stack sx={{mt:5}}>
              <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    fontSize: "20px",
                    p: 2,
                    backgroundColor: vivideOrange,
                    "&:hover": {
                      backgroundColor: vivideOrange,
                    },
                  }}
                >
                  Reset Password
              </Button>
            </Stack>

        </FormUse>
      </Stack>
    </>
  );
}
