import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import CFormErrors from "./CFormErrors";
import { useThemePalette } from "common/hooks/theme_palette";
import { useNavigate, useSearchParams } from "react-router-dom";

const CRadioBox = ({ label, name, formik, changeAction, options ,readOnly}) => {
  const errorsMsg = formik.errors[name];
  const value = formik.values[name];
  const { textGray, orange } = useThemePalette();
  let [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  console.log(id)

  console.log(readOnly)
  const mainLabelStyle = useMemo(() => {
    return { color: "#797979", fontSize: "18px", fontFamily: "Nunito", mb: 2 };
  }, []);

  const radioStyles = {
    "&.Mui-checked": {
      color: orange,
    },
  };

  return (
    <FormControl>
      <Typography sx={mainLabelStyle} id="demo-controlled-radio-buttons-group">
        {label}
      </Typography>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value?value:null}
        onChange={(event) => changeAction(event?.target?.value)}
        readOnly
      >
        {options?.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option?.value}
              control={
                <Radio
                  sx={radioStyles}
                  disabled={readOnly?(id?readOnly:null):null} // if the user passed readOnly true then that means he want's it to be viewed in the edit page and not be modified
                />
              }
              label={option?.label}
            />
          );
        })}
      </RadioGroup>
      <CFormErrors errorsMsg={errorsMsg} />
    </FormControl>
  );
};

export default CRadioBox;
