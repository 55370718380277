/* eslint-disable array-callback-return */
import { removeDuplicateObjects } from "common/utils/helpers";
import Types from "./Types";

const INIT_STATE = {
  notifications: [],
  count: 0,
  unreadCount: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function NotificationsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_NOTIFICATIONS_SUCCESS: {
      const notifications = [...state?.notifications, ...payload?.data?.notifications];
      const uniqueNotifications = removeDuplicateObjects(notifications, "id");
      const sortedNotifications = uniqueNotifications.sort((a, b) => b?.id - a?.id);

      return {
        ...state,
        notifications: sortedNotifications,
        count: payload?.extra?.total_count,
      };
    }
    case Types.SET_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: payload,
      };
    }
    case Types.SET_NOTIFICATION_IS_SEEN: {
      const clickedNotification = payload?.id;
      const updatedNotifications = state.notifications.map((notification) => {
        if (notification?.id == clickedNotification) {
          return {
            ...notification,
            is_seen: true,
          };
        }
        return notification;
      });
      return {
        ...state,
        notifications: updatedNotifications,
      };
    }
    case Types.NOTIFICATIONS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
