import * as Yup from "yup";
export const initialValues = (data) => {
  return {    
    ...data,
    ...data?.localized_attributes
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.image`,
        kind: "upload",
        label: "Screen image  (EN)",
        width: "47%",

      },
      {
        name: `ar.image`,
        kind: "upload",
        label: "Screen image  (AR)",
        width: "47%",
      },
    ],
  },
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.title`,
        kind: "input",
        type: "text",
        label: "Screen title ( EN )",
        width: "47%",
        placeholder:"Write screen title "
      },
      {
        name: `ar.title`,
        kind: "input",
        type: "text",
        label: "Screen title ( AR )",
        width: "47%",
        placeholder:"Write screen title "

      },
      {
        name: `en.content`,
        kind: "textArea",
        maxCharLength:42,
        type: "text",
        label: "Screen description ( EN ) ",
        width: "47%",
        placeholder:"Write screen title "

      },
      {
        name: `ar.content`,
        kind: "textArea",
        maxCharLength:42,
        type: "text",
        label: "Screen description ( AR ) ",
        width: "47%",
        placeholder:"Write screen title "

      },
      {
        name: `position`,
        kind: "input",
        type: "text",
        label: "Screen position",
        width: "47%",
        placeholder:"Write screen position "

      },

    ],
  },
];

export const validationSchema = () =>
Yup.object().shape({
  en: Yup.object().shape({
    title: Yup.string().required('Title is required'),
    image: Yup.string().required('Image is required'),
    content: Yup.string().required('Content is required'),
  }),
  ar: Yup.object().shape({
    title: Yup.string().required('Title is required'),
    image: Yup.string().required('Image is required'),
    content: Yup.string().required('Content is required'),
  }),
  position: Yup.string().required('Position is required'),
});