import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch, useSelector } from "react-redux";
import { ToggleConfirmationModal } from "services/modules/modal/Actions";

const ConfirmationModal = ({
  open,
  setOpen,
  onConfirm,
  onCancel,
  customClass,
  text,
  confirmBtnTitle = "ok",
  mainColor,
  titleStyle,
  icon,
}) => {
  const dispatch = useDispatch();
  const { red } = useThemePalette();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    py: 4,
    outline: "none",
  };

  const isOpenConfirmationModal = useSelector((state) => state.modal.openConfirationModal);

  const handleClose = () => {
    onCancel && onCancel();
    setOpen ? setOpen(false) : dispatch(ToggleConfirmationModal());
  };

  const handleConfirmation = () => {
    onConfirm && onConfirm();
    setOpen ? setOpen(false) : dispatch(ToggleConfirmationModal());
  };

  return (
    <Modal
      open={open ? open : isOpenConfirmationModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} alignItems="center" justifyContent="center" className={`delete-modal ${customClass}`}>
        <Stack gap={3} alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
          <Typography
            component="h1"
            style={{
              color: red,
              fontWeight: "bold",
              fontSize: "30px",
              textTransform: "capitalize",
              color: mainColor ? mainColor : red,
              ...titleStyle,
            }}
          >
            {text[0]}
          </Typography>
          <Divider sx={{ border: "1px solid #F5F5F5", width: "100%" }} />
          {text[1] && (
            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
              {icon && <Box component="img" src={icon} width="40px" />}
              <Typography
                textAlign="center"
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "400",
                  fontSize: "24px",
                }}
              >
                {text[1]}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack flexDirection="row" alignItems="center" mt={4} className="buttons-wrap" gap={2}>
          <Button
            sx={{
              color: "black",
              border: "1px solid #000000",
              py: 2,
            }}
            className="r-25px"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            sx={{
              backgroundColor: red,
              "&:hover": {
                backgroundColor: mainColor ? mainColor : red,
              },
              color: "white",
              textTransform: "capitalize",
              bgcolor: mainColor ? mainColor : red,
              py: 2,
            }}
            className="primary r-25px"
            onClick={handleConfirmation}
          >
            {confirmBtnTitle}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ConfirmationModal;
