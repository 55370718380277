import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { images } from "assets/AssetHelper";
import { Stack, Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function AuthLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isShowBackToLoginBtn = [
    "/forget-password",
    "/reset-password-success",
  ].includes(pathname);

  return (
    <Stack
      sx={{ p: 2 ,height:'120vh' }}
      direction="row"
      justifyContent="space-between"
    >
      {/* ------------ SIDE IMAGE ------------ */}
      <Box
        component={"img"}
        src={images.loginSideImage}
        style={{ width: "35%", height: "100%" }}
      />

      <Stack sx={{ width: "50%", py: 8 }}>
        {/* LOGO */}
        <Box component={"img"} src={images.Logo} sx={{ width: "100px",height:"100px" }} />
        {/* LOGIN / FORGET PASSWORD FORMS */}
        <Box sx={{ mt: 5, width: "350px", gap: 5 }}>
          <Outlet />
          {/* ------------ BACK TO LOGIN ------------ */}
          {isShowBackToLoginBtn && (
            <Stack
              sx={{
                display: "flex",
                width: "fit-content",
                cursor: "pointer",
                mx: "auto",
                gap: 1,
                mt: 3,
              }}
              direction="row"
              alignItems="center"
              onClick={(_) => navigate("/")}
            >
              <ArrowBackIcon />
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "18px",
                  fontFamily: "Jost",
                }}
              >
                Back To Login
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
