import Cookies from "js-cookie";
import { toast } from "react-toastify";

export default function handleErrors(error) {
  let errorData = error?.response?.data;
  let errorMsg = errorData?.error;
  let errorStatus = error?.response?.status;

  if (errorMsg === "invalid_token" && errorStatus == 401) {
    window.location = "/";
    Cookies.remove("token");
  }

  if (errorData) {
    if (Array.isArray(errorData?.errors)) {
      errorData?.errors?.map((data) => {
        toast.error(data?.message);
        console.log(data?.message);
        throw data?.message;
      });
    }
    if (errorData.error) {
      if (Array.isArray(errorData.error)) {
        toast.error(errorData?.message);
      } else {
        toast.error(errorData?.message);
      }
    } else {
      toast.error(errorData?.message);
      console.log(errorData?.message);
      throw errorData?.message;
    }
  } else {
    // error.message
    toast.error(errorData?.message);
    console.log(errorData?.message);
    throw errorData?.message;
  }
}
