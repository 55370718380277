import { makeFilterString } from "../utils/Helper";
import {
ContactsLoading,GetContactDetailsSuccess,GetContactsSuccess
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetContactsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "get contacts",
    isLoading: ContactsLoading,
    successFunction: GetContactsSuccess,
    url: `/admin/lookups/contacts`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetContactDetailsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "get contact details",
    isLoading: ContactsLoading,
    successFunction: GetContactDetailsSuccess,
    url: `/admin/lookups/contacts/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const UpdateContactRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ContactsLoading,
    loadingType: "update contact",
    url: `/admin/lookups/contacts/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "patch",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



