import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { DeletePostRequest, GetReportedPostsRequest } from "services/modules/community/posts";
import ReportedPostsTemplate from "templates/community/reportedPosts";

import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Box, Stack, Typography } from "@mui/material";
import { formatDate } from "common/utils/helpers";
function ReportedPostsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // dates
  // for some reason dates need to be as it is to be able to do conditions like minDate and maxDate so i will save them in a useState
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  //

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.posts.ReportedPosts);
  const count = useSelector((state) => state.posts.count);

  const load = useSelector((state) => state.posts.isloading);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: page,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetReportedPostsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      //the default behavior of the first column is to navigate to details of row id (here the id is the report id)
      //instead navigate to post id
      id: <span onClick={() => navigate(`/community/postlist?type=details&id=${data?.post?.id}`)}>{data?.id}</span>,
      created_at: <>{formatDate(data?.created_at)}</>,
      delete_field: "Post", //property name is used inside the delete modal
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/community/reportedposts",
      title: "Community / Reported posts",
    },
  ];
  const filterList = [
    {
      type: "date", // this isn't important anyways
      maxDate: toDate,
      sx: { width: "190px" },
      onChange: (value) => {
        setFromDate(value);
        setFilterObj((s) => {
          return { ...s, "date[from]": value ? dayjs(value).format("DD-MM-YYYY") : null };
        });
      },
    },
    {
      type: "date", // this isn't important anyways
      minDate: fromDate,
      sx: { width: "190px" },
      onChange: (value) => {
        setToDate(value);
        setFilterObj((s) => {
          return { ...s, "date[to]": value ? dayjs(value).format("DD-MM-YYYY") : null };
        });
      },
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`/community/postlist?type=details&id=${data?.post?.id}`);
        },
        text: "View post",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  const handleDelete = (id) => {
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      toast.success("Post deleted successfully");
    };
    const requestData = {
      id: selectedRow?.rowData?.post?.id,
      action, //dispatched on success
    };
    dispatch(DeletePostRequest(requestData));
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Loader open={load?.loading && load?.type == "Reported Posts"} />
      <ReportedPostsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default ReportedPostsPage;
