import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import AdventurerDetailsPage from "pages/adventurer/AdventurerDetails";
import React from "react";
import { useSearchParams } from "react-router-dom";

const AdventurerTemplate = ({
  handlePageChange,
  data,
  count,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data, // data
            listTitle: "Adventurer", //title
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              {
                accessor: "fullname",
                Header: "Adventurer name",
                sort: true,
              },
              {
                accessor: "username",
                Header: "Username",
                sort: true,
              },
              {
                accessor: "email",
                Header: "Email",
                sort: true,
              },
              { accessor: "status", Header: "Status", sort: true },
              { accessor: "profile_status", Header: "Profile Status", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search adventure name/username",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            // details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          DetailsComponent={<AdventurerDetailsPage />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default AdventurerTemplate;
