import React from "react";
import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";

const CActions = ({ id, index, data, actionsList }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isActions = actionsList({ id, data }).length !== 0;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {isActions ? (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        "-"
      )}

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
          {actionsList({ id, index, data })?.map((action, index) => {
            return (
              action.condition(index) === true && (
                <MenuItem
                  key={index}
                  onClick={() => {
                    action.click();
                    handleClose();
                  }}
                >
                  {action.text}
                </MenuItem>
              )
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default CActions;
