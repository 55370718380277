import { reduxRequest } from "common/utils/reduxRequest";
import { makeFilterString } from "../../../utils/Helper";
import { GetRequestDetailsSuccess, GetRequestsSuccess, RequestsLoading, UpdateRequestSuccess } from "./Actions";

export const GetRequestsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: RequestsLoading,
    successFunction: GetRequestsSuccess,
    url: `/admin/expert_requests${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetRequestDetailsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "details",
    isLoading: RequestsLoading,
    successFunction: GetRequestDetailsSuccess,
    url: `/admin/expert_requests/${variables?.id}${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const ApproveExpertRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "update",
    isLoading: RequestsLoading,
    successFunction: UpdateRequestSuccess,
    url: `/admin/expert_requests/${variables?.id}`,
    method: "patch",
    action: variables?.action,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const RejectExpertRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "update",
    isLoading: RequestsLoading,
    successFunction: UpdateRequestSuccess,
    url: `/admin/expert_requests/${variables?.id}`,
    method: "delete",
    action: variables?.action,
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
