import Types from "./Types";

export const GetEarningReportsSuccess = (payload) => ({
  type: Types.GET_EARNING_REPORTS_SUCCESS,
  payload,
});

export const EarningReportsLoading = (payload) => ({
  type: Types.EARNING_REPORTS_LOADING,
  payload,
});
