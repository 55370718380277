import { Stack } from "@mui/material";
import CustomTabs from "components/Organisms/tabs/customTabs";
import Header from "components/Atoms/SharedComponents/Details/header";

export default function ExpertDetailsTemplate({ tabs, selectedTab, handleTabChange }) {
  return (
    <Stack>
      <Header title="Expert details" deleteBtn={false} editBtn={false} />
      <CustomTabs tabs={tabs} selectedTab={selectedTab} handleTabChange={handleTabChange} />
    </Stack>
  );
}
