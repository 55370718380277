const lookupTypes = {
  GET_TERMS_AND_CONDTIONS_SUCCESS: "GET_TERMS_AND_CONDTIONS_SUCCESS", //listing is seperate but get/update doc is just by id
  GET_PRIVACY_POLICY_SUCCESS:"GET_PRIVACY_POLICY_SUCCESS",
  GET_CANCELLATION_POLICY_SUCCESS:"GET_CANCELLATION_POLICY_SUCCESS",

  GET_DOCUMENT_DETAIL:"GET_DOCUMENT_DETAIL",
  DOCUMENTS_LOADING: "DOCUMENTS_LOADING",
};

export default lookupTypes;
