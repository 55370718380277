import { Box, Stack, Typography,Paper,Button } from "@mui/material";

const Empty = ({CustomFirstPage})=>{


    return(
        <Stack flexDirection={'column'} gap={2}>
        <Typography fontWeight={500} fontSize={'32px'}>{CustomFirstPage.title}</Typography>
      <Stack
      component={Paper}
      sx={{width:'100%',height:'774px'}}
      alignItems={'center'}
      justifyContent={'center'}
      gap={3}
      >
        <div>
        {CustomFirstPage.icon}
        </div>
        <Typography fontWeight={700} fontSize={'32px'}>{CustomFirstPage.content}</Typography>
          <Button
            variant="contained"
            sx={{
              px: 10,
              py: 2,
              textTransform: "none",
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
              backgroundColor: "#FF7900",
              "&:hover": {
                backgroundColor: "#FF7900",
              },
            }}
            onClick={CustomFirstPage.onClick}
          >
              <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              {CustomFirstPage.add}
            </Typography>
          </Button>
      </Stack>
      </Stack>

    )
}

export default Empty;