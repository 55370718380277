import React, { useEffect, useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { images } from "assets/AssetHelper";
import { detailsData } from "./details";
import { useDispatch, useSelector } from "react-redux";
const OnBoardingTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Screen details",
            data: {
              list: detailsData({ data: details }),
              images: [
                { id: 0, img: details?.localized_attributes?.en?.image, title: "Featured Ad Image (EN)", type: "screen" },
                { id: 1, img: details?.localized_attributes?.ar?.image, title: "Featured Ad Image (AR)", type: "screen" },
              ],
            },
          }}
          table={{
            list: data, // data
            listTitle: "Onboarding", //title
            createBtnTitle: <Typography>Add screen </Typography>, // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              {
                accessor: "title",
                Header: "Screen title ( EN ) ",
                sort: true,
              },
              {
                accessor: "position",
                Header: "Postion",
                sort: true,
              },
              {
                accessor: "content",
                Header: "Screen description ( EN )",
                sort: true,
              },
              { accessor: "created_at", Header: "Created At", sort: true },
              { accessor: "updated_at", Header: "Updated At", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search screen",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
            propertyName: "title", // i added this so i can daynamically change the delete modal label because in diff tables the name of the column changes.
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id ? {} : initialValues(details),
            structure: () => structure(),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit Screen" : "Add Screen",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default OnBoardingTemplate;
