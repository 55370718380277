/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  journeyCancellationReasons: [],
  journeyCancellationReason: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function journeyCancellationReasonsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_JOURNEY_CANCELLATION_REASONS_SUCCESS: {
      return {
        ...state,
        journeyCancellationReasons: payload?.data?.journey_cancellation_reasons,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_JOURNEY_CANCELLATION_REASON_DETAILS_SUCCESS: {
      return {
        ...state,
        journeyCancellationReason: payload?.data?.journey_cancellation_reason,
      };
    }

    case Types.JOURNEY_CANCELLATION_REASONS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
