import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import StarIcon from "@mui/icons-material/Star";
import WalletDetailsModal from "../Models/WalletDetailsModal";
import { useState } from "react";

export default function AvatarCard({ data, userType, style, showWallet = true, showRate = true, isMainParticipant = false }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <WalletDetailsModal open={open} setOpen={setOpen} id={data?.id} />
      <Stack direction="row" gap={2}>
        <Stack>
          <Box
            component="img"
            src={data?.avatar || icons.AnonymousUser}
            sx={{ width: "158px", height: "158px", borderRadius: "50%", ...style?.avatar }}
          />
        </Stack>
        {userType !== "adventurer" && (
          <Stack>
            <Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography sx={{ fontSize: "28px", fontWeight: 600, ...style?.name }}>{data?.fullname}</Typography>
                {isMainParticipant && (
                  <Typography p={0.5} bgcolor="#FFF6EE" color="#FF7900" fontWeight={700} fontSize="14px">
                    Main Participant
                  </Typography>
                )}
              </Stack>
              <Typography sx={{ color: "#919191", fontSize: "20px", ...style?.content }}>@{data?.username}</Typography>
              {showRate && (
                <Stack direction="row" alignItems="center">
                  <StarIcon sx={{ color: "#FEC200", ...style?.content }} />{" "}
                  <Typography sx={{ fontSize: "18px", ...style?.content }}>
                    {Number.parseFloat(data?.avg_rate).toFixed(2)} ({data?.reviews_count})
                  </Typography>
                </Stack>
              )}
            </Stack>
            {showWallet && (
              <Stack>
                <Stack direction="row" alignItems="center" gap={1} onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
                  <Box component="img" src={icons?.Wallet} sx={{ width: "28px", height: "28px" }} />
                  <Typography>Wallet Balance:</Typography>
                  <Typography sx={{ fontSize: "26px", color: "#343434", fontWeight: 700 }}>
                    {data?.wallet?.total_balance} USD
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
}
