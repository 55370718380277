import { makeFilterString } from "../utils/Helper";
import {
  GetMainCategoriesSuccess,
  GetLookUpLoading,
  GetSubCategoriesSuccess,
  GetCountriesSuccess,
  GetCountryDetailsSuccess,
  GetMainCategoriesDetailSuccess,
  GetSubCategoriesDetailsSuccess,
  AddSubCategory,
  GetMultipleQuestionsSuccess,
  GetMultipleQuestionDetailsSuccess,
  AddMultipleQuestionSuccess,
  CreateSubCategoriesSuccess,
  GetFreeTextQuestionsSuccess,
  GetFreeTextQuestionDetailsSuccess,
  AddFreeTextQuestionSuccess,
  GetCitiesDetailSuccess,
  GetCitiesSuccess,
  GetScrollSubCategoriesSuccess,
  AddReportReasonSuccess,
  GetReportReasonsSuccess,
  GetReportReasonDetailsSuccess,
  GetScrollCountriesSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

// Main categories

export const GetMainCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "main categories",
    isLoading: GetLookUpLoading,
    successFunction: GetMainCategoriesSuccess,
    url: `/admin/lookups/main_categories${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetMainCategoryDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "main category details",
    successFunction: GetMainCategoriesDetailSuccess,
    url: `/admin/lookups/main_categories/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateMainCategoryRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "update main category",
    url: `/admin/lookups/main_categories/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

// SUB CATEGORIES

export const GetSubCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "sub categories",
    isLoading: GetLookUpLoading,
    successFunction: GetSubCategoriesSuccess,
    url: `/admin/lookups/sub_categories${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetScrollSubCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: GetLookUpLoading,
    successFunction: GetScrollSubCategoriesSuccess,
    url: `/admin/lookups/sub_categories${makeFilterString(variables)}`,
    method: "get",
    variables: variables,
  };

  return async (dispatch) => {
    await reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createSubCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    successFunction: CreateSubCategoriesSuccess,
    loadingType: "add",
    action: variables?.action,
    url: `/admin/lookups/sub_categories`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateSubCategoryRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "update main category",
    url: `/admin/lookups/sub_categories/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetCountriesRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Countries",
    isLoading: GetLookUpLoading,
    successFunction: GetCountriesSuccess,
    url: `/admin/lookups/countries${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateCountryRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "update country",
    url: `/admin/lookups/countries/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollCountriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "list",
    successFunction: GetScrollCountriesSuccess,
    url: `/admin/lookups/countries${makeFilterString(variables)}`,
    method: "get",
    variables: variables,
  };

  return async (dispatch) => {
    await reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetCountryDetailsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "country detail",
    isLoading: GetLookUpLoading,
    successFunction: GetCountryDetailsSuccess,
    url: `/admin/lookups/countries/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteCountryRequest = ({ ...variables }) => {
  const requestData = {
    // loadingType: "country detail",
    isLoading: GetLookUpLoading,
    action: variables?.action,
    url: `/admin/lookups/countries/${variables?.id}`,
    method: "DELETE",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetSubCategoriesDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    successFunction: GetSubCategoriesDetailsSuccess,
    loadingType: "get sub category details",
    url: `admin/lookups/sub_categories/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const AddSubCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    successFunction: AddSubCategory,
    loadingType: "Add subcategory",
    url: `/admin/lookups/sub_categories`,
    action: variables?.action,
    data: variables?.body,
    method: "POST",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteSubCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "delete sub category",
    action: variables?.action,
    url: `/admin/lookups/sub_categories/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

// multiple questions
export const GetMultipleQuestionsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "multiple questions",
    isLoading: GetLookUpLoading,
    successFunction: GetMultipleQuestionsSuccess,
    url: `/admin/lookups/expert_request_questions?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetMultipleQuestionDetailRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "multipleQuestionDetail",
    successFunction: GetMultipleQuestionDetailsSuccess,
    url: `/admin/lookups/expert_request_questions/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateMultipleQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "update freetext question",
    url: `/admin/lookups/expert_request_questions/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const AddMultipleQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    successFunction: AddMultipleQuestionSuccess,
    loadingType: "add multiple",
    action: variables?.action,
    url: `/admin/lookups/expert_request_questions`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteMultipleQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "delete multiple question",
    action: variables?.action,
    url: `/admin/lookups/expert_request_questions/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

// Expert

export const GetFreeTextQuestionsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "freetext questions",
    isLoading: GetLookUpLoading,
    successFunction: GetFreeTextQuestionsSuccess,
    url: `/admin/lookups/expert_request_general_questions?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetFreeTextQuestionDetailRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "FreeTextQuestionDetail",
    successFunction: GetFreeTextQuestionDetailsSuccess,
    url: `admin/lookups/expert_request_general_questions/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateFreeTextQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "update freetext question",
    url: `/admin/lookups/expert_request_general_questions/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const AddFreeTextQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    successFunction: AddFreeTextQuestionSuccess,
    loadingType: "add freetext",
    action: variables?.action,
    url: `/admin/lookups/expert_request_general_questions`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteFreeTextQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "delete freetext question",
    action: variables?.action,
    url: `/admin/lookups/expert_request_general_questions/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetCitiesRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Cities",
    isLoading: GetLookUpLoading,
    successFunction: GetCitiesSuccess,
    url: `/admin/lookups/cities${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetCityDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "city details",
    successFunction: GetCitiesDetailSuccess,
    url: `/admin/lookups/cities/${variables?.id}?include=country`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "update city",
    url: `/admin/lookups/cities/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "delete city",
    action: variables?.action,
    url: `/admin/lookups/cities/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetReportReasonsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "report reasons",
    isLoading: GetLookUpLoading,
    successFunction: GetReportReasonsSuccess,
    url: `/admin/lookups/report_reasons${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetReportReasonDetailRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "report reasons details",
    successFunction: GetReportReasonDetailsSuccess,
    url: `/admin/lookups/report_reasons/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateReportReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "update report reasons",
    url: `/admin/lookups/report_reasons/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const AddReportReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    successFunction: AddReportReasonSuccess,
    loadingType: "add report reasons",
    action: variables?.action,
    url: `/admin/lookups/report_reasons`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteReportReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetLookUpLoading,
    loadingType: "delete report reasons",
    action: variables?.action,
    url: `/admin/lookups/report_reasons/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
