/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  chats: [],
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function ReportedChatsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_REPORTED_CHATS_SUCCESS: {
      return {
        ...state,
        chats: payload?.data?.chat_room_reports,
        count: payload?.extra?.total_count,
      };
    }

    case Types.REPORTED_CHATS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
