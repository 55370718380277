import * as Yup from "yup";
export const initialValues = (data) => {};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `email`,
        kind: "input",
        type: "text",
        label: "Email",
        width: "100%",
        placeholder: "Enter your email",
      }
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    email: Yup.string().required("required"),
  });
