import * as Yup from "yup";
export const initialValues = (data) => {};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `email`,
        kind: "input",
        type: "text",
        label: "Email",
        width: "100%",
        placeholder: "Enter your email",
      },
      {
        name: `password`,
        kind: "input",
        type: "password",
        label: "Password",
        width: "100%",
        placeholder: "***********",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().label("Password"),
});