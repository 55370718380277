import React from "react";

import { LinearProgress, Rating, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export default function ReviewsDetails({ details }) {
  const { avg_rate, total_reviews } = details;
  const reviewsAnlytics = [5, 4, 3, 2, 1];

  return (
    <Stack sx={{ width: "40%" }}>
      <Stack sx={{}} alignItems="center" gap={2}>
        <Typography sx={{ fontSize: "32px", fontWeight: 700 }}>{Number(avg_rate || 0).toFixed(1)} /5</Typography>
        <Rating
          name="read-only"
          value={avg_rate}
          readOnly
          sx={{
            fontSize: "45px",
          }}
        />
        <Typography sx={{ fontSize: "18px" }}>Number of reviews</Typography>
        <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>({total_reviews})</Typography>
      </Stack>
      <Stack gap={1}>
        {reviewsAnlytics.map((rate, i) => (
          <Stack direction="row" alignItems="center" key={i} gap={1} justifyContent="center">
            <Typography sx={{ fontSize: "22px" }}>{rate}</Typography>
            <StarIcon sx={{ color: "#FEC200" }} fontSize="large" />
            <Stack sx={{ width: "75%" }}>
              <LinearProgress
                variant="determinate"
                value={(details[rate] / total_reviews) * 100 || 0}
                sx={{
                  width: "100%",
                  height: "10px",
                  borderRadius: "15px",
                  backgroundColor: "#F5F5F5",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 5,
                    backgroundColor: "#FEC200",
                  },
                }}
              />
            </Stack>
            <Typography sx={{ fontSize: "22px" }}>({details[rate]})</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
