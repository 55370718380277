import { Typography } from "@mui/material";

export default function MapDirectionsButton({ lat, long }) {
  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`;
    window.open(url, "_blank");
  };

  return (
    <Typography
      sx={{ textDecoration: "underline", fontSize: "18px", cursor: "pointer", width: "fit-content" }}
      onClick={openGoogleMaps}
    >
      Get Directions
    </Typography>
  );
}
