import { Stack } from "@mui/material";
import Header from "components/Atoms/SharedComponents/Details/header";
import Status from "components/Atoms/SharedComponents/Text/Status";
import JourneyDetailsTemplate from "templates/journey-details/JourneyDetails";

export default function JourneyDetailsPage({ details, title }) {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Header title={title} deleteBtn={false} editBtn={false} />
        <Status
          status={details?.compounded_status}
          cancellation={{ text: details?.cancellation_reason_text, reason: details?.cancellation_reason }}
        />
      </Stack>
      <JourneyDetailsTemplate details={details} />
    </>
  );
}
