import {  Stack } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import { useThemePalette } from "common/hooks/theme_palette";


const CInput = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  disabled,//to dim if needed
  ...rest
}) => {
  const {  orange } = useThemePalette();

  const multinames = name?.split(".")?.map((data) => data);
  const value =
    multinames?.length === 2
      ? formik?.values?.[multinames[0]]?.[multinames[1]]
        ? formik?.values?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik?.values[name];
  const errorsMsg =
    multinames?.length === 2
      ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik.errors[name];

  // const textfield = {
  //   position: "absolute",
  //   top: "-12px",
  //   fontSize: "13px",
  //   margin: "0 20px",
  //   background: "#fff",
  //   zIndex: "99",
  //   padding: "0 10px",
  //   color: "black",
  // };

  return (
    <Stack mb="5" position="relative">
      <TextFieldInput
        id={name}
        type={type}
        label={label}
        labelStyle={{
          color: "#797979",
          fontSize: "18px",
          mb: 2,
        }}
        placeholder={placeholder}
        sx={{
          background:'#FBFBFB',
          "& label.Mui-focused": {
            color: "white",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: orange,
            },
          },
        }}
        onChange={formik?.handleChange}
        value={value || ""}
        onBlur={formik.handleBlur}
        name={name}
        disabled={disabled}
        {...rest}
      />
      
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CInput;
