import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import { detailsData } from "templates/adventurer/details";

export default function Details({ details }) {
  return (
    <DetailsUse
      data={{
        deleteBtn: false,
        editBtn: false,
        data: {
          list: detailsData({ data: details }),
        },
      }}
    />
  );
}
