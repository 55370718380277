import Types from "./Types";

export const GetRequestsSuccess = (payload) => ({
  type: Types.GET_REQUESTS_SUCCESS,
  payload,
});
export const GetRequestDetailsSuccess = (payload) => ({
  type: Types.GET_REQUEST_DETAILS_SUCCESS,
  payload,
});

export const UpdateRequestSuccess = (payload) => ({
  type: Types.UPDATE_REQUEST_SUCCESS,
  payload,
});


export const RequestsLoading = (payload) => ({
  type: Types.REQUESTS_LOADING,
  payload,
});
