import Types from "./Types";

export const GetSystemNotificationsSuccess = (payload) => ({
  type: Types.GET_SYSTEM_NOTIFICATIONS_SUCCESS,
  payload,
});

export const CreateSystemNotificationSuccess = (payload) => ({
  type: Types.CREATE_SYSTEM_NOTIFICATION_SUCCESS,
  payload,
});

export const SystemNotificationsLoading = (payload) => ({
  type: Types.SYSTEM_NOTIFICATIONS_LOADING,
  payload,
});
