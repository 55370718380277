import React, { useEffect, useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { images } from "assets/AssetHelper";
import { useDispatch, useSelector } from "react-redux";
import { detailsData } from "./details";
const SubCategoriesTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Sub category details",
            data: {
              list: detailsData({ data: details }),
              images: [{ id: 0, img: details?.image, title: "Sub category photo" }],
            },
          }}
          table={{
            list: data, // data
            listTitle: "Sub categories", //title
            createBtnTitle: <Typography fontSize={"15px"}>Add sub category </Typography>, // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              {
                accessor: "image",
                Header: (
                  <Typography fontSize={"13px"} fontWeight={500}>
                    Sub Category image
                  </Typography>
                ),
              },
              {
                accessor: "localized_attributes.en.name",
                Header: (
                  <Typography fontSize={"13px"} fontWeight={500}>
                    Sub Category name ( EN )
                  </Typography>
                ),
                sort: true,
              },
              {
                accessor: "localized_attributes.ar.name",
                Header: (
                  <Typography fontSize={"13px"} fontWeight={500}>
                    Sub Category name ( AR )
                  </Typography>
                ),
                sort: true,
              },
              {
                accessor: "main_category.name",
                Header: (
                  <Typography fontSize={"13px"} fontWeight={500}>
                    Main Category
                  </Typography>
                ),
                sort: true,
              },
              {
                accessor: "created_at",
                Header: (
                  <Typography fontSize={"13px"} fontWeight={500}>
                    Created At
                  </Typography>
                ),
                sort: true,
              },
              {
                accessor: "updated_at",
                Header: (
                  <Typography fontSize={"13px"} fontWeight={500}>
                    Updated At
                  </Typography>
                ),
                sort: true,
              },
              {
                accessor: "Actions",
                Header: (
                  <Typography fontSize={"13px"} fontWeight={500}>
                    Actions
                  </Typography>
                ),
              },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search sub-categories",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
            propertyName: "delete_field",
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit sub category" : "Add sub category",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default SubCategoriesTemplate;
