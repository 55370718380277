/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  Equipment: [],
  Equipment_detail: {},
  Experts:[],
  count: 0,
  isloading: {loading:null,loadingType:""},
  error: null,
};

export default function EquipmentReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        Equipment: payload?.data?.equipment,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_EQUIPMENT_DETAIL_SUCCESS: {
      return {
        ...state,
        Equipment_detail: payload?.data.equipment
      };
    }

    case Types.GET_EXPERTS: {
      return {
        ...state,
        Experts: payload?.data?.users
      };
    }

    case Types.EQUIPMENT_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
