import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import setAuthToken from "services/modules/utils/handel_api";
import CloseIcon from "@mui/icons-material/Close";

function CMultiUpload({ type, id, label, name, formik, changeAction, text }) {
  const [fileUrl, setFileUrl] = useState([]);
  const { orange, textGray } = useThemePalette();
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);
  console.log(formik);
  const dispatch = useDispatch();

  const handleClose = (index) => {
    let filterdList = fileUrl?.filter((val, idx) => idx != index);
    setFileUrl(filterdList);
    changeAction(filterdList);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      uploadFile(files[0], type);
    }
  };

  const uploadFile = async (file, type) => {
    const imageExe = ["png", "jpg", "jpeg"];
    const extension = file?.type.slice(file?.type.indexOf("/") + 1);
    try {
      if (file.size > 10 * 1024 * 1024) {
        setError("file size must be less than 10MB");
        return;
      }
      if (!imageExe?.includes(extension)) {
        setError("file must be png , jpg or jpeg");
        return;
      }
      setError(null);

      const requestData = {
        body: {
          files: [
            {
              extension: extension,
            },
          ],
        },
        file: file,
        updateData: (data) => {
          changeAction([...fileUrl, data]);
          setFileUrl((prevData) => [...prevData, data]);
        },
      };
      dispatch(getPresignedUrlRequest({ ...requestData }));
    } catch (error) {
      console.error("Error in uploadFile:", error);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file, type);
    }
  };
  console.log(fileUrl);
  return (
    <Grid container gap={2}>
      <>
        <Grid
          item
          xs={3}
          height="auto"
          sx={{
            border: " 1px dashed rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            height: "100%",
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <label htmlFor={`multiUpload-${id}`}>
            <input
              id={`multiUpload-${id}`}
              name={`multiUpload-${id}`}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
            <Stack alignItems="center" rowGap={"24px"} py="2rem">
              <img src={icons?.uploadIcon} alt="upload icon" />
              <Typography fontSize="13px" textAlign="center">
                Select a file or drag and drop here
              </Typography>
              {text && (
                <Typography fontSize="12px" color={textGray}>
                  {text}
                </Typography>
              )}
              <Button
                component="span"
                sx={{
                  color: orange,
                  border: `1px solid ${orange}`,
                  fontSize: "15px",
                  px: "16px",
                }}
              >
                Select File
              </Button>
            </Stack>
          </label>
          <Typography color="red" mt="8px" fontSize="14px">
            {error}
          </Typography>
        </Grid>
      </>
      {fileUrl?.length > 0 &&
        fileUrl?.map((data, index) => {
          return (
            <Grid
              key={index}
              item
              xs={3}
              sx={{
                border: " 1px dashed rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <Box
                component="img"
                src={data}
                alt="img"
                width="100%"
                height={"100%"}
                sx={{
                  borderRadius: "10px",
                }}
              />
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "48px",
                  height: "48px",
                  bgcolor: "white",
                  borderRadius: "50%",
                  filter: "drop-shadow(0px 0px 4px rgba(185, 185, 185, 0.25))",
                  transform: "translate(50%, -30%)",
                  cursor: "pointer",
                }}
                onClick={() => handleClose(index)}
              >
                <CloseIcon />
              </Stack>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default CMultiUpload;
