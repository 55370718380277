import { Typography } from "@mui/material";

export default function ColoredText({ children, color, sx }) {
  const colorPallete = {
    red: { bgcolor: "#FFECEB", color: "#FF3B30" },
    green: { bgcolor: "#EFFBF2", color: "#34C759" },
    yellow: { bgcolor: "#FCFFE9", color: "#CCA300" },
    blue: { bgcolor: "#E5FEFF", color: "#00A0A6" },
  };

  return (
    <Typography
      sx={{
        minWidth: "fit-content",
        p: 1,
        textAlign: "center",
        ...colorPallete[color],
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}
