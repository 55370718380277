import { makeFilterString } from "../../../utils/Helper";
import { GetReportsSuccess, ReportsLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const GetReportsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: ReportsLoading,
    successFunction: GetReportsSuccess,
    url: `/admin/users/user/reports${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
