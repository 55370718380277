import { Stack } from "@mui/material";
import PostDetailsHeader from "components/Molecules/Community/PostDetailsHeader";
import CustomTabs from "components/Organisms/tabs/customTabs";

export default function PostDetailsTemplate({ details, tabs, selectedTab, handleTabChange }) {
  return (
    <Stack>
      <PostDetailsHeader data={details} />
      <CustomTabs tabs={tabs} selectedTab={selectedTab} handleTabChange={handleTabChange} />
    </Stack>
  );
}
