import Types from "./Types";

export const GetCommentsSuccess = (payload) => ({
  type: Types.GET_COMMENTS_SUCCESS,
  payload,
});

export const GetCommentDetailSuccess = (payload) => ({
  type: Types.GET_COMMENT_DETAIL_SUCCESS,
  payload,
});

export const UpdateCommentSuccess = (_,payload) => ({
  type: Types.UPDATE_COMMENT_SUCCESS,
  payload,
});



export const CommentsLoading = (payload) => ({
  type: Types.COMMENTS_LOADING,
  payload,
});
