import { Divider, Modal, Stack, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetWalletDetailsRequest } from "services/modules/users/users";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { icons } from "assets/AssetHelper";
import dayjs from "dayjs";
import { useThemePalette } from "common/hooks/theme_palette";
import { formatDate } from "common/utils/helpers";

const WalletDetailsModal = ({ open, setOpen, id }) => {
  const { gray5 } = useThemePalette();
  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    height: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    pb: 4,
    gap: 5,
    outline: "none",
  };

  const handleClose = () => {
    setOpen(false);
  };

  const wallet = useSelector((state) => state.users.wallet);
  const isTransactions = wallet?.wallet_transactions?.length >= 1;
  const loading = useSelector((state) => state.users.isloading);

  useEffect(() => {
    const requestData = {
      params: {
        expert_id: id,
      },
    };
    open && dispatch(GetWalletDetailsRequest(requestData));
  }, [open]);
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Stack sx={style}>
        {loading?.loading ? (
          <Stack sx={{ height: "300px" }}>
            <Loader open={true} />
          </Stack>
        ) : (
          <>
            <Stack
              gap={3}
              alignItems="center"
              direction="row"
              sx={{ p: 4, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)" }}
              justifyContent="space-between"
            >
              <Typography
                component="h1"
                style={{
                  fontWeight: 500,
                  fontSize: "32px",
                  textTransform: "capitalize",
                }}
              >
                Wallet Balance
              </Typography>
              <CloseIcon onClick={handleClose} fontSize="large" sx={{ cursor: "pointer" }} />
            </Stack>
            <Stack direction="row" justifyContent="space-around">
              <Stack sx={{ border: "1px solid gray", p: 3, width: "244px", borderRadius: "11px" }} alignItems="center">
                <Typography sx={{ fontSize: "20px" }}>Total balance </Typography>
                <Typography sx={{ fontSize: "26px", fontWeight: 700 }}>USD {wallet?.total_balance || 0}</Typography>
              </Stack>
              <Stack sx={{ border: "1px solid gray", p: 3, width: "244px", borderRadius: "11px" }} alignItems="center">
                <Typography sx={{ fontSize: "20px" }}>Redeemable balance </Typography>
                <Typography sx={{ fontSize: "26px", fontWeight: 700 }}>USD {wallet?.redeemable_balance || 0}</Typography>
              </Stack>
            </Stack>
            <Divider sx={{ border: "1px solid #F5F5F5" }} />
            <Stack sx={{ px: 3, gap: 2, overflowY: "auto" }}>
              <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Balance Details </Typography>
              {!isTransactions && <Typography sx={{ mx: "auto", fontSize: "28px", color: "gray" }}>No transactions</Typography>}
              <Stack sx={{}} gap={2}>
                {wallet?.wallet_transactions?.map((transaction) => (
                  <Stack sx={{}} direction="row" key={transaction.id} gap={2} alignItems="start" justifyContent="space-between">
                    <Stack gap={2} direction="row" alignItems="start">
                      <Box
                        component="img"
                        sx={{ width: "48px", height: "48px" }}
                        src={transaction?.amount < 0 ? icons.Decrease : icons.Increase}
                      />
                      <Stack gap={1}>
                        <Typography sx={{ fontSize: "19px" }}>{transaction.reason}</Typography>
                        <Typography
                          sx={{
                            fontSize: "21px",
                            fontWeight: 700,
                            color: transaction?.amount < 0 ? "#FF6961" : "#30DB5B",
                          }}
                        >
                          {transaction?.amount < 0 ? "-" : "+"} USD{" "}
                          {transaction?.amount < 0 ? transaction?.amount * -1 : transaction?.amount}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack gap={1} alignItems="end">
                      <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>USD {transaction?.amount_before}</Typography>
                      <Typography sx={{ fontSize: "16px", fontWeight: 400, color: gray5 }}>
                        {formatDate(transaction?.created_at, "DD/MM/YYYY")}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default WalletDetailsModal;
