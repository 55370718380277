import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";

const CommentDetailsModal = ({
  t,
  title,
  closeTitle,
  actionTitle,
  actionColor,
  open,
  setOpen,
  content,
  handleCloseAction,
  handleAction,
  isLoading,
}) => {
  const { red } = useThemePalette();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    minHeight: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    py: 4,
  };
  const handleClose = () => {
    handleCloseAction && handleCloseAction("keep-hidden");
    setOpen(false);
  };

  const handleAccept = () => {
    handleAction && handleAction("unhide");
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} alignItems="center" justifyContent="center">
        {isLoading ? (
          <Loader open={isLoading} />
        ) : (
          <>
            <Stack gap={3} sx={{ width: "100%" }}>
              <Typography component="h1" style={{ color: "#2C2C2E", fontWeight: "bold", fontSize: "32px", marginLeft: "30px" }}>
                {title}
              </Typography>
              <Divider sx={{ border: "1px solid #F5F5F5", width: "100%" }} />
              <Stack height={"150px"} sx={{ marginLeft: "30px", marginRight: "30px", overflowY: "auto" }}>
                {content}
              </Stack>
            </Stack>
            <Stack flexDirection="row" alignItems="center" mt={4} className="buttons-wrap" gap={2}>
              <Button
                sx={{
                  color: "black",
                  border: "1px solid #000000",
                }}
                className="r-25px"
                onClick={handleClose}
              >
                {closeTitle}
              </Button>

              <Button
                sx={{
                  backgroundColor: actionColor,
                  "&:hover": {
                    backgroundColor: actionColor,
                  },
                  color: "white",
                }}
                className="primary r-25px"
                onClick={handleAccept}
              >
                {actionTitle}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default CommentDetailsModal;
