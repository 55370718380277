import CCrud from "components/Molecules/Crud";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import { detailsData } from "./details";
import DetailsStatus from "components/Molecules/DetailsStatus/DetailsStatus";

const VerificationRequestsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Verification request details",
            headerChildren: <DetailsStatus details={details} actionsList={actionsList} isVerificationRequest={true} />,
            data: {
              list: detailsData({ data: details }),
            },
            deleteBtn: false,
            editBtn: false,
          }}
          table={{
            list: data,
            listTitle: "Verification requests",
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              { accessor: "user.fullname", Header: "Expert name", sort: true },
              { accessor: "user.username", Header: "Username", sort: true },
              { accessor: "created_at", Header: "Created Date", sort: true },
              { accessor: "status", Header: "Approval status", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search expert name/username",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList,
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default VerificationRequestsTemplate;
