import React, { useEffect, useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { images } from "assets/AssetHelper";
import { detailsData } from "./details";
const TermsAndConditionsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: `${id == "3" ? "Adventurer" : "Expert"} Terms & Conditions details`,
            deleteBtn: null, //no delete
            data: {
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data, // data
            listTitle: "Terms & Conditions", //title
            columns: [
              // { accessor: "id", Header: "#" },
              {
                accessor: "for",
                Header: "User",
                sort: true,
                clickable: true,
              },
              { accessor: "updated_at", Header: "Last Updated", sort: true }, //misisng in BE
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search expert name/username",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !details?.content ? {} : initialValues(details),
            structure: () => structure(),
            validationSchema: validationSchema,
            title: details?.content
              ? `Edit ${id == "3" ? "Adventurer" : "Expert"} Terms & Conditions `
              : `Add ${id == "3" ? "Adventurer" : "Expert"}Terms & Conditions `,
            submitTitle: details?.content ? "Update" : "Add", // if it's not here yet it will be an add (but it's really the same form)
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default TermsAndConditionsTemplate;
