import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "Nunito",
  },
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#F25500",
    },
    error: {
      main: red.A400,
    },
  },
  theme_palette: {
    orange: "#F25500",
    red: "#D92C32",
    oliveGreen: "#3FABAE",
    gray: "#939393",
    gray5: "#8E8E93",
    grayBorder: "#E7E7E7",
    textGray: "#1A1A1A",
    lightGray: "#ddd",
    backgroundGray: "#F2F2F2",
    labelColor: "#9F9F9F",
    borderColor: "#F2F2F2",
    offWhite: "#FAFAFA",
    vivideOrange: "#FF7900",
    red: "#D20000",
    green: "#29B95F",
  },
  overrides: {
    MuiPaper: {
      root: {},
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application !
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          // Some CSS
          borderRadius: "4px",
          "&:hover": {
            //  backgroundColor: 'primary',
          },
          padding: "8px 97px",
          // width:"250px",
          fontFamily: "Nunito",
          textTransform: "none",
          fontSize: "18px",
          fontWeight: "400",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
          padding: "15px",
        },
      },
    },
  },
});

export default theme;
