import { formatDate } from "common/utils/helpers";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetReportedChatsRequest, DeleteReportedChatRequest } from "services/modules/journeys/reportedChats";
import ReportedChatsTemplate from "templates/reportedChats";

function ReportedChatsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.ReportedChats.chats);
  const count = useSelector((state) => state.ReportedChats.count);
  const load = useSelector((state) => state.ReportedChats.isloading);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetReportedChatsRequest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      id: <span onClick={() => navigate(`/journeys?type=details&id=${data?.chat_room?.journey_id}`)}>{data?.id}</span>,
      created_at: <>{formatDate(data?.created_at)}</>,
      delete_field: "Journey chat", //property name is used inside the delete modal
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/experts/reported",
      title: "Journeys/Reported journey chat",
    },
    type && {
      active: false,
      title: type === "details" && "Details",
    },
  ];

  const filterList = [
    {
      type: "date",
      onChange: (value) => {
        const created_at = value ? dayjs(value).format("YYYY-MM-DD") : "";
        setFilterObj((s) => {
          return { ...s, created_at };
        });
      },
    },
  ];

  const handleDelete = () => {
    const id = selectedRow?.rowData?.chat_room?.id;
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      toast.success("Chat deleted successfully");
    };
    const requestData = {
      id,
      action,
    };
    dispatch(DeleteReportedChatRequest(requestData));
    setOpenDeleteModal(false);
  };

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`/journeys?type=details&id=${data?.chat_room?.journey_id}`);
        },
        text: "View Journey",
      },
    ];
  };

  const headerChildren = <></>;

  const modalComponents = [];

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <ReportedChatsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default ReportedChatsPage;
