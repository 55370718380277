import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { formatDate } from "common/utils/helpers";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { SetNotificationIsSeen } from "services/modules/notifications/notifications/Actions";

export default function Notification({ notification, notificationTypes, handleClose }) {
  const { orange } = useThemePalette();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const type = notificationTypes(notification?.data_id)?.[notification?.notification_type];
  const icon = type?.icon;

  const handleClick = (id) => {
    notification?.need_action && navigate(type.path);
    dispatch(SetNotificationIsSeen({ id }));
    handleClose();
  };

  return (
    <MenuItem
      sx={{
        whiteSpace: "unset",
        wordBreak: "break-word",
        width: "450px",
        p: 2,
      }}
      onClick={() => handleClick(notification?.id)}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
        <Stack direction="row" gap={2} alignItems="start">
          <Box component="img" src={icon} width="48px" />
          <Stack>
            <Typography>{notification?.title}</Typography>
            <Typography sx={{ color: "#B4B4B4" }}>{formatDate(notification?.sent_at, "DD-MM-YYYY, hh:mm a")}</Typography>
          </Stack>
        </Stack>
        {!notification?.is_seen && (
          <Stack>
            <FiberManualRecordIcon sx={{ color: orange }} fontSize="15px" />
          </Stack>
        )}
      </Stack>
    </MenuItem>
  );
}
