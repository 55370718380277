import { Stack, Typography, Box } from "@mui/material";

export default function TextWithIcon({ icon, label, text, sx }) {
  return (
    <Stack direction="row" alignItems="center" gap={1} sx={{ width: "45%" }}>
      <Box component="img" src={icon} width="24px" />
      <Typography sx={{ color: "#7D7D7D" }}>{label}</Typography>
      <Typography sx={{ fontWeight: 600 }}>{text}</Typography>
    </Stack>
  );
}
