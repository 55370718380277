import { Box, Tabs, Tab } from "@mui/material";

export default function TabsTitles({ handleChange, value, tabs, tabstyle, selectedTabStyle }) {
  return (
    <Box
      sx={{
        ".MuiTab-root": {
          color: "#BDBDBD",
          textTransform: "capitalize",
          fontSize: "24px",
          px: 5,
          fontWeight: "400",
        },
        ".Mui-selected": {
          backgroundColor: "white",
          textDecoration: "capitalize",
          color: "black !important",
          ...selectedTabStyle,
        },
      }}
    >
      <Tabs
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{ m: 0 }}
      >
        {tabs.map((tab, i) => (
          <Tab label={tab?.title} key={i} disableRipple sx={{ px: "15px !important", ...tabstyle }} />
        ))}
      </Tabs>
    </Box>
  );
}
