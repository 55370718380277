import { useEffect } from "react";
import { Box, Stack, Typography ,Button} from "@mui/material";
import { icons } from "assets/AssetHelper";
import FormIndex from "components/Atoms/SharedComponents/Forms/main";
import FormHead from "components/Atoms/SharedComponents/Forms/main/FormHead/FormHead";
import CInput from "components/Atoms/SharedComponents/Forms/main/components/CInput";
import CMultiInput from "components/Atoms/SharedComponents/Forms/main/components/CMultiInput";
export default function MultipleChoicesForm({head,width,className,data,onChange,handleAddOption,handleDeleteOption}) {
    const renderInputs = (data, index) => {
        const generalProps = {
          data:data,
          onChange,
          label:"Add option",
          handleDeleteOption:handleDeleteOption,
        //   formik: formik,
        //   colSpan: data?.colSpan,
          index: index,
        };
        return <CMultiInput {...generalProps} />;
      };
    
  return (
    <Box
    className={className}
    width={width || "100%"}
    bgcolor="white"
    p={3}
  >
    <Stack flexDirection={'row'}alignItems={'center'} justifyContent={'space-between'}>
        <FormHead text={head} />
        <Button variant="text" sx={{color:"#F25500",fontWeight:'500',fontSize:'16px'}} onClick={handleAddOption}>+ Add another option </Button>
    </Stack>
    <Stack gap={3} width="100%" flexWrap="wrap" flexDirection="row">
      {data?.map((val, index) => {
        // if (val)
          return (
            <Box
              width={val.width || "100%"}
              key={index}
              sx={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              {renderInputs(val, index)}
            </Box>
          );
      })}
    </Stack>
  </Box>    
  );
}
