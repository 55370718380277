import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    question:data?.question,
    description_header:data?.description_header,
    description:data?.description,
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `question`,
        kind: "input",
        type: "text",
        label: "Question",
        width: "47%",
        placeholder:"Write question here"
      },
      {
        name: `description_header`,
        kind: "input",
        type: "text",
        label: "Add Question tip Title",
        width: "47%",
        placeholder:"Write question tip Title"
      },

      {
        name: `description`,
        kind: "textArea",
        type: "textarea",
        colSpan:4,
        maxCharLength:200,
        label: "Add Question tip description",
        width: "47%",
        placeholder:"Write  question tip descriptions (EN)"
        },

    ],
  },

];

export const validationSchema = () =>
  Yup.object({
    question: Yup.string().required("required"),
    description: Yup.string().required("required"),
    description_header: Yup.string().required("required")
  });
