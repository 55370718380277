import Types from "./Types";

export const GetReviewsSuccess = (payload) => ({
  type: Types.GET_REVIEWS_SUCCESS,
  payload,
});
export const GetReviewsDetailsSuccess = (payload) => ({
  type: Types.GET_REVIEWS_DETAILS_SUCCESS,
  payload,
});

export const ReviewsLoading = (payload) => ({
  type: Types.REVIEWS_LOADING,
  payload,
});
