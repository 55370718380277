import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `key`,
        kind: "input",
        type: "text",
        label: "Key",
        disabled:true,
        width: "47%",
        placeholder:"key"
      },
      {
        name: `value`,
        kind: "input",
        type: "text",
        label: "Value",
        width: "47%",
        placeholder:"Write value"

      }
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    key: Yup.string().required("required"),
    value: Yup.string().required("required"),
  });