import { Stack, Typography } from "@mui/material";
import Header from "components/Atoms/SharedComponents/Details/header";

export default function PostDetailsHeader({ data }) {
  const status = data?.status;
  const headerChildren = (
    <Stack direction="row" gap={1}>
      Status:
      <Typography sx={{ color: status === "published" ? "#34C759" : "#FFCC00", textTransform: "capitalize", fontWeight: 700 }}>
        {status}
      </Typography>
    </Stack>
  );

  return <Header title={"Post details"} children={headerChildren} deleteBtn={false} editBtn={false} />;
}
