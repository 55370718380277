import Cookies from "js-cookie";
import { useEffect, useId } from "react";
import { useDispatch } from "react-redux";
import { SetFCMTokenReuqest } from "services/modules/notifications/notifications";
import { requestForFCMToken } from "services/utils/Firebase";

const NotificationsPopup = () => {
  const id = useId();
  const dispatch = useDispatch();

  const fcm_token = Cookies.get("fcm_token");
  const token = Cookies.get("token");

  const getFCMToken = async () => {
    const updateNewFCMToken = (newFCMToken) => {
      const requestData = {
        body: {
          device: {
            fcm_token: newFCMToken || id,
          },
        },
      };
      dispatch(SetFCMTokenReuqest(requestData));
      Cookies.set("fcm_token", newFCMToken);
    };

    const newFCMToken = await requestForFCMToken();
    if (fcm_token !== newFCMToken) {
      updateNewFCMToken(newFCMToken);
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted" && !fcm_token && token) {
          getFCMToken();
        } else {
          getFCMToken();
        }
      });
    }
  }, []);

  return null;
};

export default NotificationsPopup;
