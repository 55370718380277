import { icons } from "assets/AssetHelper";
import Section from "./Section";
import { Stack } from "@mui/material";
import TextWithIcon from "components/Atoms/Text/TextWithIcon";
import dayjs from "dayjs";
import { formatDate } from "common/utils/helpers";

export default function RefundTransactions({ details }) {
  const status = details?.compounded_status;
  const adventurerRefundTransaction = details?.rectifications?.adventurer_refund_transaction;
  const expertRefundTransaction = details?.rectifications?.expert_compensation_transaction;

  if (status == "canceled_by_user" || status == "canceled_by_expert")
    return (
      <Section icon={icons.RefundTransactions} title="Refunded">
        <Stack direction="row" justifyContent="space-between" sx={{ width: "85%" }} flexWrap="wrap" gap={3}>
          {status == "canceled_by_user" && (
            <TextWithIcon
              icon={icons.RefundAmount}
              label="Compensation amount to Expert:"
              text={
                `${expertRefundTransaction?.amount || 0} ${details?.currency?.lookup_key}` || `0${details?.currency?.lookup_key}`
              }
            />
          )}
          <TextWithIcon
            icon={icons.RefundAmount}
            label="Refunded amount to adventurer:"
            text={`${adventurerRefundTransaction?.amount || 0} ${details?.currency?.lookup_key}`}
          />
          <TextWithIcon
            icon={icons.CancelationDate}
            label="Cancelation date:"
            text={formatDate(details?.journey?.canceled_at, "DD-MM-YYYY, hh:mm a")}
          />
          <TextWithIcon
            icon={icons.RefundDate}
            label="Refund / Compensation date:"
            text={
              adventurerRefundTransaction?.initiated_at
                ? formatDate(adventurerRefundTransaction?.initiated_at, "DD-MM-YYYY, hh:mm a")
                : "-"
            }
          />
        </Stack>
      </Section>
    );
}
