import { icons, images } from "assets/AssetHelper";
import { formatDate } from "common/utils/helpers";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Details",
    icon: icons?.detailsIcon,
    details: [
      {
        label: "Key",
        name: data?.key,
      },
      {
        label: "Value",
        name: data?.value,
      },
      {
        label: "Description",
        name: data?.description,
      },
    ],
  },
  {
    head: "Dates",
    icon: icons.detailsIcon,
    details: [
      {
        label: "Created At",
        name: formatDate(data?.created_at),
      },
      {
        label: "Updated At",
        name: formatDate(data?.updated_at),
      },
    ],
  },
];
