/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  requests: [],
  details: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function ExpertsRequestsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_REQUESTS_SUCCESS: {
      return {
        ...state,
        requests: payload?.data?.expert_requests,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state,
        details: payload?.data?.expert_request,
      };
    }
    case Types.UPDATE_REQUEST_SUCCESS: {
      const updatedRequest = payload?.data?.expert_request;
      const newRequests = state?.requests.map((request) => {
        if (request?.id === updatedRequest?.id) {
          return {
            ...request,
            status: updatedRequest?.status,
            rejection_reason: updatedRequest?.rejection_reason,
          };
        } else {
          return request;
        }
      });

      console.log("newRequests", newRequests);
      return {
        ...state,
        requests: newRequests,
        details: { ...state.details, status: updatedRequest?.status, rejection_reason: updatedRequest?.rejection_reason },
      };
    }

    case Types.REQUESTS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
