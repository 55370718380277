import Types from "./Types";

export const GetSystemConfigurationsSuccess = (payload) => ({
  type: Types.GET_SYSTEM_CONFIGURATIONS_SUCCESS,
  payload,
});

export const GetSystemConfigurationDetailSuccess = (payload) => ({
  type: Types.GET_SYSTEM_CONFIGURATION_DETAILS_SUCCESS,
  payload,
});


export const SystemConfigurationLoading = (payload) => ({
  type: Types.SYSTEM_CONFIGURATIONS_LOADING,
  payload,
});


