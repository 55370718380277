/* eslint-disable no-console */
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/messaging";
import { getDatabase, onValue, ref } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
import Cookies from "js-cookie";
import {
  ApiKey,
  AppId,
  AuthDomain,
  DatabaseUrl,
  MeasurementId,
  MessagingSenderId,
  ProjectId,
  StorageBucket,
  VapidKey,
} from "../../services/modules/utils/constant";

const config = {
  apiKey: ApiKey,
  authDomain: AuthDomain,
  databaseURL: DatabaseUrl,
  projectId: ProjectId,
  storageBucket: StorageBucket,
  messagingSenderId: MessagingSenderId,
  appId: AppId,
  measurementId: MeasurementId,
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
const messaging = getMessaging();
export const db = getDatabase(app);

export const requestForFCMToken = async (updateNewFCMToken) => {
  return await getToken(messaging, {
    vapidKey: VapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log("No registration token available. Request permission to generate one.");
        return null;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      return null;
    });
};

firebase.initializeApp(config);

export const listenOnNode = (dispatch, action) => {
  const userNode = Cookies.get("firebase_uid");
  const notificaiontsCount = ref(db, `users/${userNode}`);
  onValue(notificaiontsCount, (snapshot) => {
    const count = snapshot?.val()?.notification_count;
    dispatch(action(count));
  });
};

export const SignInFirebase = (firebaseAuthToken) => {
  signInWithCustomToken(auth, firebaseAuthToken || Cookies.get("firebase_auth_token"))
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // getAnalytics(config);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
};

export const SignoutFirebase = async () => {
  const auth = getAuth();
  return await signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log("signout from firebase");
    })
    .catch((error) => {
      // An error happened.
    });
};

export const auth = getAuth();
export const database = getDatabase();
export const firebaseSDK = firebase;
