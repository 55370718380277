/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  admin: {},
  sendOTP: {},
  verifyOTP: {},
  resetPassword: {},
  isLoading: {
    loading: null,
    type: "",
  },
};

export default function authReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_USER_PROFILE: {
      return {
        ...state,
        admin: payload?.data?.user,
      };
    }
    case Types.LOGOUT_SUCCESS: {
      return {
        ...state,
        admin: {},
      };
    }
    case Types.OTPREQUEST_SUCCESS: {
      return {
        ...state,
        sendOTP: payload,
      };
    }

    case Types.OTPVERIFY_SUCCESS: {
      return {
        ...state,
        verifyOTP: payload,
      };
    }

    case Types.RESETPASSWORD_SUCCESS: {
      return {
        ...state,
        resetPassword: payload,
      };
    }

    case Types.AUTH_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
