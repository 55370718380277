import { Button, Divider, Input, Modal, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch, useSelector } from "react-redux";
import { ToggleRejectionReasonModal } from "services/modules/modal/Actions";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export default function RejectionReasonModal({ onConfirm, onCancel, reason, onChange, handleRejectRequest, id }) {
  const { vivideOrange, red } = useThemePalette();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    pb: 4,
    outline: "none",
  };

  const [inputError, setInputError] = useState("");
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modal.rejectionReasonModal.isOpen);
  const type = useSelector((state) => state.modal.rejectionReasonModal.type);

  const modalTitle =
    type === "display" ? "Rejection reason" : type === "edit" ? "Edit Rejection reason" : "Reject request expert";
  const confirmBtnTitle = type === "display" ? "edit" : type === "edit" ? "update" : "submit";
  const isShowCloseIcon = type === "display" || type === "edit";
  const mainColor = type === "display" || type === "edit" ? vivideOrange : red;
  const maxLength = 300;

  const handleConfirm = () => {
    if (reason?.length == 0) {
      setInputError("Please enter a reason");
    } else {
      setInputError("");
      type === "display" && dispatch(ToggleRejectionReasonModal({ type: "edit", isOpen: true }));
      (type === "new" || type === "edit") && handleRejectRequest(id, reason);
    }
  };

  const handleClose = () => {
    setInputError("");
    onCancel && onCancel();
  };

  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Stack sx={style}>
        <Stack alignItems="center" direction="row" sx={{ p: 3 }} justifyContent="space-between">
          <Typography
            component="h1"
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              textTransform: "capitalize",
              mx: "auto",
              color: mainColor,
            }}
          >
            {modalTitle}
          </Typography>
          {isShowCloseIcon && <CloseIcon onClick={handleClose} fontSize="large" sx={{ cursor: "pointer" }} />}
        </Stack>
        {type == "new" && <Divider />}
        <Stack sx={{ p: 3 }}>
          {type == "display" && (
            <Stack sx={{ border: "1px solid #D0D0D0", p: 2 }}>
              <Typography>{reason}</Typography>
            </Stack>
          )}

          {(type == "new" || type == "edit") && (
            <Stack gap={2} px={2} pt={2}>
              {/* <Typography sx={{fontSize:'20px', fontWeight:500}}>Write Rejection reason</Typography> */}
              <Input
                value={reason}
                onChange={onChange}
                disableUnderline
                multiline
                rows={6}
                sx={{ border: "1px solid #D0D0D0", p: 1 }}
                inputProps={{ maxLength: maxLength }}
                placeholder="Write Rejection reason"
              />
            </Stack>
          )}
        </Stack>

        {(type == "edit" || type == "new") && (
          <Stack sx={{ px: 3, alignItems: "end" }} direction="row" justifyContent="space-between">
            <Typography sx={{ color: "red" }}>{inputError}</Typography>
            <Typography>
              {reason.length}/{maxLength}
            </Typography>
          </Stack>
        )}

        <Stack alignItems="center" mt={4}>
          <Stack direction="row" gap={2}>
            <Button
              sx={{
                color: "black",
                border: "1px solid #000000",
                py: 2,
              }}
              className="r-25px"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: mainColor,
                "&:hover": {
                  backgroundColor: mainColor,
                },
                color: "white",
                textTransform: "capitalize",
              }}
              className="primary r-25px"
              onClick={handleConfirm}
            >
              {confirmBtnTitle}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
