import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import {
  AddReportReasonRequest,
  GetReportReasonDetailRequest,
  GetReportReasonsRequest,
  updateReportReasonRequest,
  DeleteReportReasonRequest,
} from "services/modules/lookups";
import ReportReasonsTemplate from "templates/reportReasons";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { formatDate } from "common/utils/helpers";

function ReportReasonsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.lookup.ReportReasons);
  const count = useSelector((state) => state.lookup.count);
  const details = useSelector((state) => state.lookup.ReportReason);
  const load = useSelector((state) => state.lookup.isloading);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(GetReportReasonDetailRequest({ id }));
  };
  // details of a single user
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetReportReasonsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      id: <span onClick={() => void 0}>{data?.id}</span>,
      created_at: <>{formatDate(data?.created_at)}</>,
      updated_at: <>{formatDate(data?.updated_at)}</>,
      delete_field: "Reason",
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/reportreasons",
      title: "Lookups/Report reasons",
    },
    type && {
      active: false,
      title: type === "details" ? "Details" : id ? "Edit Report Reason" : "Add Report Reason",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/reportreasons");
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(`Report Reason has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: {
        report_reason: {
          en: data.en,
          ar: data.ar,
          for: data.for,
        },
      },
      action,
    };

    if (id) {
      //update call
      dispatch(updateReportReasonRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(AddReportReasonRequest({ ...requestData }));
    }
  };

  const displayToParameter = new Map([
    ["Post", "post"],
    ["Expert", "expert"],
    ["Journey Chat", "journey_chat"],
  ]);

  const filterList = [
    {
      type: "SearchableSelect", // this isn't important anyways
      placeholder: "Type",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, for: displayToParameter?.get(value?.label) };
        });
      },
      options: [
        { id: 0, name: "Post" },
        { id: 1, name: "Expert" },
        { id: 2, name: "Journey Chat" },
      ],
    },
  ];

  const handleDelete = (data) => {
    //action to be called after successfull deletion
    const id = data?.props?.children;
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/reportreasons");
      toast.success("Report Reason deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(DeleteReportReasonRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const id = data?.id?.props?.children;
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "report reasons"} />
      <ReportReasonsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default ReportReasonsPage;
