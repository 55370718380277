import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_attributes,
  };
};


export const structure = (_, countries, onMenuOpen, handleMenuScrollToBottom) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "City name  (EN)",
        width: "47%",
        placeholder: "City name  (EN)",
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "City name (AR)",
        width: "47%",
        placeholder: "City name (AR)",
      },
      {
        name: `country`,
        kind: "select",
        type: "text",
        label: "Select country",
        width: "47%",
        options: countries,
        handleOpen: onMenuOpen,
        handleScroll:handleMenuScrollToBottom
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
  });
