import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function NoData({ section }) {
  const { vivideOrange } = useThemePalette();
  const title = section?.name == "Total Earning" ? "Earning" : section?.name;

  return (
    <Stack
      sx={{ bgcolor: "white", height: "450px", borderRadius: "8px", justifyContent: "center", alignItems: "center", gap: 2 }}
    >
      <Stack
        sx={{ border: `3px solid ${vivideOrange}`, borderRadius: "50%", alignItems: "center", justifyContent: "center" }}
        width="120px"
        height="120px"
      >
        <Box component="img" src={section.icon} width="55%" />
      </Stack>
      <Stack>
        <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
          No <span style={{ textTransform: "lowercase" }}>{title}</span> yet
        </Typography>
      </Stack>
    </Stack>
  );
}
