import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

import { GoogleMapKey } from "services/modules/utils/constant";
import { icons } from "assets/AssetHelper";

const containerStyle = {
  width: "100%",
  height: "300px",
};

export default function Map({ location }) {
  // const { type, getCurrentLocation, location, details } = props;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  // when a place is searched
  // const getSearchLocation = (place) => {
  //   getCurrentLocation(
  //     place.geometry.location.lat(),
  //     place.geometry.location.lng()
  //   );
  // };

  // // changing the marker location
  // const changeLocation = (map) => {
  //   getCurrentLocation(map.latLng.lat(), map.latLng.lng());
  // };

  // useEffect(() => {
  //   if (type === "add") {
  //     // reset the location to Riyad City (default value)
  //     getCurrentLocation(24.7136, 46.6753);
  //   } else {
  //     // set the location to the city's location
  //     getCurrentLocation(parseFloat(details?.lat), parseFloat(details?.long));
  //   }
  // }, [details]);

  return (
    <Stack
      sx={{
        borderRadius: "6px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        overflow: "hidden",
        position: "relative",
        width: "80%",
      }}
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location}
          zoom={15}
          options={{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
          }}
          // onClick={(map) => changeLocation(map)}
        >
          <MarkerF position={{ lat: location?.lat, lng: location?.lng }} />
        </GoogleMap>
      ) : null}
    </Stack>
  );
}
