import Types from "./Types";

export const GetUsersSuccess = (payload) => ({
  type: Types.GET_USERS_SUCCESS,
  payload,
});

export const GetScrollUsersSuccess = (payload) => ({
  type: Types.GET_SCROLL_USERS_SUCCESS,
  payload,
});

export const UpdateUserStatusSuccess = (payload) => ({
  type: Types.UPDATE_USER_STATUS_SUCCESS,
  payload,
});

export const GetUserDetailsSuccess = (payload) => ({
  type: Types.GET_USERS_DETAILS_SUCCESS,
  payload,
});

export const GetCategoriesSuccess = (payload) => ({
  type: Types.GET_CATEGORIES_SUCCESS,
  payload,
});

export const GetWalletDetailsSuccess = (payload) => ({
  type: Types.GET_WALLET_DETAILS_SUCCESS,
  payload,
});

export const UsersLoading = (payload) => ({
  type: Types.USERS_LOADING,
  payload,
});

export const GetJourneysSuccess = (payload) => ({
  type: Types.GET_JOURNEYS_SUCCESS,
  payload,
});

export const GetJourneyDetailsSuccess = (payload) => ({
  type: Types.GET_JOURNEY_DETAIL_SUCCESS,
  payload,
});

export const JourneysLoading = (payload) => ({
  type: Types.JOURNEYS_LOADING,
  payload,
});
