import { Grid, Stack } from "@mui/material";
import NotificationsPopup from "components/Atoms/Notifications/NotificationPopup";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { GetUserProfileRequest } from "services/modules/auth";
import { SetUnreadCount } from "services/modules/notifications/notifications/Actions";
import { SignInFirebase, auth, listenOnNode } from "services/utils/Firebase";
import Header from "./Header";
import SideNavbar from "./SideNavbar";
import dayjs from "dayjs";

const Layout = () => {
  const isAuth = Cookies.get("token");

  const dispatch = useDispatch();
  const unreadCount = useSelector((state) => state.notifications.unreadCount);

  useEffect(() => {
    dispatch(GetUserProfileRequest());
  }, []);

  // useEffect(() => {
  //   const title = "Ventx Dashboard";
  //   if (unreadCount) {
  //     document.title = `${title} 🔔`;
  //   } else {
  //     document.title = `${title}`;
  //   }
  // }, [unreadCount]);

  useEffect(() => {
    // start listening on the node when sign in firebase
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        listenOnNode(dispatch, SetUnreadCount);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // check firebase auth token expiration
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const refreshedToken = await user.getIdToken();
        Cookies.set("firebase_auth_token", refreshedToken);
      } else {
        SignInFirebase();
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Grid container>
      <Grid item display={{ xs: "none", lg: "grid" }} lg={2}>
        <SideNavbar />
      </Grid>
      <Grid item xs={12} lg={10} sx={{ backgroundColor: "#F5F5F5", overflowY: "scroll", height: "100vh" }}>
        <NotificationsPopup />
        <Header />
        <Stack p={3}>
          <Breadcrumb />
          <Outlet />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Layout;
