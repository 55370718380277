const lookupTypes = {
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_SCROLL_USERS_SUCCESS: "GET_SCROLL_USERS_SUCCESS",
  GET_USERS_DETAILS_SUCCESS: "GET_USERS_DETAILS_SUCCESS",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_WALLET_DETAILS_SUCCESS: "GET_WALLET_DETAILS_SUCCESS",
  GET_JOURNEYS_SUCCESS: "GET_JOURNEYS_SUCCESS",
  GET_JOURNEY_DETAIL_SUCCESS: "GET_JOURNEY_DETAIL_SUCCESS",
  UPDATE_USER_STATUS_SUCCESS: "UPDATE_USER_STATUS_SUCCESS",

  USERS_LOADING: "USERS_LOADING",
  JOURNEYS_LOADING: "JOURNEYS_LOADING",
};

export default lookupTypes;
