import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

const DeleteModal = ({
  t,
  open,
  setOpen,
  handelDeleteRequest,
  handleCancelDelete,
  customClass,
  text,
}) => {
  const { red } = useThemePalette();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    py: 4,
  };
  // const handleClose = () => setOpen(false);
  const handleClose = () => {
    setOpen(false);
    handleCancelDelete();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        sx={style}
        alignItems="center"
        justifyContent="center"
        className={`delete-modal ${customClass}`}
      >
        <Stack
          gap={3}
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Typography
            component="h1"
            style={{ color: red, fontWeight: "bold", fontSize: "30px" }}
          >
            {text[0]}
          </Typography>
          <Divider sx={{ border: "1px solid #F5F5F5", width: "100%" }} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Box component="img" src={icons.errorCircle} width="40px" />
            <Typography
              textAlign="center"
              style={{
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "24px",
              }}
            >
              {text[1]}
            </Typography>
          </Stack>
        </Stack>
        {/* <Stack flexDirection='row' alignItems='center' mt={4}>
                    <Button style={{ width: '150px', color: 'black' }} onClick={() => setOpen(false)}>  {t('offer.cancel')}</Button>

                    <Button style={{ width: '150px' }} className='primary r-25px' onClick={handelDeleteRequest}>{t('offer.delete')}</Button>
                </Stack> */}
        <Stack
          flexDirection="row"
          alignItems="center"
          mt={4}
          className="buttons-wrap"
          gap={2}
        >
          <Button
            sx={{
              color: "black",
              border: "1px solid #000000",
            }}
            className="r-25px"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            sx={{
              backgroundColor: red,
              "&:hover": {
                backgroundColor: red,
              },
              color: "white",
            }}
            className="primary r-25px"
            onClick={handelDeleteRequest}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DeleteModal;
