import { Box } from "@mui/material";
import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import CActions from "components/Molecules/Crud/components/actions";
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

const ParticipatingJourneysTemplate = ({
  handlePageChange,
  data,
  count,
  filterList,
  actionsList,
  setFilterObj,
  paginationPage,
  headerChildren,
}) => {
  const [tableData, setTableData] = useState({});
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Actions = (id, elmIndex, data) => {
    return (
      <CActions
        id={id}
        index={elmIndex}
        data={data}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        anchorEl={anchorEl}
        actionsList={actionsList}
      />
    );
  };
  const mappedData = data?.map((data, index) => {
    return { ...data, Actions: Actions(data?.id, index, data) };
  });

  return (
    <>
      <Box sx={{ bgcolor: "#fff", pt: 2 }}>
        <TableUse
          headerChildren={headerChildren}
          list={mappedData}
          columnsData={[
            { accessor: "id", Header: "#", clickable: true },
            {
              accessor: "journey.name",
              Header: "Journey name",
              sort: true,
            },
            {
              accessor: "journey.expert.fullname",
              Header: "Expert name",
              sort: true,
            },
            {
              accessor: "journey.full_address",
              Header: "Location",
              sort: true,
            },
            { accessor: "total_original_amount", Header: "total paid ", sort: true },
            { accessor: "compounded_status", Header: "Status", sort: true },
            { accessor: "Actions", Header: "Actions" },
          ]}
          isChecked={false}
          setTableData={setTableData}
          isSearch={false}
          setFilterObj={setFilterObj}
          filterList={filterList}
          total_count={count}
          paginationPage={paginationPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </>
  );
};

export default ParticipatingJourneysTemplate;
