import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { useNavigate } from "react-router-dom";
import Section from "./Section";
import AvatarCard from "../Avatar/AvatarCard";

export default function MainInfo({ details }) {
  const navigate = useNavigate();
  const { vivideOrange } = useThemePalette();
  const { journey } = details;

  return (
    <Section icon={icons.detailsIcon} title="Main info">
      <Stack gap={1}>
        <Typography sx={{ color: "#9C9C9C" }}>Journey details</Typography>
        <Stack direction="row" gap={1}>
          <Box component="img" width="64px" height="64px" src={journey?.photos?.[0]?.url} />
          <Stack sx={{ width: "85%" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: 700, wordBreak: "break-word" }}>{journey?.name}</Typography>
            <Stack sx={{ mt: "auto" }} direction="row" alignItems="center" justifyContent="space-between">
              <Typography sx={{ color: "#9C9C9C" }}>
                {journey?.city?.name}, {journey?.country?.name}
              </Typography>
              <Button
                sx={{ p: 0, color: vivideOrange }}
                endIcon={<ArrowForwardIosIcon />}
                onClick={() => navigate(`/journeys?type=details&id=${journey?.id}`)}
              >
                View details
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography sx={{ color: "#9C9C9C" }}>Expert</Typography>
        <AvatarCard
          data={journey?.expert}
          style={{
            avatar: { width: "64px", height: "64px" },
            content: { fontSize: "14px" },
            name: { fontSize: "18px" },
          }}
          showWallet={false}
        />
      </Stack>
      <Stack gap={1} sx={{ width: "fit-content" }}>
        <Typography sx={{ color: "#9C9C9C" }}>Adventurer</Typography>
        <AvatarCard
          data={details?.user}
          style={{
            avatar: { width: "64px", height: "64px" },
            content: { fontSize: "14px" },
            name: { fontSize: "18px" },
          }}
          showWallet={false}
          showRate={false}
          isMainParticipant
        />
      </Stack>
    </Section>
  );
}
