import Logo from "./images/logo.svg";
import Logo2 from "./images/orange_logo.svg";
import Usersetting from "./icons/usersetting.svg";
import Home from "./icons/home.svg";

import Assistant from "./icons/assistant.svg";
import Courses from "./icons/courses.svg";
import Setting from "./icons/setting.svg";
import imageIcon from "./icons/image.png";
import deleteIcon from "./icons/delete.svg";
import editIcon from "./icons/edit.svg";
import videoIcon from "./icons/video.svg";
import exportIcon from "./icons/export.svg";
import plusCircleIcon from "./icons/plus-circle.svg";
import orangeSearchIcon from "./icons/orange_search_icon.svg";
import blackSearchIcon from "./icons/black_search_icon.svg";
import filterIcon from "./icons/filter.svg";
import AddPersonIcon from "./icons/add-person.svg";
import SourceIcon from "./icons/source.svg";
import AttachedIcon from "./icons/attached.svg";
import EmptyQuestionsIcons from "./icons/empty-questions.svg";
import QuestionBankIcon from "./icons/question-bank.svg";
import Delete from "./icons/Delete-icon.svg";
import Download from "./icons/Download.svg";
import Mask from "./dummy/Mask.png";
import loginSideImage from "./images/login-page-side-image.png";
import lockIcon from "./icons/lock.svg";
import notificationsHeader from "./icons/notificationsHeader.svg";
import resetPassword from "./icons/reset-password.svg";
import errorCircle from "./icons/error-circle.svg";
import check from "./icons/check-one.svg";
import location from "./icons/location.svg";
import templateDeleteIcon from "./icons/deleteIcon.svg";
import editWhite from "./icons/editWhite.svg";
import detailsIcon from "./icons/detailsIcon.svg";
import dates from "./icons/dates.svg";
import fees from "./icons/fees.svg";
import map from "./icons/map.svg";
import details from "./icons/details.svg";
import uploadIcon from "./icons/uploadIcon.svg";
import role from "./icons/role.svg";
import email from "./icons/email.svg";
import phone from "./icons/phone.svg";
import checkMark from "./icons/checkMark.svg";
import dashboard from "./icons/dashboard.svg";
import users from "./icons/users.svg";
import giftcards from "./icons/gift_cards.svg";
import cities from "./icons/cities.svg";
import gear from "./icons/gear.svg";
// import reportData from "./icons/carbon_report-data.svg"
import wrappers from "./icons/wrappers.svg";
import occassions from "./icons/occassions.svg";

import checkedfilled from "./icons/checkedfilled.svg";
import AnonymousUser from "./icons/anonymousUser.jpg";
//ventex icons
import adventurer from "./icons/Users new.svg";
import expert from "./icons/Expert.svg";
import equipment from "./icons/equipment.svg";
import equipmentIcon from "./icons/equipmentIcon.svg";
import Journeys from "./icons/Journeys.svg";
import Booking from "./icons/booking.svg";
import Transactions from "./icons/transactions.svg";
import Community from "./icons/Community.svg";
import Reports from "./icons/reports.svg";
import Notifications from "./icons/notification-bing.svg";
import lookups from "./icons/lookups.svg";
import Onboarding from "./icons/onBoarding.svg";
import Document from "./icons/Document.svg";
import nameField from "./icons/nameField.svg";
import emailField from "./icons/emailField.svg";
import phoneField from "./icons/phoneField.png";
import genderField from "./icons/genderField.svg";
import dateField from "./icons/dateField.svg";
import bioField from "./icons/bioField.svg";
import Participants from "./icons/3Users.svg";
import DownloadDocument from "./icons/DownloadDocument.svg";
import lowRiskLevel from "./icons/LowRiskLevel.svg";
import mediumRiskLevel from "./icons/MeduimRiskLevel.svg";
import highRiskLevel from "./icons/HighRiskLevel.svg";
import categories from "./icons/categories.svg";
import eye from "./icons/eye.svg";
import Like from "./icons/Like.svg";
import Comment from "./icons/Comments.svg";
import Share from "./icons/Shares.svg";
import Wallet from "./icons/wallet.svg";
import Bank from "./icons/Bank.svg";
import CertificatesIcon from "./icons/CertificatesIcon.svg";
import Info from "./icons/Info.svg";
import QuestionMark from "./icons/QuestionMark.svg";
import Checked from "./icons/Checked.svg";
import SocialLinks from "./icons/SocialLinks.svg";
import GreyEye from "./icons/GreyEye.svg";
import Hash from "./icons/Hash.svg";
import Calender from "./icons/Calender.svg";
import PaymentDetails from "./icons/PaymentDetails.svg";
import ExclamationMark from "./icons/ExclamationMark.svg";
import RefundTransactions from "./icons/RefundTransactions.png";
import RefundDate from "./icons/RefundDate.svg";
import RefundAmount from "./icons/RefundAmount.svg";
import CancelationDate from "./icons/CancelationDate.svg";
import ContactUs from "./icons/contactus.svg"
import Earnings from "./icons/Earnings.png";
import Route from "./icons/Route.svg";
import Reservations from "./icons/Reservations.svg";
import Registerations from "./icons/Registerations.svg";
import EquipmentReports from "./icons/EquipmentReports.svg";
import ExpertRequest from "./icons/ExpertRequest.svg";
import VerificationRequest from "./icons/VerificationRequest.svg";
import MoneyTransfer from "./icons/MoneyTransfer.svg";

import Increase from "./icons/increase.svg";
import Decrease from "./icons/decrease.svg";

export const dummy = {
  Mask,
};

export const images = {
  Logo,
  loginSideImage,
  Logo2,
};
export const icons = {
  ExpertRequest,
  VerificationRequest,
  MoneyTransfer,
  EquipmentReports,
  Earnings,
  Route,
  Reservations,
  Registerations,
  Increase,
  Decrease,
  RefundDate,
  RefundAmount,
  CancelationDate,
  RefundTransactions,
  ExclamationMark,
  PaymentDetails,
  Calender,
  Hash,
  GreyEye,
  SocialLinks,
  Checked,
  QuestionMark,
  Info,
  CertificatesIcon,
  Bank,
  eye,
  Wallet,
  checkedfilled,
  detailsIcon,
  editWhite,
  templateDeleteIcon,
  Usersetting,
  AnonymousUser,
  Home,
  adventurer,
  expert,
  equipment,
  equipmentIcon,
  Journeys,
  Booking,
  Transactions,
  Community,
  Reports,
  Notifications,
  lookups,
  Onboarding,
  Document,
  Assistant,
  Courses,
  Setting,
  imageIcon,
  deleteIcon,
  editIcon,
  videoIcon,
  exportIcon,
  plusCircleIcon,
  orangeSearchIcon,
  blackSearchIcon,
  filterIcon,
  AddPersonIcon,
  SourceIcon,
  AttachedIcon,
  EmptyQuestionsIcons,
  QuestionBankIcon,
  Delete,
  Download,
  lockIcon,
  notificationsHeader,
  resetPassword,
  errorCircle,
  check,
  location,
  dates,
  fees,
  map,
  details,
  uploadIcon,
  role,
  email,
  phone,
  checkMark,
  dashboard,
  users,
  giftcards,
  cities,
  gear,
  // reportData,
  wrappers,
  occassions,
  nameField,
  emailField,
  phoneField,
  genderField,
  dateField,
  bioField,
  Participants,
  DownloadDocument,
  categories,
  Like,
  Comment,
  Share,
  AnonymousUser,
  lowRiskLevel,
  mediumRiskLevel,
  highRiskLevel,
  ContactUs,
};
