import { Avatar, Stack, Typography } from "@mui/material";
import { formatDate } from "common/utils/helpers";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import CommentDetailsModal from "components/Molecules/Models/CommentDetailsModal";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetCommentsDetailsRequest, GetCommentsRequest, UpdateCommentRequest } from "services/modules/community/comments";
import HiddenCommentsTemplate from "templates/hiddenComments/HiddenComments";

function HiddenCommentsPage({ id }) {
  const dispatch = useDispatch();

  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const list = useSelector((state) => state.comments.Comments);
  const details = useSelector((state) => state.comments.Comment);
  const count = useSelector((state) => state.comments.comments_count);
  const load = useSelector((state) => state.comments.isloading);

  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [tableData, setTableData] = useState({});
  const [openCommentModal, setOpenCommentModal] = useState(false);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };
  const getDetails = async () => {
    // destructure it this way becase the id is altered inside the mapped data
    const comment_id = tableData?.rowData?.id?.props?.children;
    dispatch(GetCommentsDetailsRequest({ post_id: id, comment_id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: page,
        keyword: "",
        sort_by: "id",
        is_hidden: true,
        is_hidden_reviewed: false,
        ...filterObj,
      },
      id,
    };
    dispatch(GetCommentsRequest(requestData));
  };

  const updateComment = (actionType) => {
    const action = () => {
      toast.success(`comment has been ${actionType == "unhide" ? "unhidden" : "kept hidden"} successfully`);
    };
    let body;
    if (actionType == "unhide") {
      body = {
        comment: {
          is_hidden: false, //mark comment as unhidden
        },
      };
    } else {
      body = {
        comment: {
          is_hidden_reviewed: true, //mark hidden comment as reviewed
        },
      };
    }
    const requestData = {
      body,
      post_id: id,
      comment_id: tableData?.rowData?.id?.props?.children,
      action,
    };
    dispatch(UpdateCommentRequest(requestData));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      //override the default behavior of the table
      id: <span onClick={() => void 0}>{data?.id}</span>,
      content: data?.content.length > 10 ? data?.content.slice(0, 10) + "..." : data?.content,
      created_at: <>{formatDate(data?.created_at)}</>,
    };
  });

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/adventurer",
      title: "Adventurer",
    },
    id && {
      active: false,
      title: "Adventurer details",
    },
  ];

  const filterList = [];

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          getDetails();
          setOpenCommentModal(true);
        },
        text: "View",
      },
    ];
  };

  //if more modals needed, pass them to the modalComponents as below

  useEffect(() => {
    getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading} />
      <CommentDetailsModal
        open={openCommentModal}
        title="Comment details"
        closeTitle="Keep hidden"
        actionTitle="Unhide"
        actionColor="#FF7900"
        setOpen={setOpenCommentModal}
        handleCloseAction={updateComment}
        handleAction={updateComment}
        isLoading={load?.loading}
        content={
          <Stack flexDirection={"row"} gap={2}>
            <Avatar src={details?.user?.avatar} sx={{ width: 64, height: 64 }} />
            <Stack flexDirection={"column"} gap={1}>
              <Typography fontSize={"18px"} fontWeight={700}>
                {details?.user?.username}
              </Typography>
              <Typography>{details?.content}</Typography>
            </Stack>
          </Stack>
        }
        text={["Comment details", "test"]}
      />
      <HiddenCommentsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        setTableData={setTableData}
      />
    </>
  );
}

export default HiddenCommentsPage;
