import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const SummaryCard = ({ card }) => {
  return (
    <Stack sx={{ p: 2, flexGrow: 1, bgcolor: "#fff", borderRadius: "8px", maxWidth: "17%" }} alignItems="start" gap={1}>
      <Stack sx={{ bgcolor: "rgba(255, 121, 0, 0.12)", p: 1.3, borderRadius: "50%" }}>
        <Box component="img" src={card.icon} width="25px" height="25px" />
      </Stack>
      <Typography sx={{ fontFamily: "Roboto", fontSize: "32px", fontWeight: 500, wordBreak: "break-word" }}>
        {card?.report?.total_amount} {card?.report?.amount_currency ?? " "}
      </Typography>
      <Typography sx={{ color: "#666666" }}>{card?.name}</Typography>
    </Stack>
  );
};
export default function Summary({ data }) {
  return (
    <Stack direction="row" justifyContent="space-between" gap={3}>
      {data?.map((item, i) => (
        <SummaryCard key={i} card={item} />
      ))}
    </Stack>
  );
}
