import { combineReducers } from "redux";

import authReducer from "./modules/auth/Reducer";
import documentsReducer from "./modules/documents/Reducer";
import PostsReducer from "./modules/community/posts/Reducer";
import CommentsReducer from "./modules/community/comments/Reducer";
import EquipmentReducer from "./modules/equipments/Reducer";
import JourneysReducer from "./modules/journeys/Reducer";
import modalReducer from "./modules/modal/Reducer";
import OnBoardingReducer from "./modules/onboarding/Reducer";
import systemConfigurationReducer from "./modules/systemconfigurations/Reducer";
import filesUploadReducer from "./modules/uploadFiles/Reducer";
import reviewsReducer from "./modules/users/experts/reviews/Reducer";
import moneyTransferRequestsReducer from "./modules/users/experts/transferMoneyRequests/Reducer";
import usersReducer from "./modules/users/users/Reducer";
import ExpertsRequestsReducer from "./modules/users/experts/upgradeToExpertRequests/Reducer";
import VerificationRequestsReducer from "./modules/users/experts/verificationRequests/Reducer";
import reportsReducer from "./modules/users/experts/reportedExperts/Reducer";
import ReportedChatsReducer from "./modules/journeys/reportedChats/Reducer";
import BookingsReducer from "./modules/bookings/Reducer";
import SystemNotificationsReducer from "./modules/notifications/systemNotifications/Reducer";
import EarningReportsReducer from "./modules/earningReports/Reducer";
import contactsReducer from "./modules/contactUs/Reducer";
import StatisticsReducer from "./modules/statistics/Reducer";
import NotificationsReducer from "./modules/notifications/notifications/Reducer";
import lookupReducer from "./modules/lookups/Reducer";
import journeyCancellationReasonsReducer from "./modules/lookups/journeyCancelationReasons/Reducer";

const rootReducer = combineReducers({
  files: filesUploadReducer,
  modal: modalReducer,
  auth: authReducer,
  lookup: lookupReducer,
  document: documentsReducer,
  systemConfig: systemConfigurationReducer,
  onboarding: OnBoardingReducer,
  posts: PostsReducer,
  comments: CommentsReducer,
  Journeys: JourneysReducer,
  users: usersReducer,
  equipment: EquipmentReducer,
  reviews: reviewsReducer,
  reports: reportsReducer,
  moneyTransferRequestsL: moneyTransferRequestsReducer,
  ExpertsRequests: ExpertsRequestsReducer,
  VerificationRequests: VerificationRequestsReducer,
  equipment: EquipmentReducer,
  Journeys: JourneysReducer,
  ReportedChats: ReportedChatsReducer,
  users: usersReducer,
  onboarding: OnBoardingReducer,
  systemNotifications: SystemNotificationsReducer,
  notifications: NotificationsReducer,
  earningReports: EarningReportsReducer,
  contactUs: contactsReducer,
  statistics: StatisticsReducer,
  bookings: BookingsReducer,
  journeyCancellationReasons: journeyCancellationReasonsReducer,
});

export default rootReducer;
