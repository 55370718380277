import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_attributes,
  };
};

export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.title`,
        kind: "input",
        type: "text",
        label: "Notification Title (EN))",
        width: "47%",
        placeholder: "Write Notification title (EN)",
      },
      {
        name: `ar.title`,
        kind: "input",
        type: "text",
        label: "Notification Title (AR)",
        width: "47%",
        placeholder: "Write Notification title (AR)",
      },
      {
        name: `en.message`,
        kind: "textArea",
        type: "text",
        label: "Notifications message (EN)",
        width: "47%",
        placeholder: "Write Notification message (EN)",
        colSpan: 4,
        maxCharLength: 200,
      },
      {
        name: `ar.message`,
        kind: "textArea",
        label: "Notifications message (AR)",
        width: "47%",
        placeholder: "Write Notification message (AR)",
        colSpan: 4,
        maxCharLength: 200,
      },
      {
        name: `user_classification`,
        kind: "radioBox",
        label: "User type",
        width: "47%",
        placeholder: "Write Notification message (AR)",
        options: [
          { value: 0, label: "Adventures" },
          { value: 1, label: "Experts" },
          { value: 3, label: "all" },
        ],
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("title is required"),
      message: Yup.string().required("message is required"),
    }),
    ar: Yup.object().shape({
      title: Yup.string().required("title is required"),
      message: Yup.string().required("message is required"),
    }),
    user_classification: Yup.string().required("user type is required"),
  });
