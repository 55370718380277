import { makeFilterString } from "../utils/Helper";
import {
GetSystemConfigurationDetailSuccess,GetSystemConfigurationsSuccess,SystemConfigurationLoading
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetSystemConfigurationsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "system configurations",
    isLoading: SystemConfigurationLoading,
    successFunction: GetSystemConfigurationsSuccess,
    url: `/admin/lookups/system_configurations?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetSystemConfigurationDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SystemConfigurationLoading,
    loadingType: "system configurations details",
    successFunction:GetSystemConfigurationDetailSuccess,
    url: `/admin/lookups/system_configurations/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const updateSystemConfigurationRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SystemConfigurationLoading,
    loadingType: "update system configurations",
    url: `/admin/lookups/system_configurations/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



