import { Box, Stack, Typography } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";

const EarningReportsTemplate = ({
  handlePageChange,
  data,
  count,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  earningsData,
}) => {
  return (
    <>
      <Box> 
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <Stack width="50%">
          <Typography fontSize="32px" fontWeight="700">
            Reports
          </Typography>
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: (
              <Typography sx={{ fontSize: "28px" }}>
                Total Earnings: <span style={{ fontSize: "32px", fontWeight: 600 }}>{earningsData?.total_earnings}</span>
               {" "} {earningsData?.total_earnings_currency_iso_code}
              </Typography>
            ),
            columns: [
              { accessor: "id", Header: "#" },
              { accessor: "reservation_number", Header: "Booking num", sort: true },
              { accessor: "sub_total_amount", Header: "Total Price without VAT", sort: true },
              { accessor: "commission", Header: "VentX commission", sort: true },
              { accessor: "created_at", Header: "Date", sort: true },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search Notifications",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
          }}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default EarningReportsTemplate;
