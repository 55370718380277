import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import AvatarCard from "components/Molecules/Avatar/AvatarCard";

const Field = ({ info, icon }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box component="img" src={icon} sx={{ width: "24px", height: "24px" }} />
      <Typography fontSize="20px" fontWeight={500}>
        {info}
      </Typography>
    </Stack>
  );
};
export const detailsData = ({ data }) => [
  {
    head: <AvatarCard data={data} userType="adventurer" sx={{ width: "114px", height: "114px" }} />,
  },
  {
    avatar: data.avatar,
    details: [
      {
        label: "Adventure name",
        name: <Field info={data?.fullname} icon={icons.nameField} />,
      },
      {
        label: "User name",
        name: <Field info={data?.username} icon={icons.nameField} />,
      },
      {
        label: "Email",
        name: <Field info={data?.email} icon={icons.email} />,
      },
      {
        label: "Phone Number",
        name: <Field info={data?.phone_number ? "+" + data?.country_code + data?.phone_number : null} icon={icons.phoneField} />,
      },
      {
        label: "Gender",
        name: <Field info={data?.gender} icon={icons.genderField} />,
      },
      {
        label: "Date of brith",
        name: <Field info={data?.date_of_birth} icon={icons.dateField} />,
      },
      {
        label: "Bio",
        name: <Field info={data?.user_bio} icon={icons.bioField} />,
      },
    ],
  },
];
