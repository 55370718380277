import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function DetailsList({ data }) {
  const { backgroundGray, textGray, labelColor } = useThemePalette();
  return (
    <Box p="24px">
      <Stack direction="row" gap="8px" alignItems="center" mb="24px">
        {data?.icon && (
          <Stack
            justifyContent="center"
            alignItems="center"
            width="48px"
            height="48px"
            sx={{ borderRadius: "8px", bgcolor: backgroundGray }}
          >
            <Box component="img" src={data?.icon} />
          </Stack>
        )}

        <Typography fontSize="26px" fontWeight="500" color={textGray}>
          {data?.head}
        </Typography>
      </Stack>

      <Stack flexDirection={"row"} gap={"35px"}>
        {data?.images?.map(
          (data) =>
            (data?.media_type === "photo" && (
              <Box component="img" src={data?.url} width="197px" height="165px" borderRadius="8px" alt="" />
            )) ||
            (data?.media_type === "video" && (
              <Box>
                <video width="197px" height="165px" controls>
                  <source src={data?.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            ))
        )}
      </Stack>

      <Stack direction="row" flexWrap="wrap" gap="30px">
        {data?.details?.map((data) => (
          <Stack gap="12px" width={data?.width ? data?.width : "45% !important"} mr="20px">
            <Typography fontSize="16px" color={labelColor}>
              {data?.label}
            </Typography>
            <Typography fontSize="20px" color={textGray} sx={{ height: "100%" }}>
              {data?.name }
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
