import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { GetMainCategoriesRequest, updateMainCategoryRequest, GetMainCategoryDetailsRequest } from "services/modules/lookups";
import MainCategoriesTemplate from "templates/mainCategories";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { formatDate } from "common/utils/helpers";

function MainCategoriesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.lookup.MainCategories);
  const count = useSelector((state) => state.lookup.count);
  const details = useSelector((state) => state.lookup.MainCategory);
  const load = useSelector((state) => state.lookup.isloading);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(GetMainCategoryDetailsRequest({ id }));
  };

  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: page,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetMainCategoriesRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      id: <span onClick={() => void 0}>{data?.id}</span>,
      created_at: <>{formatDate(data?.created_at)}</>,
      updated_at: <>{formatDate(data?.updated_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/categories",
      title: "Lookups / Categories",
    },
    {
      active: !type ? false : true,
      path: "/categories/maincategories",
      title: "Main categories",
    },
    type && {
      active: false,
      title: type === "details" ? "Details" : id ? "Edit Main Category" : "New Main Category",
    },
  ];
  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/categories/maincategories");
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(`Main Category has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: {
        main_category: data,
      },
      action,
    };

    if (id) {
      //update call
      dispatch(updateMainCategoryRequest({ ...requestData, id }));
    } else {
      //create call
      //   dispatch(createTagRequest(requestData));
    }
  };

  const filterList = [];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const id = data?.id?.props?.children;
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${id}`);
        },
        text: "Edit",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading && load?.type === "main categories"} />
      <MainCategoriesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        // handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default MainCategoriesPage;
