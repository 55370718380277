import CCrud from "components/Molecules/Crud";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import ExpertDetailsPage from "pages/experts/expert-details/ExpertDetails";

const ExpertsListTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "Experts list",
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              { accessor: "fullname", Header: "Expert name", sort: true },
              { accessor: "username", Header: "Username", sort: true },
              { accessor: "categories", Header: "Category", sort: true },
              { accessor: "is_verified", Header: "Is verified", sort: true },
              { accessor: "status", Header: "Status", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search expert name/username",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList,
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          DetailsComponent={<ExpertDetailsPage details={details} />}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ExpertsListTemplate;
