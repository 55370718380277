import { icons, images } from 'assets/AssetHelper'
import { formatDate } from 'common/utils/helpers';
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Images',
        icon: icons.imageIcon,
        images:data?.media
    },
    {
        head: 'General details ',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Equipment name',
                name: data?.name,
            },
            {
                label: 'Expert name',
                name: data?.expert?.fullname,
            }, 
            {
                label: 'Price for rent',
                name: data?.rental_price,
            },
            {
                label: 'Price for buy ',
                name: data?.purchase_price,
            },
            {
                label: 'Description',
                name: data?.description,
            },

            
        ]
    },
    {
        head: 'Dates',
        icon: icons.dates,
        details: [
            {
                label: 'Created At',
                name: formatDate(data?.created_at),
            },
            {
                label: 'Updated At',
                name: formatDate(data?.updated_at),
            },
        ]
    },
];


