import Types from "./Types";

export const GetVerificationRequestsSuccess = (payload) => ({
  type: Types.GET_VERIFICATION_REQUESTS_SUCCESS,
  payload,
});

export const GetVerificationRequestDetailsSuccess = (payload) => ({
  type: Types.GET_VERIFICATION_REQUEST_DETAILS_SUCCESS,
  payload,
});

export const UpdateVerificationRequestSuccess = (payload) => ({
  type: Types.UPDATE_VERIFICATION_REQUEST_SUCCESS,
  payload,
});

export const VerificationRequestsLoading = (payload) => ({
  type: Types.VERIFICATION_REQUESTS_LOADING,
  payload,
});
