import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ReactDOMServer from 'react-dom/server';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
import quillEmoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import CFormErrors from 'components/Atoms/SharedComponents/Forms/main/components/CFormErrors';
import { Box, Stack,Icon  } from '@mui/material';
const { ToolbarEmoji } = quillEmoji;

Quill.register(
  {
    'modules/emoji-toolbar': ToolbarEmoji,
  },
  true
);
const icons = Quill.import('ui/icons');
icons['undo'] = `<div style="font-size: 20px;">&#8630;</div>`;
icons['redo'] = `<div style="font-size: 20px;">&#8631;</div>`;

class QuillTextEditor extends Component {
  constructor(props) {
    super(props);

    this.modules = {
      history: {
        delay: 1000,
        maxStack: 1000,
        userOnly: false,
      },
      toolbar: {
        container: [
      [{ 'header': [] },{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['emoji'],
      ['link', 'image'],
      // ['clean'],
      ['undo'],
      ['redo'],
        ],
        handlers: {
          undo: this.myUndo,
          redo: this.myRedo,
        },
      },
      'emoji-toolbar': true,

    };

    this.formats = [
      'font',
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'code-block',
      'color',
      'background',
      'list',
      'indent',
      'align',
      'link',
      'image',
      // 'clean',
      'emoji',
      'undo',
      'redo',
    ];
  }

  myUndo = () => {
    console.log(this.reactQuillRef.getEditor().history.undo);

    let myEditor = this.reactQuillRef.getEditor();
    return myEditor.history.undo();
  };

  myRedo = () => {
    let myEditor = this.reactQuillRef.getEditor();
    return myEditor.history.redo();
  };
  
  render() {
    return (
      <Stack>
        <Box sx={{ paddingBottom: 12 }} className="editor-container">
          <ReactQuill
            theme="snow"
            className="custom-quill-editor"
            modules={this.modules}
            formats={this.formats}
            onChange={this.props.changeAction}
            value={this.props.formik?.values[this.props.name]}
            ref={(el) => {
              this.reactQuillRef = el;
            }}
          />
        </Box>
        <CFormErrors errorsMsg={this.props.formik?.errors[this.props.name]} />
      </Stack>
    );
  }
}

export default QuillTextEditor;
