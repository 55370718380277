import React, { useEffect } from "react";
import { GetCategoriesRequest } from "services/modules/users/users";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Box, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";

export default function Categroies({ id, classification }) {
  const categories = useSelector((state) => state.users.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    const requestData = {
      id,
      params: {
        classification,
        include: "sub_categories",
      },
    };
    dispatch(GetCategoriesRequest(requestData));
  }, [id]);

  return (
    <Stack bgcolor="#fff" sx={{ p: 3, gap: 3 }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Box component="img" src={icons.categories} sx={{ width: "48px", height: "48px" }} />
        <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>Categories</Typography>
      </Stack>
      <Stack gap={2}>
        {categories?.map((category) => (
          <React.Fragment key={category.id}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              {category.id}. {category.name}
            </Typography>
            <Stack direction="row" gap={2}>
              {category?.sub_categories?.map((subCategory) => (
                <Typography
                  key={subCategory.id}
                  sx={{
                    bgcolor: "#EFEFEF",
                    color: "#1A1A1A",
                    px: 2,
                    py: 1,
                    borderRadius: "25px",
                    fontWeight: 500,
                  }}
                >
                  {subCategory?.name}
                </Typography>
              ))}
            </Stack>
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
}
