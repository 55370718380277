import { makeFilterString } from "../../utils/Helper";
import { ReportedChatsLoading, GetReportedChatsSuccess, DeleteReportedChatSuccess } from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const GetReportedChatsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: ReportedChatsLoading,
    successFunction: GetReportedChatsSuccess,
    url: `/admin/chat_rooms/reports${makeFilterString(variables?.params)}`, //removed for now , there is some error (pagination)
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteReportedChatRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "delete",
    isLoading: ReportedChatsLoading,
    successFunction: DeleteReportedChatSuccess,
    url: `/admin/chat_rooms/${variables?.id}`, //removed for now , there is some error (pagination)
    method: "delete",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
