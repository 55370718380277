const authTypes = {
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  OTPREQUEST_SUCCESS: "OTPREQUEST_SUCCESS",
  OTPVERIFY_SUCCESS: "OTPVERIFY_SUCCESS",
  RESETPASSWORD_SUCCESS: "RESETPASSWORD_SUCCESS",

  GET_USER_PROFILE: "GET_USER_PROFILE",
  AUTH_LOADING: "AUTH_LOADING",
};

export default authTypes;
