import { Button, Divider, Input, Modal, Stack, Typography, Box } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch, useSelector } from "react-redux";
import { ToggleRefundModal } from "services/modules/modal/Actions";
import CloseIcon from "@mui/icons-material/Close";

export default function RefundAndCompensationModal({
  journey,
  onConfirm,
  onCancel,
  totalAmount,
  currency,
  amounts,
  onChange,
  error,
  setError,
}) {
  const dispatch = useDispatch();
  const { vivideOrange } = useThemePalette();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    pb: 4,
    outline: "none",
  };
  const { isOpen, type } = useSelector((state) => state.modal.refundModal);
  const modalTitle = type == "refund/compensation" ? "Refund / Compensation" : type == "refund" ? "Refund to adventurer" : "";

  const handleConfirm = () => {
    onConfirm && onConfirm(); // will open the confirmation modal
  };
  const handleClose = () => {
    onCancel && onCancel();
    dispatch(ToggleRefundModal());
  };

  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Stack sx={style}>
        <Stack alignItems="center" direction="row" sx={{ p: 3 }} justifyContent="space-between">
          <Typography
            component="h1"
            style={{
              fontWeight: 600,
              fontSize: "32px",
              textTransform: "capitalize",
            }}
          >
            {modalTitle}
          </Typography>
          <CloseIcon onClick={handleClose} fontSize="large" sx={{ cursor: "pointer" }} />
        </Stack>
        <Divider sx={{ border: "1px solid #EAEAEA" }} />
        {/* journey  details */}
        <Stack gap={1} p={3}>
          <Stack direction="row" gap={1}>
            <Box component="img" width="64px" height="64px" src={journey?.photos?.[0]?.url} />
            <Stack sx={{ width: "85%" }}>
              <Typography sx={{ fontSize: "18px", fontWeight: 500, wordBreak: "break-word" }}>{journey?.name}</Typography>
              <Stack sx={{ mt: "auto" }} direction="row" alignItems="center" justifyContent="space-between">
                <Typography sx={{ color: "#000000", fontSize: "24px", fontWeight: 700 }}>
                  {totalAmount} {currency}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {type == "refund/compensation" && (
          <Stack sx={{ px: 3 }} gap={4}>
            <Stack>
              <Typography sx={{ fontWeight: 400, fontSize: "18px", color: "#565656" }}>
                Enter compensation amount to expert
              </Typography>
              <Stack direction="row" alignItems="center">
                <Input
                  sx={{ py: 2, width: "90%", backgroundColor: "#FBFBFB", pl: 1 }}
                  type="number"
                  inputProps={{ min: 0, inputMode: "none" }}
                  disableUnderline={true}
                  value={amounts?.expert}
                  onChange={onChange}
                  name="expert"
                />
                <Typography sx={{ fontSize: "20px", fontFamily: "Jost", p: 2 }}>
                  {currency}
                </Typography>
              </Stack>
              <Typography sx={{ color: "red" }}>{error.expert}</Typography>
            </Stack>
          </Stack>
        )}
        <Stack sx={{ px: 3 }} gap={4}>
          <Stack>
            <Typography sx={{ fontWeight: 400, fontSize: "18px", color: "#565656" }}>
              Enter refunded amount to adventurer
            </Typography>
            <Stack direction="row" alignItems="center">
              <Input
                sx={{ py: 2, width: "90%", backgroundColor: "#FBFBFB", pl: 1 }}
                type="number"
                inputProps={{ min: 0, inputMode: "none" }}
                disableUnderline={true}
                value={amounts?.adventurer}
                onChange={onChange}
                name="adventurer"
              />
              <Typography sx={{ fontSize: "20px", fontFamily: "Jost", p: 2 }}>
                {currency}
              </Typography>
            </Stack>
            <Typography sx={{ color: "red" }}>{error.adventurer}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ border: "1px solid #EAEAEA" }} />
        <Stack alignItems="center" mt={4}>
          <Stack direction="row" gap={2}>
            <Button
              sx={{
                color: "black",
                border: "1px solid #000000",
                py: 2,
              }}
              className="r-25px"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: vivideOrange,
                "&:hover": {
                  backgroundColor: vivideOrange,
                },
                color: "white",
                textTransform: "capitalize",
              }}
              className="primary r-25px"
              onClick={handleConfirm}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
