import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";

import { toast } from "react-toastify";
import { GetUsersRequest, UpdateUserStatusRequest, GetUserDetailsRequest } from "services/modules/users/users";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import { ToggleConfirmationModal } from "services/modules/modal/Actions";
import { useThemePalette } from "common/hooks/theme_palette";
import ExpertsListTemplate from "templates/experts/experts-list";
import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import { Typography } from "@mui/material";

function ExpertsListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { green, red } = useThemePalette();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const journeyId = searchParams.get("journeyid");

  const list = useSelector((state) => state.users.users);
  const count = useSelector((state) => state.users.count);
  const details = useSelector((state) => state.users.user);
  const load = useSelector((state) => state.users.isloading);

  const [actionToPerform, setActionToPerform] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        classification: "expert",
        include: "expert_main_categories",
        ...filterObj,
      },
    };
    dispatch(GetUsersRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    const status = data?.status;
    const profileStatus = data?.profile_status;
    return {
      ...data,
      is_verified: <>{data?.account_verification?.verified ? "yes" : "no"}</>,
      status: <ColoredText color={status == "active" ? "green" : "red"}>{status}</ColoredText>,
      categories: (
        <>
          {data?.expert_main_categories?.map((c, i) => (
            <Typography sx={{ display: "inline-block" }}>
              {c.name} {i !== data?.expert_main_categories.length - 1 && ","}{" "}
            </Typography>
          ))}
        </>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/experts",
      title: "Experts/Experts list",
    },
    id &&
      !journeyId && {
        active: false,
        title: "Expert details",
      },
    journeyId && { active: true, title: "Expert details", path: `/experts?type=details&id=${id}` },
    journeyId && { active: false, title: "Participating journey", path: `` },
  ];

  const filterList = [
    {
      type: "SearchableSelect",
      placeholder: "Category",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, main_category_id: value?.value };
        });
      },
      options: [
        { id: "1", name: "Land" },
        { id: "2", name: "Air" },
        { id: "3", name: "Water" },
      ],
    },
    {
      type: "SearchableSelect",
      placeholder: "Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value };
        });
      },
      options: [
        { id: "active", name: "Active" },
        { id: "deleted", name: "Deleted" },
        { id: "suspended", name: "Suspended" },
      ],
    },
  ];

  const getDetails = async () => {
    const requestData = { id, params: { include: "wallet" } };
    dispatch(GetUserDetailsRequest(requestData));
  };

  // details of a Country
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const handleUpdateUser = () => {
    const id = selectedRow?.rowData?.id;
    const action = () => {
      toast.success(`Account has been ${actionToPerform == "activate" ? "activated" : "suspended"} successfully`);
    };
    const requestData = {
      body: {
        user: {
          status: actionToPerform == "activate" ? "active" : "suspended",
        },
      },
      action,
      id,
    };
    dispatch(UpdateUserStatusRequest(requestData));
  };

  const handleToggleConfirmationModal = (status) => {
    dispatch(ToggleConfirmationModal());
    if (status == "active") {
      setActionToPerform("suspend");
    } else if (status == "suspended") {
      setActionToPerform("activate");
    }
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const status = data.status.props.children;
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return status === "active";
        },
        click: () => {
          handleToggleConfirmationModal(status);
        },
        text: "suspend",
      },
      {
        condition: () => {
          return status === "suspended";
        },
        click: () => {
          handleToggleConfirmationModal(status);
        },
        text: "Activate",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  const confirmationModal = () => {
    return (
      <ConfirmationModal
        text={[`${actionToPerform} Account`, `Are you sure you want to ${actionToPerform} this account?`]}
        onConfirm={handleUpdateUser}
        confirmBtnTitle={actionToPerform}
        mainColor={actionToPerform == "activate" ? green : red}
      />
    );
  };

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [confirmationModal];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <ExpertsListTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default ExpertsListPage;
