import { Stack, Typography } from "@mui/material";
import { formatDate } from "common/utils/helpers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export default function ColumnDetails({ isShowDetails, details, currency, section, x }) {
  const interval_type = useSelector((state) => state.statistics.intervalType);
  const interval = interval_type == "weekly" ? `${details?.from} - ${details?.to}` : formatDate(x, "DD MMM YYYY");

  return (
    <Stack
      sx={{
        bgcolor: "#fff",
        boxShadow: "0px 2.556px 5.111px 0px rgba(0, 0, 0, 0.04)",
        p: 1,
        visibility: isShowDetails ? "visible" : "hidden",
        borderRadius: "8px",
        position: "absolute",
        bottom: "100%",
        minWidth: interval_type == "weekly" || section == "Total Earning" ? "210px" : "80px",
      }}
    >
      <Typography sx={{ fontSize: "12px" }}>{interval} </Typography>
      <Typography sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}>
        {details?.amount} {section == "Total Earning" && currency}
      </Typography>
    </Stack>
  );
}
