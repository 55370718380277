import { Box, Button, IconButton, InputBase, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import Search from "components/Atoms/Input/Search/Search";
import { useThemePalette } from "common/hooks/theme_palette";
import Filter from "components/Molecules/Filters/filter";

const Header = ({
  Title,
  isSearch,
  setFilterObj,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  handlePageChange,
  searchInputPlaceholder,
  children,
  filterList,
  handleApplyFilter,
}) => {
  const [value, setValue] = useState(null);
  const { oliveGreen } = useThemePalette();
  const [searchEmail, setSearchEmail] = useState("");

  useEffect(() => {
    const timeId = setTimeout(() => {
      setFilterObj((data) => {
        return {
          ...data,
          keyword: value,
        };
      });
      handlePageChange(1);
    }, 500);
    return () => clearTimeout(timeId);
  }, [value]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
      <Stack width="50%">
        <Typography fontSize="32px" fontWeight="700">
          {Title}
        </Typography>
      </Stack>

      <Stack direction="row" width="50%" gap={2} alignSelf="end" justifyContent="end">
        {filterList ? <Filter filterList={filterList} handleApplyFilter={handleApplyFilter} /> : null}
        <>
          {isSearch && (
            <Search
              placeholder={searchInputPlaceholder}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onClear={() => setValue("")}
            />
          )}
        </>
        {children}
        {createBtnTitle && (
          <Button
            onClick={createBtnFun}
            variant="contained"
            sx={{
              px: 8,
              py: 1.8,
              textTransform: "none",
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
              backgroundColor: "#FF7900",
              "&:hover": {
                backgroundColor: "#FF7900",
              },
            }}
          >
            {CreateBtnIcon && <CreateBtnIcon sx={{ position: "absolute", left: "20px" }} />}

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              {createBtnTitle}
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;

// <Stack flexDirection="row" bgcolor="white" borderRadius="10px">
// <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
//   <SearchIcon />
// </IconButton>
// <InputBase
//   sx={{ ml: 1, width: "100%" }}
//   placeholder={searchInputPlaceholder}
//   type={"text"}
//   size="small"
// onChange={(e) => {
//   setValue(e.target.value);
// }}
// />
// </Stack>
