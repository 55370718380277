/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  Posts: [],
  Post: {},
  ReportedPosts: [],
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function PostsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_POSTS_SUCCESS: {
      return {
        ...state,
        Posts: payload?.data?.posts,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_POST_DETAIL_SUCCESS: {
      return {
        ...state,
        Post: payload?.data?.post,
      };
    }

    case Types.GET_REPORTED_POSTS_SUCCESS: {
      return {
        ...state,
        ReportedPosts: payload?.data?.post_reports,
        count: payload?.extra?.total_count,
      };
    }

    case Types.POSTS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
