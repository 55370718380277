import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";

import {  GetUsersRequest, UpdateUserStatusRequest } from "services/modules/users/users";
import { ToggleConfirmationModal } from "services/modules/modal/Actions";
import AdventurerTemplate from "templates/adventurer";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import { useThemePalette } from "common/hooks/theme_palette";
import { formatDate } from "common/utils/helpers";

function AdventurerPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { green, red } = useThemePalette();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const journeyId = searchParams.get("journeyid");

  const list = useSelector((state) => state.users.users);
  const count = useSelector((state) => state.users.count);
  const load = useSelector((state) => state.users.isloading);

  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [actionToPerform, setActionToPerform] = useState(""); //activating or suspending user

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: page,
        keyword: "",
        sort_by: "id",
        classification: "adventurer",
        ...filterObj,
      },
    };
    dispatch(GetUsersRequest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    const status = data?.status;
    const profileStatus = data?.profile_status;
    return {
      ...data,
      status: <ColoredText color={status == "active" ? "green" : "red"}>{status}</ColoredText>,
      profile_status: <ColoredText color={profileStatus == "complete" ? "green" : "yellow"}>{profileStatus}</ColoredText>,
      created_at: <>{formatDate(data?.created_at)}</>,
      updated_at: <>{formatDate(data?.updated_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/adventurer",
      title: "Adventurer",
    },
    id &&
      !journeyId && {
        active: false,
        title: "Adventurer details",
      },
    journeyId && { active: true, title: "Adventurer details", path: `/adventurer?type=details&id=${id}` },
    journeyId && { active: false, title: "Participating journey", path: `` },
  ];

  const filterList = [];

  const handleUpdateUser = () => {
    const id = selectedRow?.rowData?.id;
    const action = () => {
      toast.success(`Account has been ${actionToPerform == "activate" ? "activated" : "suspended"} successfully`);
    };
    const requestData = {
      body: {
        user: {
          status: actionToPerform == "activate" ? "active" : "suspended",
        },
      },
      action,
      id,
    };
    dispatch(UpdateUserStatusRequest(requestData));
  };

  const handleToggleConfirmationModal = (status) => {
    dispatch(ToggleConfirmationModal());
    if (status == "active") {
      setActionToPerform("suspend");
    } else if (status == "suspended") {
      setActionToPerform("activate");
    }
  };

  const actionsList = ({ index, data }) => {
    const status = data.status.props.children;
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}&tab=0`);
        },
        text: "View",
      },
      {
        condition: () => {
          return status === "active";
        },
        click: () => {
          handleToggleConfirmationModal(status);
        },
        text: "suspend",
      },
      {
        condition: () => {
          return status === "suspended";
        },
        click: () => {
          handleToggleConfirmationModal(status);
        },
        text: "Activate",
      },
    ];
  };

  const headerChildren = <></>;

  const confirmationModal = () => {
    return (
      <ConfirmationModal
        text={[`${actionToPerform} Account`, `Are you sure you want to ${actionToPerform} this account?`]}
        onConfirm={handleUpdateUser}
        confirmBtnTitle={actionToPerform}
        mainColor={actionToPerform == "activate" ? green : red}
      />
    );
  };

  const modalComponents = [confirmationModal];

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <AdventurerTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default AdventurerPage;
