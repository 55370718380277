import { icons } from 'assets/AssetHelper'
import { formatDate } from 'common/utils/helpers';
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Sub category name (EN)',
                name: data?.localized_attributes?.en?.name,
            },
            {
                label: 'sub category name (AR)',
                name: data?.localized_attributes?.ar?.name,
            },
            {
                label: 'Main category',
                name: data?.main_category?.name,

            }
        ]
    },
    {
        head: 'Dates',
        icon: icons.dates,
        details: [
            {
                label: 'Created At',
                name: formatDate(data?.created_at),
            },
            {
                label: 'Updated At',
                name: formatDate(data?.updated_at),
            },
        ]
    },
];


