import { reduxRequest } from "common/utils/reduxRequest";
import { makeFilterString } from "../../utils/Helper";
import { GetNotificationsSuccess, NotificationsLoading, SetFCMTokenSucces } from "./Actions";

export const GetNotificationsReuqest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: NotificationsLoading,
    successFunction: GetNotificationsSuccess,
    url: `/admin/notifications?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const SetFCMTokenReuqest = ({ ...variables }) => {
  const requestData = {
    loadingType: "set fcm token",
    isLoading: NotificationsLoading,
    successFunction: SetFCMTokenSucces,
    url: `/auth/set_fcm_token`,
    method: "patch",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const CreateFirebaseAuthToken = ({ ...variables }) => {
  const requestData = {
    loadingType: "firebase token",
    isLoading: NotificationsLoading,
    url: `/auth/tokens/firebase`,
    action: variables?.action,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
