import {
  AuthLoading,
  GetUserProfileSuccess,
  LogoutSuccess,
  OTPRequestSuccess,
  OTPVerifySuccess,
  ResetPasswordSuccess,
} from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const loginRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "login",
    isLoading: AuthLoading,
    url: `/auth/admin/login`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const LogoutRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "logout",
    isLoading: AuthLoading,
    successFunction: LogoutSuccess,
    url: `/auth/logout`,
    action: variables?.action,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const OTPRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "OTP request",
    isLoading: AuthLoading,
    successFunction: OTPRequestSuccess,
    url: `/auth/passwords/admin/send_reset_password_info`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const OTPVerify = ({ ...variables }) => {
  const requestData = {
    loadingType: "OTP verify",
    isLoading: AuthLoading,
    successFunction: OTPVerifySuccess,
    url: `/auth/passwords/admin/verify_otp`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const resetPasswordRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "reset password",
    isLoading: AuthLoading,
    successFunction: ResetPasswordSuccess,
    url: `/auth/passwords/admin/reset_password`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetUserProfileRequest = () => {
  const requestData = {
    loadingType: "get profile",
    isLoading: AuthLoading,
    successFunction: GetUserProfileSuccess,
    url: `/admin/users/profile`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
