import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import { GetJourneysRequest } from "services/modules/users/users";
import ParticipatingJourneysTemplate from "templates/participating-journeys/ParticipatingJourneys";
import { NotificationsOutlined } from "@mui/icons-material";

function ParticipatingJourneysPage({ id, as_expert = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const list = useSelector((state) => state.users.journeys);
  const count = useSelector((state) => state.users.count);
  const load = useSelector((state) => state.users.isloading);

  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: page,
        keyword: "",
        sort_by: "id",
        user_id: id,
        ...filterObj,
      },
    };
    dispatch(GetJourneysRequest(requestData));
  };
  console.log("canceled by adventurer".includes("canceled"));
  const journeyStatuses = {
    canceled_by_user: "canceled by adventurer",
    canceled_by_expert: "canceled by Expert",
    upcoming: "upcoming",
    inprogress: "inprogress",
    finished: "finished",
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    const compounded_status = data?.compounded_status;
    const status = journeyStatuses[compounded_status];
    return {
      ...data,
      id: <span onClick={() => navigate(`?type=details&id=${id}&journeyid=${data?.id}`)}>{data?.id}</span>,
      compounded_status: (
        <ColoredText
          color={
            status == "finished"
              ? "green"
              : status?.includes("canceled")
              ? "red"
              : compounded_status === "upcoming"
              ? "blue"
              : "yellow"
          }
        >
          {status}
        </ColoredText>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/adventurer",
      title: "Adventurer",
    },
    id && {
      active: false,
      title: "Adventurer details",
    },
  ];

  const filterList = [
    {
      type: "SearchableSelect",
      placeholder: "Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, compounded_status: value?.value };
        });
      },
      options: [
        { id: "canceled_by_expert", name: "Canceled by expert" },
        { id: "canceled_by_user", name: "Canceled by adventurer" },
        { id: "upcoming", name: "Upcoming" },
        { id: "finished", name: "Finished" },
        { id: "inprogress", name: "Inprogress" },
      ],
    },
  ];

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          // keeping the adventurer id in case the user presses back
          // {data?.id?.props?.children} is because we modified the id in the mapped data
          navigate(`?type=details&id=${id}&journeyid=${data?.id?.props?.children}`);
        },
        text: "View",
      },
    ];
  };

  const headerChildren = <></>;

  const modalComponents = [];

  useEffect(() => {
    getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading} />
      <ParticipatingJourneysTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default ParticipatingJourneysPage;
