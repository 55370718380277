import { Stack } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { formatDate } from "common/utils/helpers";
import TextWithIcon from "components/Atoms/Text/TextWithIcon";
import BookingDetailsHeader from "components/Molecules/Booking/BookingDetailsHeader";
import Equipment from "components/Molecules/Booking/Equipment";
import MainInfo from "components/Molecules/Booking/MainInfo";
import Participants from "components/Molecules/Booking/Participants";
import PaymentDetails from "components/Molecules/Booking/PaymentDetails";
import RefundTransactions from "components/Molecules/Booking/RefundTransactions";
import dayjs from "dayjs";

export default function BookingDetailsTemplate({ details, joruneyStatuses }) {
  const isEquipment = details?.reservation_equipment?.length > 0;
  const isParticipants = details?.participants?.length > 0;

  return (
    <Stack gap={3}>
      <BookingDetailsHeader title="Booking details" joruneyStatuses={joruneyStatuses} details={details} />

      <Stack sx={{ bgcolor: "#fff", p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ width: "85%" }}>
          <TextWithIcon icon={icons.Hash} label="Booking Number:" text={details?.reservation_number} />
          <TextWithIcon icon={icons.Calender} label="Booking Date:" text={formatDate(details?.created_at)} />
        </Stack>
      </Stack>

      <RefundTransactions details={details} />

      <Stack direction="row" gap={3}>
        <MainInfo details={details} />
        <PaymentDetails details={details} />
      </Stack>

      {isEquipment && <Equipment details={details} />}
      {isParticipants && <Participants details={details} />}
    </Stack>
  );
}
