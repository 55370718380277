import { makeFilterString } from "../../../utils/Helper";
import { GetReviewsDetailsSuccess, GetReviewsSuccess, ReviewsLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const GetReviewsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: ReviewsLoading,
    successFunction: GetReviewsSuccess,
    url: `/admin/reviews${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetReviewsDetailsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: ReviewsLoading,
    successFunction: GetReviewsDetailsSuccess,
    url: `/admin/reviews/analytics${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
