import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import { detailsData } from "templates/journey-details/details";

export default function JourneyDetailsTemplate({ details }) {
  return (
    <DetailsUse
      data={{
        deleteBtn: false,
        editBtn: false,
        data: {
          //as we have conditional rendering inside the detailsData fn.
          // [item, false, item, item] could occure, so we use filter
          //to remove the false values
          list: detailsData({ data: details }).filter((item) => item),
        },
      }}
    />
  );
}
