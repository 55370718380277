import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ColumnDetails from "./ColumnDetails";
import { formatCompactNumber } from "common/utils/helpers";

export default function Graph({ section }) {
  const chartRef = useRef();
  const { vivideOrange } = useThemePalette();

  const [chartHeight, setChartHeight] = useState(0);
  const [hoveredColIndex, setHoveredColIndex] = useState(null);

  const interval_type = useSelector((state) => state.statistics.intervalType);
  const intervalPeriod = useSelector((state) => state.statistics.intervalPeriod);

  const yAxis = section?.report?.y_axis ? [0, ...section?.report?.y_axis] : [];
  const xAxis = section?.report?.x_axis;
  const currency = section?.report?.amount_currency;
  const columnsDetails = section?.report?.columns_details;

  const maxValue = yAxis[yAxis?.length - 1];
  const factor = chartHeight / maxValue;

  const intervals = {
    daily: "Days",
    weekly: "Weeks",
    monthly: "Months",
    yearly: "Years",
  };

  const calculateColumnHeight = (value = 1) => {
    let height;
    if (value == 0) {
      height = 0;
    } else if (value > maxValue) {
      //-7 to equalize the extra padding added from the bottom
      height = maxValue * factor - 7;
    } else {
      height = value * factor - 7;
      if (height < 1) {
        // set a minimum value so that the column will still be visible
        // in case value of x is too small
        height = 1;
      }
    }
    return `${height}px`;
  };

  const calculateChartHeight = () => {
    setChartHeight(chartRef.current.offsetHeight);
  };

  useEffect(() => {
    calculateChartHeight();
  }, []);

  return (
    <Stack sx={{ bgcolor: "#fff", p: 5, borderRadius: "8px", gap: 8, pb: 10 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ color: "#1C2A53", fontSize: "20px", fontWeight: "500" }}>{section?.name}</Typography>
        <Typography sx={{ color: "#686868", fontSize: "20px", fontWeight: "400" }}>
          Last {intervalPeriod} {intervals[interval_type]}
        </Typography>
      </Stack>
      {/* chart */}
      <Stack>
        <Stack direction="row" sx={{ position: "relative", minHeight: 300 }} ref={chartRef}>
          {/* yaxis start */}
          <Stack
            sx={{ textAlign: "end", width: "fit-content", mb: -1, mt: -1 }}
            justifyContent="space-between"
            direction="column-reverse"
          >
            {yAxis?.map((y, i) => (
              <Typography key={i} sx={{ ml: currency && -2, color: "#8E95A9", fontWeight: 500, fontSize: "14px" }}>
                {currency} {formatCompactNumber(y)}
              </Typography>
            ))}
          </Stack>
          {/* yaxis end */}

          {/* horizontal lines start */}
          <Stack
            sx={{
              borderLeft: "1px solid #F4F4F4",
              position: "absolute",
              width: "92%",
              height: "100%",
              left: 60,
            }}
            justifyContent="space-between"
          >
            {yAxis.map((val, i) => (
              <Stack key={i} sx={{ border: "1px solid #F4F4F4" }} />
            ))}
          </Stack>
          {/* horizontal lines end */}

          {/* x-axis start */}
          <Stack direction="row" justifyContent="space-between" alignItems="end" sx={{ width: "85%", mx: "auto", zIndex: 1 }}>
            {xAxis?.map((x, i) => (
              <Stack alignItems="center" justifyContent="end" gap={1} key={i} sx={{ mb: -4, position: "relative" }}>
                <ColumnDetails
                  isShowDetails={hoveredColIndex == i}
                  details={columnsDetails[x]}
                  x={x}
                  currency={currency}
                  section={section?.name}
                />
                <Stack
                  sx={{
                    borderRadius: "40px",
                    width: "40px",
                    bgcolor: hoveredColIndex == i ? "#FFF8F2" : "transparent",
                    py: 1,
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack
                    sx={{
                      width: "8px",
                      height: (_) => calculateColumnHeight(section?.report?.graph_points[x]),
                      bgcolor: vivideOrange,
                      borderRadius: "5px",
                      cursor: "pointer",
                      transition: "1s",
                    }}
                    onMouseOver={() => setHoveredColIndex(i)}
                    onMouseOut={() => setHoveredColIndex(null)}
                  />
                </Stack>
                <Typography sx={{ color: "#8E95A9" }}>{x}</Typography>
              </Stack>
            ))}
          </Stack>
          {/* x-axis end */}
        </Stack>
      </Stack>
    </Stack>
  );
}
