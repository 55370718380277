/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  TermsAndConditions: [],
  PrivacyPolicy:[],
  CancellationPolicy:[],
  document: {},
  count: 0,
  isloading: {loading:null,loadingType:""},
  error: null,
};

export default function documentsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_TERMS_AND_CONDTIONS_SUCCESS: {
      return {
        ...state,
        TermsAndConditions: payload?.data?.documents,
        // count: payload?.documents.length, //not implemented in BE
      };
    }

    case Types.GET_PRIVACY_POLICY_SUCCESS: {
      return {
        ...state,
        PrivacyPolicy: [payload?.data?.document],
      };
    }

    case Types.GET_CANCELLATION_POLICY_SUCCESS: {
      return {
        ...state,
        CancellationPolicy: [payload?.data?.document],
      };
    }



    case Types.GET_DOCUMENT_DETAIL: {
      return {
        ...state,
        document: payload?.data?.document
      };
    }

    case Types.DOCUMENTS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
