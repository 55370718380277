import { useSearchParams } from "react-router-dom";
import { useState } from "react";

import PostDetailsTemplate from "templates/community/PostDetails";
import PostDetails from "components/Organisms/community/details";
import HiddenCommentsPage from "pages/hiddenComments/HiddenComments";

export default function PostDetailsPage({ details }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");
  const postId = searchParams.get("id");

  const [value, setValue] = useState(() => {
    return selectedTab ? parseInt(selectedTab) : 0;
  });

  const tabs = [
    { title: "Details", children: <PostDetails details={details} /> },
    {
      title: "Requested to hide comments",
      children: <HiddenCommentsPage id={postId} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
    setValue(newValue);
  };

  return <PostDetailsTemplate details={details} selectedTab={value} tabs={tabs} handleTabChange={handleTabChange} />;
}
