/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  contacts: [],
  contact: {},
  count: 0,
  isLoading: { loading: null, type: "" },
  error: null,
};

export default function contactsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CONTACTS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.GET_CONTACTS_SUCCESS: {
      return {
        ...state,
        contacts: payload?.data?.contacts,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_CONTACT_DETAILS: {
      return {
        ...state,
        contact: payload?.data?.contact,
      };
    }

    default: {
      return state;
    }
  }
}
