import { Box, Button, Rating, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useThemePalette } from "common/hooks/theme_palette";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { formatDate } from "common/utils/helpers";

export default function ReviewsCard({ review }) {
  const { vivideOrange, gray5 } = useThemePalette();
  const navigate = useNavigate();
  const reviewer = review?.reviewer;

  return (
    <Stack gap={2} sx={{ borderRadius: "8px", boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.20)", p: 2 }}>
      <Stack sx={{}} direction="row" gap={2}>
        <Stack>
          <Box component="img" src={icons.AnonymousUser} width="55px" height="55px" sx={{ borderRadius: "50%" }} />
        </Stack>
        <Stack sx={{ flexGrow: 1 }} gap={2}>
          <Stack sx={{}} direction="row" justifyContent="space-between">
            <Stack gap={1}>
              <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>{reviewer.fullname}</Typography>
              <Rating
                name="read-only"
                value={review?.rating}
                readOnly
                sx={{
                  fontSize: "25px",
                }}
              />
            </Stack>
            <Stack>
              <Typography sx={{ color: gray5, fontSize: "18px" }}>{formatDate(review?.created_at, "DD MMM YYYY")}</Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography sx={{ fontSize: "16px" }}>{review.comment}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{}} direction="row" gap={2}>
        <Stack>
          <Box component="img" src={review?.journey?.photos[0]?.url || icons.AnonymousUser} width="66px" height="66px" />
        </Stack>
        <Stack sx={{ flexGrow: 1 }} justifyContent="space-between">
          <Stack>
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>{review?.journey?.name}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ fontSize: "12px" }}>
              {review?.journey?.city?.name}, {review?.journey?.country?.name}
            </Typography>
            <Button
              sx={{ color: vivideOrange, p: 0, fontSize: "16px" }}
              endIcon={<KeyboardArrowRightIcon />}
              onClick={() => navigate(`/journeys?type=details&id=${review?.journey?.id}`)}
            >
              Journey Details
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
