import { formatDate } from "common/utils/helpers";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetEarningReportsReuqest } from "services/modules/earningReports";
import EarningReportsTemplate from "templates/earningReports";

function EarningReportsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.earningReports.reports);
  const earningsData = useSelector((state) => state.earningReports.data);
  const count = useSelector((state) => state.earningReports.count);
  const load = useSelector((state) => state.earningReports.isloading);

  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(null);

  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetEarningReportsReuqest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      id: <span onClick={() => void 0}>{data?.id}</span>,
      sub_total_amount: (
        <>
          {data?.sub_total_amount} {data?.currency?.lookup_key}
        </>
      ),
      commission: (
        <>
          {data?.system_commission} {data?.currency?.lookup_key}
        </>
      ),
      created_at: <>{formatDate(data?.created_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/reports",
      title: "Reports",
    },
  ];

  const filterList = [
    {
      type: "date",
      label: "From",
      onChange: (value) => {
        const date_from = value ? dayjs(value).format("YYYY-MM-DD") : "";
        setFilterObj((s) => {
          return { ...s, "date[from]": date_from };
        });
      },
    },
    {
      type: "date",
      label: "To",
      onChange: (value) => {
        const date_to = value ? dayjs(value).format("YYYY-MM-DD") : "";
        setFilterObj((s) => {
          return { ...s, "date[to]": date_to };
        });
      },
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <EarningReportsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
        earningsData={earningsData}
      />
    </>
  );
}

export default EarningReportsPage;
