import { Button, Divider, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { loginSchema } from "common/utils/schemas";
import FormUse from "components/Atoms/SharedComponents/CRUD/form-use";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "services/modules/auth";
import setAuthToken from "services/modules/utils/handel_api";
import { SignInFirebase } from "services/utils/Firebase";
import { structure } from "./structure";
import "./style.css";
export default function LoginForm() {
  const { isLoading } = useSelector((state) => state?.auth);
  const id = useId();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orange, vivideOrange } = useThemePalette();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const action = (data) => {
        const token = data?.data?.extra?.access_token;
        const firebaseAuthToken = data?.data?.user?.firebase_auth_token;
        const firebaseUID = data?.data?.user?.firebase_uid;

        Cookies.set("token", token);
        Cookies.set("firebase_auth_token", firebaseAuthToken);
        Cookies.set("firebase_uid", firebaseUID);

        // SignInFirebase(firebaseAuthToken);
        setAuthToken(token);
        navigate("/");
      };

      const requestData = {
        body: {
          user: values,
          device: {
            fcm_token: Cookies.get("fcm_token") ?? id,
            device_type: 1,
          },
        },
        action,
      };
      dispatch(loginRequest(requestData));
      setSubmitting(false);
    },
    validationSchema: loginSchema,
  });

  return (
    <>
      <Loader open={isLoading?.loading} />
      <Typography fontSize="38px" fontWeight="800">
        Welcome Back
      </Typography>
      <Stack sx={{ gap: 1, mt: "20px" }}>
        <Typography fontSize="30px" fontWeight="400">
          Login
        </Typography>
        <Divider sx={{ border: "1px solid #F5F5F5" }} />

        <FormUse formStructure={structure} className="login-form" formik={formik}>
          <Stack mt={3} gap={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography
                sx={{
                  color: orange,
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: "18px",
                  marginLeft: "auto",
                  float: "right",
                }}
                onClick={(_) => navigate("forget-password")}
              >
                Forget Password ?
              </Typography>
            </Stack>
            <Button
              type="submit"
              variant="contained"
              sx={{
                textTransform: "none",
                fontSize: "20px",
                p: 2,
                backgroundColor: vivideOrange,
                "&:hover": {
                  backgroundColor: vivideOrange,
                },
              }}
            >
              Login
            </Button>
          </Stack>
        </FormUse>
      </Stack>
    </>
  );
}
