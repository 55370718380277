import { Stack } from "@mui/material";
import CTable from "../Table/CTable";
import { useState, useEffect } from "react";

function TableUse({
  isChecked = false,
  total_count,
  columnsData = [],
  list = [{ id: 0 }],
  listTitle,
  isSearch,
  searchInputPlaceholder,
  isFilter,
  setFilterObj,
  setTableData,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  filterList,
  extraFilters,
  handleApplyFilter,
  className,
  handlePageChange,
  pageNumber,
  headerChildren,
}) {
  const [checked, setChecked] = useState([false]);

  const viewData = (data) => {
    setTableData((prevData) => {
      return {
        ...prevData,
        pageNumber,
        rowData: data,
        checkedData: checked,
      };
    });
  };

  useEffect(() => {
    setTableData((prevData) => {
      return {
        ...prevData,
        checkedData: checked,
      };
    });
  }, [checked]);

  const columns = columnsData;
  return (
    <Stack>
      <CTable
        className={className}
        selectedData={viewData}
        isFilter={isFilter}
        filterList={filterList}
        extraFilters={extraFilters}
        handleApplyFilter={handleApplyFilter}
        isSearch={isSearch}
        searchInputPlaceholder={searchInputPlaceholder}
        setFilterObj={setFilterObj}
        Data={list}
        Columns={columns}
        Title={listTitle}
        currentpage={pageNumber}
        setPageNumber={handlePageChange}
        totalPage={Math.ceil(total_count / 10)}
        total_count={total_count}
        isChecked={isChecked}
        checked={checked}
        setChecked={setChecked}
        createBtnTitle={createBtnTitle}
        createBtnFun={createBtnFun}
        CreateBtnIcon={CreateBtnIcon}
        handlePageChange={handlePageChange}
        headerChildren={headerChildren}
        paginationPage={pageNumber}
      />
    </Stack>
  );
}
export default TableUse;
