import { Typography, Stack, Box } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import { formatDate } from "common/utils/helpers";
import AvatarCard from "components/Molecules/Avatar/AvatarCard";
import dayjs from "dayjs";
function formatCompactNumber(number) {
  if (number < 0) {
    return "-" + formatCompactNumber(-1 * number);
  }
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
  }
}

export const detailsData = ({ data }) => {
  return [
    {
      head: (
        <Stack gap={3}>
          <Typography sx={{ fontSize: "28px" }}>{data?.content}</Typography>
          <Stack sx={{ bgcolor: "#F9F9F9", p: 2, borderRadius: "12px" }} direction="row" gap={4}>
            <Stack direction="row" gap={1} alignItems="center">
              <Box component="img" src={icons.Like} />
              <Typography>{formatCompactNumber(data?.number_of_likes)}</Typography>
            </Stack>
            <Stack sx={{ height: "25px", border: "1px solid #CBCBCB" }} />
            <Stack direction="row" gap={1} alignItems="center">
              <Box component="img" src={icons.Comment} />
              <Typography>{formatCompactNumber(data?.number_of_comments)}</Typography>
            </Stack>
            <Stack sx={{ height: "25px", border: "1px solid #CBCBCB" }} />
            <Stack direction="row" gap={1} alignItems="center">
              <Box component="img" src={icons.Share} />
              <Typography>{formatCompactNumber(data?.number_of_shares)}</Typography>
            </Stack>
          </Stack>
        </Stack>
      ),
    },
    {
      head: "Media",
      icon: icons?.imageIcon,
      images: data?.media,
    },
    {
      head: "Details",
      icon: icons?.detailsIcon,
      details: [
        {
          label: "Category",
          name: data?.sub_category?.main_category?.name,
        },
        {
          label: "Sub category",
          name: data?.sub_category?.name,
        },
        {
          label: "Expert",
          name: (
            <AvatarCard
              data={data?.expert}
              style={{ avatar: { width: "64px", height: "64px" }, name: { fontSize: "20px" }, content: { fontSize: "16px" } }}
              showWallet={false}
            />
          ),
        },
      ],
    },
    {
      head: "Dates",
      icon: icons?.dates,
      details: [
        {
          label: "Created at",
          name: formatDate(data?.created_at),
        },
        {
          label: "Updated at",
          name: formatDate(data?.updated_at),
        },
      ],
    },
  ];
};
