import { reduxRequest } from "common/utils/reduxRequest";
import { makeFilterString } from "../utils/Helper";
import { EarningReportsLoading, GetEarningReportsSuccess } from "./Actions";

export const GetEarningReportsReuqest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: EarningReportsLoading,
    successFunction: GetEarningReportsSuccess,
    url: `/admin/earning_reports${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
