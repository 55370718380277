import { reduxRequest } from "common/utils/reduxRequest";
import { makeFilterString } from "../utils/Helper";
import { BookingsLoading, GetBookingDetailsSuccess, GetBookingsSuccess, RefundSuccess } from "./Actions";

export const GetBookingsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: BookingsLoading,
    successFunction: GetBookingsSuccess,
    url: `/admin/reservations${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetBookingtDetailsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "details",
    isLoading: BookingsLoading,
    successFunction: GetBookingDetailsSuccess,
    url: `/admin/reservations/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const RefundRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "processing",
    isLoading: BookingsLoading,
    successFunction: RefundSuccess,
    url: `/admin/rectifications`,
    method: "post",
    data: variables?.body,
    action: variables?.action,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
