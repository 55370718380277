/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  notifications: [],
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function SystemNotificationsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_SYSTEM_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notifications: payload?.data?.notifications,
        count: payload?.extra?.total_count,
      };
    }

    case Types.SYSTEM_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
