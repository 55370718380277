import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";

const ReportedExpertsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "Reported experts",
            columns: [
              { accessor: "id", Header: "#", clickable: true },
              { accessor: "reportee.fullname", Header: "Expert name", sort: true },
              { accessor: "reporter.fullname", Header: "Reporter name ", sort: true },
              { accessor: "report_reason.name", Header: "Reason", sort: true },
              { accessor: "created_at", Header: "Date", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search expert name/username",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList,
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ReportedExpertsTemplate;
