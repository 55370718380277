import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Stack } from "@mui/material";
import SearchableSelect from "components/Atoms/Input/Select/SearchableSelect";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import Header from "components/Atoms/SharedComponents/Details/header";
import Charts from "components/Organisms/statistics/Charts";

export default function StatisticsTemplate({ breadCrumbs, intervals, defaultValue, handleIntervalChange, statistics }) {
  return (
    <Stack gap={2}>
      <Breadcrumb data={breadCrumbs} />
      {/* title and filter */}
      <Stack direction="row" alignItems="center">
        <Header title="Dashboard" editBtn={false} deleteBtn={false} />
        <SearchableSelect
          isClearable={false}
          options={intervals}
          onChange={handleIntervalChange}
          defaultValue={defaultValue}
          icon={<CalendarTodayOutlinedIcon />}
        />
      </Stack>
      {/* charts */}
      <Stack>
        <Charts statistics={statistics} />
      </Stack>
    </Stack>
  );
}
