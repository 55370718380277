import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import TransferMoneyRequestModal from "components/Molecules/Models/TransferMoneyRequestModal";
import dayjs from "dayjs";
import { ToggleConfirmationModal, ToggleTransferMoneyRequestModal } from "services/modules/modal/Actions";
import {
  FulfillMoneyTransferRequest,
  GetMoneyTransferRequestsRequest,
} from "services/modules/users/experts/transferMoneyRequests";
import TransferMoneyRequestsTemplate from "templates/transferMoneyRequests";
import { toast } from "react-toastify";
import { formatDate } from "common/utils/helpers";

function TransferMoneyRequestsPage() {
  const dispatch = useDispatch();
  const { vivideOrange } = useThemePalette();

  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const list = useSelector((state) => state.moneyTransferRequestsL.requests);
  const count = useSelector((state) => state.moneyTransferRequestsL.count);
  const load = useSelector((state) => state.moneyTransferRequestsL.isloading);

  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [amountToTransfer, setAmountToTransfer] = useState(0);
  const [amountError, setAmountError] = useState(null);
  //instead of fetching details of a money transfer request
  //destructure it this way because we overrode the id in mapped data
  const details = list?.find((row) => row?.id == selectedRow?.rowData?.id?.props?.children);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetMoneyTransferRequestsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    const status = data?.status;
    return {
      ...data,
      requested_amount: (
        <Typography>
          {data?.requested_amount} {data?.currency?.lookup_key}
        </Typography>
      ),
      id: <React.Fragment onClick={() => void 0}>{data.id}</React.Fragment>,
      requested_at: <>{formatDate(data?.requested_at)}</>,
      fulfilled_at: <>{status === "fulfilled" ? formatDate(data?.fulfilled_at) : "-"}</>,
      status: <ColoredText color={status == "fulfilled" ? "green" : "yellow"}>{status}</ColoredText>,
      transfered_amount: (
        <Typography>
          {status === "fulfilled" ? data?.transfered_amount : "0"} {data?.currency?.lookup_key}
        </Typography>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/experts/transfer-money-requests",
      title: "Experts/Transfer money requests",
    },
  ];

  const filterList = [
    {
      type: "SearchableSelect",
      placeholder: "Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value };
        });
      },
      options: [
        { id: "pending", name: "Pending" },
        { id: "fulfilled", name: "Fulfilled" },
      ],
    },
    {
      type: "date",
      onChange: (value) => {
        const requested_at = value ? dayjs(value).format("YYYY-MM-DD") : "";
        setFilterObj((s) => {
          return { ...s, requested_at };
        });
      },
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const status = data.status.props.children;
    return [
      {
        condition: () => {
          return status !== "fulfilled";
          // return true;
        },
        click: () => {
          dispatch(ToggleTransferMoneyRequestModal());
        },
        text: "Fulfill request",
      },
    ].filter((item) => item?.condition());
  };
  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  const clearInputValue = () => {
    setAmountToTransfer(0);
    setAmountError("");
  };

  const handleToggleConfirmationModal = () => {
    dispatch(ToggleConfirmationModal());
  };

  const handleFirstConfirmation = () => {
    handleToggleConfirmationModal();
    setAmountError();
  };

  const handleSecondConfirmation = () => {
    const action = () => {
      clearInputValue();
      toast.success("Request has been approved successfully");
    };
    const requestData = {
      body: {
        money_transfer_request: {
          transfered_amount: amountToTransfer,
        },
      },
      id: selectedRow?.rowData?.id?.props?.children,
      action,
    };
    dispatch(FulfillMoneyTransferRequest(requestData));
  };

  const handleInputChange = (e) => {
    setAmountToTransfer(e.target.value);
  };

  const confirmationModal = () => {
    return (
      <ConfirmationModal
        text={[`Fulfill confirmation`, `Are you sure you want to Fulfill this amount?`]}
        mainColor={vivideOrange}
        onConfirm={handleSecondConfirmation}
        confirmBtnTitle="yes"
      />
    );
  };

  const TransferMoneyModal = () => {
    return (
      <TransferMoneyRequestModal
        value={amountToTransfer}
        onChange={handleInputChange}
        requestedAmount={details?.requested_amount}
        onConfirm={handleFirstConfirmation}
        onCancel={clearInputValue}
        errorMsg={amountError}
        setErrorMsg={setAmountError}
        currency={details?.currency?.lookup_key}
      />
    );
  };

  const modalComponents = [confirmationModal, TransferMoneyModal];

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading || ""} />
      <TransferMoneyRequestsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default TransferMoneyRequestsPage;
