import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";

import "./DatePicker.css";
import dayjs from "dayjs";
import React, { useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function BasicDatePicker({
  inputFormat = "DD-MM-YYYY",
  value,
  onChange,
  placeholder,
  dateMsgSelect,
  className,
  name,
  id,
  redBorder,
  errorText,
  createLis,
  sx,
  minDate,
  maxDate,
  label,
}) {
  const [cleared, setCleared] = React.useState(false);
  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  return (
    <Stack className={className} gap={1}>
      {label && <Typography sx={{ fontWeight: 500 }}>{label}</Typography>}

      <DesktopDatePicker
        name={name}
        id={id}
        value={typeof value === "undefined" ? null : value}
        onChange={(newValue) => onChange(newValue)}
        minDate={minDate ? dayjs(minDate) : null}
        maxDate={maxDate ? dayjs(maxDate) : null}
        // onChange={(newValue) => setValue(dayjs(new Date(newValue).toLocaleDateString()))}
        renderInput={(params) => <TextField {...params} />}
        label={typeof value === "undefined" && createLis === true ? dateMsgSelect : placeholder}
        format={inputFormat}
        className={`${typeof value === "undefined" && createLis === true && redBorder}`}
        slotProps={{
          field: { clearable: true, onClear: () => setCleared(true) },
        }}
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "5px",
          "& .MuiInputBase-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          ...sx,
        }}
      />
    </Stack>
  );
}
