import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import CFormErrors from "./CFormErrors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useThemePalette } from "common/hooks/theme_palette";
import { useState, useEffect } from "react";
import SearchableSelect from "components/Atoms/Input/Select/SearchableSelect";
const SelectOptions = ({
  options,
  changeAction,
  label,
  handleOpen,
  handleScroll,
  width = "100%",
  isMulti,
  defaultValues,
  direction = "row",
  name,
  formik,
  onChange,
}) => {
  const value = formik?.values[name];
  const errorsMsg = formik.errors[name];
  const { orange } = useThemePalette();

  function handleOnScroll(e) {
    const bottom = e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop;
    if (bottom) {
      handleScroll();
    }
  }
  return (
    <Stack
      w={width || "100%"}
      p="0"
      justifyContent="center"
      // flexDirection={direction}
      alignItems={direction == "column" ? "" : "baseline"}
    >
      {label && (
        <Typography
          as="span"
          sx={{
            color: "#797979",
            fontSize: "18px",
            mb: 2,
          }}
        >
          {label}
        </Typography>
      )}
      <FormControl fullWidth>
        {value ? null : (
          <InputLabel // Use InputLabel
            htmlFor="demo-simple-select-label"
            sx={{
              transformOrigin: "center",
              "&.Mui-focused ": {
                zIndex: -1,
                color: "transparent",
              },
            }}
          >
            {label}
          </InputLabel>
        )}
        <Select
          IconComponent={KeyboardArrowDownIcon}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // defaultValues={defaultValues}
          value={value?.id ? value?.id : value ? value : ""}
          multiple={isMulti}
          onChange={(e) => {
            changeAction(e.target.value);
            onChange && onChange(e.target.value);
          }}
          sx={{
            background: "#FBFBFB",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "gray",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: orange,
            },
          }}
          onOpen={handleOpen}
          MenuProps={{
            PaperProps: {
              onScroll: (event) => {
                handleOnScroll(event);
              },
              sx: {
                maxHeight: "300px", // Set your desired height here
              },
            },
          }}
        >
          {options?.map((data, index) => (
            <MenuItem key={index} value={data?.id} sx={{ height: "50px" }}>
              {data?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {errorsMsg && <CFormErrors errorsMsg={errorsMsg} />}
    </Stack>
  );
};

export default SelectOptions;
