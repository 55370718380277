/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  bookings: [],
  details: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function BookingsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_BOOKINGS_SUCCESS: {
      return {
        ...state,
        bookings: payload?.data?.reservations,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_BOOKING_DETAILS_SUCCESS: {
      return {
        ...state,
        details: payload?.data?.reservation,
      };
    }

    case Types.REFUND_SUCCESS: {
      return {
        ...state,
        details: payload?.data?.reservation,
      };
    }
    case Types.BOOKINGS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
