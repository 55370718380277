import React from "react";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { ReactComponent as filterIcon } from "../../../assets/icons/icon_filter.svg";
import SvgIcon from "@mui/material/SvgIcon";
import { useEffect } from "react";
import Search from "components/Atoms/Input/Search/Search";
import SearchableSelect from "components/Atoms/Input/Select/SearchableSelect";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";

import { Button, Divider, FormControl, Grid, IconButton, MenuItem, Paper, Stack, Typography } from "@mui/material";
// import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import "./filter.css";
const Filter = ({ filterList, handleApplyFilter, handleResetFilterMenu }) => {
  const [filtersSelectedOptions, setFiltersSelectedOptions] = useState();

  const [data, setData] = useState(null);

  useEffect(() => {
    const timeId =
      data &&
      setTimeout(() => {
        data?.list?.onChange(data?.event?.target?.value);
        // handlePageChange(1);
      }, 500);
    return () => clearTimeout(timeId);
  }, [data]);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu" style={{ flexWrap: "wrap" }}>
      {(popupState) => (
        <React.Fragment>
          <Button
            variant="outlined"
            startIcon={<SvgIcon component={filterIcon} inheritViewBox sx={{ color: "transparent" }} />}
            sx={{
              height: "auto",
              color: "#333132",
              borderRadius: "4px",
              border: "solid 1px #ececec",
              fontSize: "16px",
              fontWeight: 500,
              textTransform: "capitalize",
              lineHeight: "unset",
              padding: "7px 15px 8px",
              backgroundColor: "#FFFFFF",
            }}
            {...bindTrigger(popupState)}
          >
            Filter
          </Button>

          <Menu
            {...bindMenu(popupState)}
            style={{ padding: 0 }}
            id="filter-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Paper>
              <Typography px={2} py={1} className="filter-menu-item-head">
                More filters
              </Typography>
              <Divider />
              {filterList?.map((data) => {
                return (
                  <Stack px={2} py={1} gap={1} key={data?.key}>
                    <Typography className="filter-menu-item-fieldhead">{data?.title}</Typography>
                    <FormControl fullWidth>
                      {data?.type === "search" ? (
                        <Search
                          width={data.width}
                          placeholder={data?.placeholder}
                          onChange={(event) => setData({ event, data })}
                        />
                      ) : data?.type === "date" ? (
                        <BasicDatePicker
                          onChange={data?.onChange}
                          value={data?.value}
                          sx={data?.sx}
                          minDate={data?.minDate}
                          maxDate={data?.maxDate}
                        />
                      ) : (
                        <SearchableSelect
                          options={data?.options}
                          onChange={data?.onChange}
                          value={data?.value}
                          onInputChange={data?.onInputChange}
                          placeholder={data?.placeholder}
                          isLoading={data?.isLoading}
                          filterType={'extra-filter'}
                        />
                      )}
                    </FormControl>
                  </Stack>
                );
              })}

              <Stack flexDirection={"row"} padding={"17px"} gap={"15px"}>
                <Button
                  fullWidth
                  sx={{ padding: "0px 55px" }}
                  className="cancel-btn"
                  onClick={() => {
                    setFiltersSelectedOptions({});
                    popupState.close();

                    // handleResetFilterMenu();
                  }}
                >
                  <Typography fontSize={"16px"} fontWeight={400}>
                    Cancel
                  </Typography>
                </Button>

                <Button
                  fullWidth
                  sx={{ padding: "0px 55px" }}
                  className="apply-btn"
                  onClick={() => {
                    handleApplyFilter();
                    popupState.close();
                  }}
                >
                  Apply
                </Button>
              </Stack>
            </Paper>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default Filter;
