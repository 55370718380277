import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import Select, { components } from "react-select";
import { useThemePalette } from "common/hooks/theme_palette";

const SearchableSelect = ({
  options,
  onChange,
  value,
  placeholder,
  id,
  name,
  defaultValue,
  sx,
  count,
  onMenuOpen,
  applySearchFromRequest = false,
  getList,
  isClearable = true,
  icon,
  filterType,
  ...rest
}) => {
  const { orange, lightGray, gray } = useThemePalette();
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null);
  const handelOptions = options?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      color: val?.color,
    };
  });

  // colorStyles using to add custom color to each option in list
  // every option must have a color property to customize it
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        color: data.color,
      };
    },
  };

  const containerStyles = {
    container: (provided, state) => ({
      ...provided,
      display: "flex",
      height: "60px",
      width: "200px",
      zIndex: filterType ? null : 20,
    }),
  };

  const dropdownStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#767676",
      fontSize: "25px",
      scale: "1.2",
    }),
  };

  const controlStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: "none",
      borderRadius: "6px",
      height: "55px",
      borderColor: state.isFocused ? orange : "transparent", // Change border color based on focus state
      boxShadow: state.isFocused ? `0 0 0 1px ${orange}` : "none", // Add a border shadow on focus
    }),
  };

  const indicatorSeparatorStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };

  const handleMenuScrollToBottom = () => {
    if (options?.length < count) {
      getList({
        page_number: Math?.ceil(options?.length / 10 + 1),
      });
    }
  };
  const handleInputChange = (value, event) => {
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }
    const timeout = setTimeout(() => {
      if (event?.action == "input-change") getList({ keyword: value });
    }, 500);
    setSearchFilterTimeout(timeout);
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <Stack sx={{ width: "100%" }}>
        <Stack style={{ alignItems: "center", width: "90%", alignSelf: "center" }} direction="row">
          {icon && icon}
          {children}
        </Stack>
      </Stack>
    </components.Control>
  );
  return (
    <>
      <Select
        id={id}
        name={name}
        components={{ Control: CustomControl }}
        options={handelOptions}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        loadingMessage={() => <p>loading...</p>}
        onKeyDown={(e) => e.stopPropagation()}
        isClearable={isClearable}
        styles={{
          ...containerStyles,
          ...controlStyles,
          ...dropdownStyles,
          ...indicatorSeparatorStyles,
          ...(handelOptions[0]?.color !== undefined && colorStyles),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? gray : "white", // Change the background color of the selected menu item here
              color: data.color,
              "&:hover": {
                backgroundColor: isSelected ? gray : lightGray, // Change the background color of the hovered menu item here
              },
            };
          },
        }}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onMenuOpen={onMenuOpen && onMenuOpen}
        onInputChange={applySearchFromRequest && ((value, event) => handleInputChange(value, event))}
        {...rest}
      />
    </>
  );
};

export default SearchableSelect;
