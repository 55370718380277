import { makeFilterString } from "../utils/Helper";
import {
 JourneysLoading, GetJourneySuccess, GetJourneyDetailSuccess, GetParticipantsSuccess,GetExpertsSuccess
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";



export const GetJourneysRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Journeys",
    isLoading: JourneysLoading,
    successFunction: GetJourneySuccess,
    url: `/admin/journeys${makeFilterString(variables?.params)}`, //removed for now , there is some error (pagination)
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetJourneyDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: JourneysLoading,
    loadingType: "Journey details",
    successFunction: GetJourneyDetailSuccess ,
    url: `/admin/journeys/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const GetParticipantsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Participants",
    isLoading: JourneysLoading,
    successFunction: GetParticipantsSuccess,
    url: `/admin/journeys/${variables.id}/participants`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const GetExpertsRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "Experts",
    isLoading: JourneysLoading,
    successFunction: GetExpertsSuccess,
    url: `/admin/users/user${makeFilterString(variables?.params)}`,
    method: "get",
  };
//classification=expert
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
