import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

import { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import setAuthToken from "services/modules/utils/handel_api";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";

function CUpload({
  placeholder,
  btnStyle,
  inputStyle,
  btnText,
  type,
  label,
  name,
  formik,
  changeAction,
  text,
}) {

  const multinames = name?.split(".")?.map((data) => data);
  const value =
    multinames?.length === 2
      ? formik?.values?.[multinames[0]]?.[multinames[1]]
        ? formik?.values?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik?.values[name];

  const {
    load: { loading },
  } = useSelector((state) => state?.files);
  const [fileUrl, setFileUrl] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const { orange, textGray } = useThemePalette();
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);
  const maxUploadSize = 1; //until sys config is decided 


  const dispatch = useDispatch();
  const { oliveGreen } = useThemePalette();

  useEffect(() => {
    if(value)setFileUrl(value)
  }, [value])

  const handleClose = () => {
    setFileUrl(null);
    changeAction("");
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      uploadFile(files[0], type);
    }
  };

  const uploadFile = async (file, type) => {
    const imageExe = ["png", "jpg", "jpeg"];
    const extension = file?.type.slice(file?.type.indexOf("/") + 1);
    try {
      if (file.size / 1024 > +maxUploadSize?.value) {
        setError(
          `file size must be less than ${maxUploadSize?.value}KB`
        );
        return;
      }
      if (!imageExe?.includes(extension)) {
        setError("file must be png , jpg or jpeg");
        return;
      }
      setError(null);
      setOpenLoader(true);
      const requestData = {
        body: {
          files: [
            {
              extension: file?.type.slice(file?.type.indexOf("/") + 1),
            },
          ],
        },
        file: file,
        updateData: (data) => {
          console.log(data)
          changeAction(data);
          setFileUrl(data);
        },
        action: (data) => {
          setOpenLoader(false);
          const token = Cookies.get("token");
          setAuthToken(token);
        },
      };
      dispatch(getPresignedUrlRequest({ ...requestData }));
      console.log(multinames)

    } catch (error) {
      console.error("Error in uploadFile:", error);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file, type);
    }
  };
  // console.log(type, 'type');
  return (
    <Grid container>
      <Stack sx={{ mb: "15px" }}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: "500" }}
        >
          {label}
        </Typography>
      </Stack>
      {fileUrl ? (
        <Grid
          Grid
          item
          xs={12}
          sx={{
            border: " 1px dashed rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            position: "relative",
            minHeight: "200px",
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <Stack
            justifyContent='center'
            alignItems='center'
            // height='100%'
            minHeight='214px' // Set a fixed height for the stack

          >
            <Box
              component="img"
              src={fileUrl}
              alt="img"
              width={type == 'icon' ? '95px' : '100%'}
              height={type == 'icon' ? '95px' : '100%'}
              maxHeight='260px'
              sx={{
                borderRadius: "10px",
                objectFit: 'contain'
              }}
            />
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "48px",
              height: "48px",
              bgcolor: "white",
              borderRadius: "50%",
              filter: "drop-shadow(0px 0px 4px rgba(185, 185, 185, 0.25))",
              transform: "translate(50%, -30%)",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Stack>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              border: " 1px dashed rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
            maxHeight="260px"
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <label htmlFor={`upload-${name}`}>
              <input
                id={`upload-${name}`}
                name={`upload-${name}`}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <Stack alignItems="center" rowGap={"24px"} py="3.5rem">
                {loading ? (
                  <CircularProgress sx={{ color: oliveGreen }} />
                ) : (
                  <>
                    <img src={icons?.uploadIcon} alt="upload icon" />
                    <Typography fontSize="13px">
                      Select a file or drag and drop here
                    </Typography>
                    {text && (
                      <Typography fontSize="12px" color={textGray}>
                        {text} {maxUploadSize?.value} KB
                      </Typography>
                    )}
                    <Button
                      component="span"
                      sx={{
                        color: orange,
                        border: `1px solid ${orange}`,
                        fontSize: "15px",
                        px: "16px",
                      }}
                    >
                      Select File
                    </Button>
                  </>
                )}
              </Stack>
            </label>
          </Grid>
          <Typography color="red" mt="8px" fontSize="14px">
            {error}
          </Typography>
        </>
      )}
    </Grid>
  );
}

export default CUpload;
