import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import {
  GetMultipleQuestionsRequest,
  GetMultipleQuestionDetailRequest,
  AddMultipleQuestionRequest,
  updateMultipleQuestionRequest,
  DeleteMultipleQuestionRequest,
} from "services/modules/lookups";
import ExpertQuestionsMultipleTemplate from "templates/ExpertQuestionsMultiple";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { width } from "@mui/system";
import MultipleChoicesForm from "components/Molecules/MultipleChoicesForm/MultipleChoicesForm";
import { formatDate } from "common/utils/helpers";

function ExpertQuestionsMultiplePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.lookup.MultipleQuestions);
  const count = useSelector((state) => state.lookup.count);
  const details = useSelector((state) => state.lookup.MultipleQuestion);
  const load = useSelector((state) => state.lookup.isloading);

  const [multipleChoices, setMultipleChoices] = useState([
    { option: "", width: "47%" },
    { option: "", width: "47%" },
  ]);
  const [multipleChoicesTrash, setmultipleChoicesTrash] = useState([]);

  useEffect(() => {
    if (type === "form" && !id) {
      setMultipleChoices([
        { option: "", width: "47%" },
        { option: "", width: "47%" },
      ]);
    }
  }, [type, id]);

  const handleAddOption = () => {
    setMultipleChoices([...multipleChoices, { option: "", width: "47%" }]);
  };
  const handleDeleteOption = (idx) => {
    if (id && multipleChoices[idx].id) {
      // if it's in edit mode then store deleted options in the trash so we can send it later to BE
      setmultipleChoicesTrash([...multipleChoicesTrash, multipleChoices[idx]]); // and that option is has an id (already in db)
    }
    // setmultipleChoicesTrash([...multipleChoicesTrash,])
    const expert_request_question_options = multipleChoices.filter((_, index) => index !== idx);
    selectedRow?.formikData?.setValues({
      ...selectedRow?.formikData?.values,
      expert_request_question_options,
    });
    setMultipleChoices(expert_request_question_options);
  };
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(GetMultipleQuestionDetailRequest({ id }));
  };
  // details of a single user
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  useEffect(() => {
    if (Object.keys(details).length) {
      const options = details?.expert_request_question_options?.map((item, index) => ({
        option: item.option,
        id: item.id,
        width: "47%",
      }));
      setMultipleChoices(options);
    } //check if details is not empty
  }, [details]);

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: page,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetMultipleQuestionsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      created_at: <>{formatDate(data?.created_at)}</>,
      updated_at: <>{formatDate(data?.updated_at)}</>,
      delete_field: "multi chocies",
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },

    {
      active: !type ? false : true,
      path: "/expertquestions/multichoices",
      title: "Lookups/Expert Questions/Multi Choices",
    },
    type && {
      active: false,
      title: type === "details" ? "Multi Choices Details" : id ? "Edit Multi Choices" : "Add Multi Choices",
    },
  ];
  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/expertquestions/multichoices");
      !id && formik?.resetForm();
      setMultipleChoices([
        { value: "", width: "47%" },
        { value: "", width: "47%" },
      ]);
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(`a Multi choice question has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: {
        expert_request_question: {
          question: data?.question,
          description_header: data?.description_header,
          description: data?.description,
          is_available: true,
          is_required: true,
          expert_request_question_options_attributes: data?.expert_request_question_options?.map((obj) => ({
            option: obj.option,
            ...(obj.id !== undefined ? { id: obj.id } : {}),
          })),
        },
        deleted_option_ids: multipleChoicesTrash?.map((option) => option.id),
      },
      action,
    };

    console.log(requestData.body);

    if (id) {
      //update call
      dispatch(updateMultipleQuestionRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(AddMultipleQuestionRequest({ ...requestData }));
    }
  };

  const filterList = [];

  const handleDelete = (id) => {
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }

      type === "details" && navigate("/expertquestions/multichoices");
      toast.success("a Multi choice question has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(DeleteMultipleQuestionRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  const formChildren = () => {
    return (
      <MultipleChoicesForm
        head="Add Chooses to question ( 2 at least )"
        width="100%"
        className={"main-form"}
        data={multipleChoices}
        handleAddOption={handleAddOption}
        handleDeleteOption={handleDeleteOption}
        onChange={(event, index) => {
          const expert_request_question_options = multipleChoices.map((choice, idx) =>
            +idx === +event.target.id ? { ...choice, option: event.target.value } : choice
          );
          setMultipleChoices(expert_request_question_options);
          selectedRow?.formikData?.setValues({
            ...selectedRow?.formikData?.values,
            expert_request_question_options,
          });
        }}
      />
    );
  };

  return (
    <>
      <Loader open={load?.loading && load?.type == "multiple questions"} />
      <ExpertQuestionsMultipleTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        formChildren={formChildren()}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default ExpertQuestionsMultiplePage;
