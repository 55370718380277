import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useDispatch } from "react-redux";
import { ToggleRejectionReasonModal } from "services/modules/modal/Actions";
import CActions from "../Crud/components/actions";
import { useRef, useState } from "react";

export default function DetailsStatus({ details, actionsList, isVerificationRequest }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const status = details?.status;

  const statuses = {
    pending: "in-review",
    pending_approval: "in-review",
    approved: isVerificationRequest ? "verified" : "approved",
    rejected: "rejected",
  };

  return (
    details?.status && (
      <Stack direction="row">
        <Stack sx={{ width: "fit-content", gap: 1, mb: 2 }} direction="row" alignItems="center">
          <Typography>Status:</Typography>
          <Typography
            color={status == "approved" ? "#34C759" : status == "pending_approval" || status == "pending" ? "#FFCC00" : "#FF3B30"}
            sx={{ textTransform: "capitalize", fontWeight: 500 }}
          >
            {statuses[status]}
          </Typography>
          {status == "rejected" && (
            <Stack direction="row" alignItems="center" gap={0.2} sx={{ color: "red" }}>
              (<Box component="img" width="16px" height="16px" src={icons.eye} />
              <Typography
                sx={{ fontSize: "18px", color: "red", cursor: "pointer" }}
                onClick={() => dispatch(ToggleRejectionReasonModal({ type: "display", isOpen: true }))}
              >
                View Reason
              </Typography>
              )
            </Stack>
          )}
        </Stack>
        {/* actions */}
        <CActions
          id={details?.id}
          index={0}
          data={""}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorRef={anchorRef}
          open={open}
          anchorEl={anchorEl}
          actionsList={() => actionsList(0, details)}
        />
      </Stack>
    )
  );
}
