import Types from "./Types";

export const GetContactsSuccess = (payload) => ({
  type: Types.GET_CONTACTS_SUCCESS,
  payload,
});
export const GetContactDetailsSuccess = (payload) => ({
  type: Types.GET_CONTACT_DETAILS,
  payload,
});



export const ContactsLoading = (payload) => ({
  type: Types.CONTACTS_LOADING,
  payload,
});


