import { Box, Button, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Header({ title, children, deleteBtn = true, editBtn = true, setOpenDeleteModal }) {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { oliveGreen, red, vivideOrange } = useThemePalette();
  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent="center" sx={{ flexGrow: 1 }}>
      <Stack width="50%">
        <Typography fontSize="32px" fontWeight="600" gutterBottom>
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" width="50%" gap={2} alignSelf="end" justifyContent="end">
        {children}
        {deleteBtn && (
          <Button
            sx={{
              color: red,
              border: `1px solid ${red}`,
            }}
            onClick={(_) => setOpenDeleteModal(true)}
          >
            <Box
              component="img"
              src={icons.templateDeleteIcon}
              alt="edit icon"
              width="24px"
              height="24px"
              sx={{ position: "absolute", left: "20px" }}
            />
            Delete
          </Button>
        )}
        {editBtn && (
          <Button
            onClick={(_) => navigate(`?type=form&id=${id}`)}
            variant="contained"
            sx={{
              py: 1,
              textTransform: "none",
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
              backgroundColor: vivideOrange,
              "&:hover": {
                backgroundColor: vivideOrange,
              },
            }}
          >
            <Box
              component="img"
              src={icons.editWhite}
              alt="edit icon"
              width="24px"
              height="24px"
              sx={{ position: "absolute", left: "20px" }}
            />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              Edit
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
