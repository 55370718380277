import { makeFilterString } from "../utils/Helper";
import {
  GetOnBoardingDetailSuccess,
  GetOnBoardingSuccess,
  AddOnBoarding,
  OnBoardingLoading,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

// Main categories

export const GetOnBoardingRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "OnBoarding",
    isLoading: OnBoardingLoading,
    successFunction: GetOnBoardingSuccess,
    url: `/admin/lookups/onboardings${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetOnBoardingDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OnBoardingLoading,
    loadingType: "OnBoarding details",
    successFunction: GetOnBoardingDetailSuccess ,
    url: `/admin/lookups/onboardings/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const updateOnBoardingRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OnBoardingLoading,
    loadingType: "update OnBoarding",
    url: `/admin/lookups/onboardings/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const AddOnBoardingRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OnBoardingLoading,
    successFunction: AddOnBoarding,
    loadingType: "Add OnBoarding",
    url: `/admin/lookups/onboardings`,
    action: variables?.action,
    data: variables?.body,
    method: "POST",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteOnBoardingRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OnBoardingLoading,
    loadingType: "delete OnBoarding",
    action: variables?.action,
    url: `/admin/lookups/onboardings/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

