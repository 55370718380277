import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().label("Password"),
  });


export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required().label("Password"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], `Passwords don't match`),
  });


export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});