import Types from "./Types";

export const GetJourneyCancellationReasonsSuccess = (payload) => ({
  type: Types.GET_JOURNEY_CANCELLATION_REASONS_SUCCESS,
  payload,
});

export const GetJourneyCancellationReasonDetailsSuccess = (payload) => ({
  type: Types.GET_JOURNEY_CANCELLATION_REASON_DETAILS_SUCCESS,
  payload,
});

export const AddJourneyCancellationReasonSuccess = (payload) => ({
  type: Types.ADD_JOURNEY_CANCELLATION_REASON_SUCCESS,
  payload,
});

export const JourneyCancellationReasonsLoading = (payload) => ({
  type: Types.JOURNEY_CANCELLATION_REASONS_LOADING,
  payload,
});
