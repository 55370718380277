import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { GetReviewsDetailsRequest, GetReviewsRequest } from "services/modules/users/experts/reviews";
import ReviewsDetails from "components/Molecules/Reviews/ReviewsDetails";
import ReviewsCard from "components/Molecules/Reviews/ReviewsCard";
import { useThemePalette } from "common/hooks/theme_palette";

export default function Reviews({ id }) {
  const dispatch = useDispatch();
  const { gray5 } = useThemePalette();

  const reviews = useSelector((state) => state.reviews.reviews);
  const details = useSelector((state) => state.reviews.details);
  const isLoading = useSelector((state) => state.reviews.isloading);
  const isReviews = reviews.length >= 1;

  useEffect(() => {
    const requestData = {
      params: {
        reviewee_id: id,
      },
    };
    dispatch(GetReviewsRequest(requestData));
    dispatch(GetReviewsDetailsRequest(requestData));
  }, [id]);

  return (
    <>
      <Loader open={isLoading?.loading || ""} />
      <Stack sx={{ bgcolor: "#fff", p: 3 }} direction="row" gap={1}>
        <ReviewsDetails details={details} />
        <Stack sx={{ width: "55%", height: "65vh", overflowY: "auto" }} gap={2}>
          {isReviews ? (
            <>
              {reviews?.map((review) => (
                <ReviewsCard review={review} />
              ))}
            </>
          ) : (
            <Stack alignItems={"center"}>
              <Typography sx={{ fontSize: "32px", color: gray5 }}>No Reviews</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}
