import { Button, Divider, Input, Modal, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch, useSelector } from "react-redux";
import { ToggleTransferMoneyRequestModal } from "services/modules/modal/Actions";
import CloseIcon from "@mui/icons-material/Close";

export default function TransferMoneyRequestModal({
  onConfirm,
  onCancel,
  requestedAmount,
  currency,
  value,
  onChange,
  errorMsg,
  setErrorMsg,
}) {
  const { vivideOrange } = useThemePalette();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    pb: 4,
    outline: "none",
  };
  const isOpen = useSelector((state) => state.modal.isOpenTransferMoneyRequestModal);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (value <= 0 || value > requestedAmount) {
      setErrorMsg(() => "Invalid amount");
    } else {
      onConfirm && onConfirm();
      dispatch(ToggleTransferMoneyRequestModal());
    }
  };
  const handleClose = () => {
    onCancel && onCancel();
    dispatch(ToggleTransferMoneyRequestModal());
  };

  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Stack sx={style}>
        <Stack alignItems="center" direction="row" sx={{ p: 3 }} justifyContent="space-between">
          <Typography
            component="h1"
            style={{
              fontWeight: 600,
              fontSize: "32px",
              textTransform: "capitalize",
            }}
          >
            Fulfill request
          </Typography>
          <CloseIcon onClick={handleClose} fontSize="large" />
        </Stack>
        <Divider sx={{ border: "1px solid #EAEAEA" }} />
        <Stack sx={{ p: 3 }} gap={4}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Requested amount :</Typography>{" "}
            <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>{requestedAmount} </Typography>
            <Typography sx={{ fontSize: "24px", fontWeight: 400 }}>{currency} </Typography>
          </Stack>

          <Stack>
            <Typography sx={{ fontWeight: 400, fontSize: "18px", color: "#565656" }}>Enter transferred amount</Typography>
            <Stack direction="row" alignItems="center">
              <Input
                sx={{ py: 2, width: "90%", backgroundColor: "#FBFBFB", pl: 1 }}
                type="number"
                inputProps={{ min: 0, inputMode: "none" }}
                disableUnderline={true}
                value={value}
                onChange={onChange}
              />
              <Typography sx={{ fontWeight: 600, fontSize: "20px", fontFamily: "Jost", p: 2 }}>{currency}</Typography>
            </Stack>
            <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ border: "1px solid #EAEAEA" }} />
        <Stack alignItems="center" mt={4}>
          <Stack direction="row" gap={2}>
            <Button
              sx={{
                color: "black",
                border: "1px solid #000000",
                py: 2,
              }}
              className="r-25px"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: vivideOrange,
                "&:hover": {
                  backgroundColor: vivideOrange,
                },
                color: "white",
                textTransform: "capitalize",
              }}
              className="primary r-25px"
              onClick={handleConfirm}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
