import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { initialValues, structure, validationSchema } from "./structure";
// import { GetMainCategoriesRequest } from "services/modules/lookups";
const CitiesTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  getCountriesList,
  countriesCount,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const [supportedLang, setSupportedLang] = useState("en");

  let countries = useSelector((state) => state.lookup.Countries);
  countries = [details?.country, ...countries];

  const handleMenuScrollToBottom = () => {
    let page_number = Math?.ceil(countries?.length / 10);
    if (countries?.length < countriesCount) {
      getCountriesList({
        page_number,
      });
    }
  };

  const onMenuOpen = () => {
    // 1 because by default we add the initial values
    countries?.length == 1 && getCountriesList({});
  };

  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data, // data
            listTitle: "Cities", //title
            // createBtnTitle: "Add", // action
            // CreateBtnIcon: AddIcon,
            // createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "localized_attributes.en.name",
                Header: "City name (EN)",
                sort: true,
              },
              {
                accessor: "localized_attributes.ar.name",
                Header: "City name (AR)",
                sort: true,
              },
              { accessor: "country.name", Header: "Country name", sort: true },
              { accessor: "lookup_key", Header: "Lookup", sort: true },
              { accessor: "created_at", Header: "Created At", sort: true },
              { accessor: "updated_at", Header: "Updated At", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search Cities",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
            propertyName: "delete_field",
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(supportedLang, countries, onMenuOpen, handleMenuScrollToBottom),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit City" : "Add City",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default CitiesTemplate;
