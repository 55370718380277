import AddIcon from "@mui/icons-material/Add";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import { useNavigate } from "react-router-dom";
import { structure, validationSchema } from "./structure";

const SystemNotificationsTemplate = ({
  handlePageChange,
  data,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data, // data
            listTitle: "Notifications", //title
            createBtnTitle: "Add notification", // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              { accessor: "localized_attributes.en.title", Header: "Title ( EN )", sort: true },
              { accessor: "localized_attributes.ar.title", Header: "Title( AR )", sort: true },
              { accessor: "localized_attributes.en.message", Header: "Notification ( EN )  ", sort: true },
              { accessor: "localized_attributes.ar.message", Header: "Notification ( AR )  ", sort: true },
              { accessor: "sent_at", Header: "Date", sort: true },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search Notifications",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
          }}
          form={{
            initialValues: {},
            structure: () => structure(),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: "Add Notifications ",
            submitTitle: "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default SystemNotificationsTemplate;
