import Types from "./Types";

export const isOpenAddAddress = () => ({
  type: Types.IS_OPEN_ADD_ADDRESS,
});

export const ToggleConfirmationModal = () => ({
  type: Types.TOGGLE_CONFIRMATION_MODAL,
});

export const ToggleCancellationReasonModal = () => ({
  type: Types.TOGGLE_CANCELLATION_REASON_MODAL,
});

export const ToggleTransferMoneyRequestModal = () => ({
  type: Types.TOGGLE_TRANSFER_MONEY_REQUEST_MODAL,
});

export const ToggleRejectionReasonModal = (payload) => ({
  type: Types.TOGGLE_REJECTION_REASON_MODAL,
  payload,
});

export const ToggleRefundModal = (payload) => ({
  type: Types.TOGGLE_REFUND_MODAL,
  payload,
});
