import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { formatDate } from "common/utils/helpers";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import ColoredText from "components/Atoms/SharedComponents/Text/ColoredText";
import CancellationReasonModal from "components/Molecules/Models/CancellationReasonModal";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetBookingsRequest, GetBookingtDetailsRequest } from "services/modules/bookings";
import { ToggleCancellationReasonModal } from "services/modules/modal/Actions";
import { GetScrollUsersRequest } from "services/modules/users/users";
import BookingsTemplate from "templates/bookings";

function BookingsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.bookings.bookings);
  const details = useSelector((state) => state.bookings.details);
  const count = useSelector((state) => state.bookings.count);
  const load = useSelector((state) => state.bookings.isloading);
  const experts_list = useSelector((state) => state.users.users);
  const experts_count = useSelector((state) => state.users.count);

  const [selectedRow, setSelectedRow] = useState({});
  const journey = selectedRow?.rowData?.journey;
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getUsersList = (filterObj) => {
    const defaultData = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      classification: "expert",
      sort_by: "id",
    };
    dispatch(GetScrollUsersRequest({ ...defaultData, ...filterObj }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetBookingsRequest(requestData));
  };

  const getDetails = async () => {
    dispatch(GetBookingtDetailsRequest({ id }));
  };
  const joruneyStatuses = {
    inprogress: "in progress",
    finished: "finished",
    upcoming: "upcoming",
    canceled_by_expert: "Canceled by expert",
    canceled_by_user: "Canceled by adventurer",
  };

  const toggleCancelationReasonModal = () => {
    dispatch(ToggleCancellationReasonModal());
  };

  const mappedData = list?.map((data, index) => {
    const status = data?.compounded_status;
    return {
      ...data,
      status: (
        <Stack gap={1}>
          <ColoredText
            color={status === "finished" ? "green" : status === "inprogress" ? "yellow" : status === "upcoming" ? "blue" : "red"}
          >
            {joruneyStatuses[status]}
          </ColoredText>
          {status === "canceled_by_expert" && (
            <Stack direction="row" sx={{ cursor: "pointer", mx: "auto" }} onClick={() => toggleCancelationReasonModal()}>
              <Box component="img" src={icons.GreyEye} width="12px" />
              <Typography sx={{ fontSize: "12px", color: "#A3A3A3" }}>View Cancelation Reason</Typography>
            </Stack>
          )}
        </Stack>
      ),
      cost: (
        <>
          {data?.journey.price_after_discount} {data?.currency?.lookup_key}
        </>
      ),
      created_at: <>{formatDate(data?.created_at)}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/bookings",
      title: "Booking/Booking list",
    },
    type && {
      active: false,
      title: type === "details" && "Booking details",
    },
  ];

  const filterList = [
    {
      type: "SearchableSelect", // this isn't important anyways
      placeholder: "Expert",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, expert_id: value?.value };
        });
      },
      onMenuOpen: () => {
        experts_list?.length == 0 && getUsersList({});
      },
      options: experts_list?.map((expert) => {
        return {
          name: expert.username,
          id: expert.id,
        };
      }),
      getList: getUsersList,
      count: experts_count,
      applySearchFromRequest: true,
    },
    {
      type: "SearchableSelect",
      placeholder: "Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, compounded_status: value?.value };
        });
      },
      options: Object.keys(joruneyStatuses).map((key) => {
        return {
          id: key,
          name: joruneyStatuses[key],
        };
      }),
    },
    {
      type: "date",
      onChange: (value) => {
        const created_at = value ? dayjs(value).format("YYYY-MM-DD") : "";
        setFilterObj((s) => {
          return { ...s, created_at };
        });
      },
    },
  ];

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
    ];
  };

  const headerChildren = <></>;

  const CancellationReason = () => {
    return (
      <CancellationReasonModal
        cancellation={{
          reason: journey?.cancellation_reason,
          text: journey?.cancellation_reason_text,
        }}
      />
    );
  };

  const modalComponents = [CancellationReason];

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  useEffect(() => {
    if (id) getDetails();
  }, [id]);
  return (
    <>
      <Loader open={load?.loading || ""} />
      <BookingsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        details={details}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        actionsList={actionsList}
        headerChildren={headerChildren}
        modalComponents={modalComponents}
        joruneyStatuses={joruneyStatuses}
      />
    </>
  );
}

export default BookingsPage;
