import * as Yup from "yup";
export const initialValues = (data) => {};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `password`,
        kind: "input",
        type: "password",
        label: "Password",
        width: "100%",
        placeholder: "***********",
      },
      {
        name: `confirmPassword`,
        kind: "input",
        type: "password",
        label: "Confirm Password",
        width: "100%",
        placeholder: "***********",
      }

    ],
  },
];

export const validationSchema = () =>
Yup.object().shape({
  password: Yup.string().required().label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], `Passwords don't match`),
});
