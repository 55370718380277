/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  reports: [],
  details: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function reportsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_REPORTS_SUCCESS: {
      return {
        ...state,
        reports: payload?.data?.user_reports,
        count: payload?.extra?.total_count,
      };
    }

    case Types.REPORTS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
