/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  system_configurations:[],
  system_configuration:{},
  count: 0,
  isloading: {loading:null,loadingType:""},
  error: null,
};

export default function systemConfigurationReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SYSTEM_CONFIGURATIONS_LOADING:{
      return {
        ...state,
        isLoading:payload,
      };
    }
    
    case Types.GET_SYSTEM_CONFIGURATIONS_SUCCESS: {
      return {
        ...state,
        system_configurations: payload?.data?.system_configurations,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_SYSTEM_CONFIGURATION_DETAILS_SUCCESS: {
      return {
        ...state,
        system_configuration: payload?.data?.system_configuration
      };
    }

    default: {
      return state;
    }
  }
};