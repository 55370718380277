import Types from "./Types";

export const GetOnBoardingSuccess = (payload) => ({
  type: Types.GET_ONBOARDING_SUCCESS,
  payload,
});

export const GetOnBoardingDetailSuccess = (payload) => ({
  type: Types.GET_ONBOARDING_DETAIL_SUCCESS,
  payload,
});

export const AddOnBoarding = (payload) => ({
  type: Types.ADD_ONBOARDING_SUCCESS,
  payload,
});


export const OnBoardingLoading = (payload) => ({
  type: Types.ONBOARDING_LOADING,
  payload,
});
