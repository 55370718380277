import * as Yup from "yup";
export const initialValues = (data) => {
  return {    
    ...data,
    ...data?.localized_attributes
  };
};



export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Report Reason (EN)",
        width: "47%",
        placeholder:"write Reason name in English"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Report Reason (AR)",
        width: "47%",
        placeholder:"write Reason name in Arabic "

      },
      {
        name: `for`,
        kind: "radioBox",
        label: "Reason type",
        width: "47%",
        options:[{value:'journey_chat',label:'Journey Chat'},{value:'expert',label:'Expert'},{value:'post',label:'Post'}],
        readOnly:true // add this to radio boxes when you want it to be readonly in edit 
      }
    
    ],
  },
];

export const validationSchema = () =>
Yup.object().shape({
  en: Yup.object().shape({
    name: Yup.string().required('Content is required'),
  }),

  ar: Yup.object().shape({
    name: Yup.string().required('Content is required'),
  }),

  for: Yup.string().required('Position is required'),
});
