import SearchableSelect from "components/Atoms/Input/Select/SearchableSelect";
import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import Search from "components/Atoms/Input/Search/Search";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
const DataFiltration = ({ filterList, handlePageChange }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const timeId =
      data &&
      setTimeout(() => {
        data?.list?.onChange(data?.event?.target?.value);
        handlePageChange(1);
      }, 500);
    return () => clearTimeout(timeId);
  }, [data]);

  return (
    <Stack
      flexDirection="row"
      flexWrap="wrap"
      gap={"20px"}
      alignItems="center"
      // justifyContent={filterList?.length === 2 && "space-between"}
    >
      {filterList?.map((list, index) => {
        return list?.type === "search" ? (
          <Search placeholder={list?.placeholder} onChange={(event) => setData({ event, list })} width="25%" />
        ) : list?.type === "title" ? (
          <Stack direction="row" gap={1}>
            {list?.title}
          </Stack>
        ) : list?.type === "date" ? (
          <BasicDatePicker
            onChange={list?.onChange}
            value={list?.value}
            sx={list?.sx}
            minDate={list?.minDate}
            maxDate={list?.maxDate}
            label={list?.label}
          />
        ) : (
          <SearchableSelect
            options={list?.options}
            onChange={list?.onChange}
            count={list?.count && list?.count}
            getList={list?.getList && list?.getList}
            onMenuOpen={list.onMenuOpen && list.onMenuOpen}
            placeholder={list?.placeholder}
            data={list}
            applySearchFromRequest={list?.applySearchFromRequest}
          />
        );
      })}
    </Stack>
  );
};

export default DataFiltration;
