import Types from "./Types";

export const GetReportsSuccess = (payload) => ({
  type: Types.GET_REPORTS_SUCCESS,
  payload,
});

export const ReportsLoading = (payload) => ({
  type: Types.REPORTS_LOADING,
  payload,
});

