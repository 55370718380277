import { makeFilterString } from "../../utils/Helper";
import { CreateSystemNotificationSuccess, GetSystemNotificationsSuccess, SystemNotificationsLoading } from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const GetSystemNotificationsReuqest = ({ ...variables }) => {
  const requestData = {
    loadingType: "list",
    isLoading: SystemNotificationsLoading,
    successFunction: GetSystemNotificationsSuccess,
    url: `/admin/notifications/system?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const CreateSystemNotificationReuqest = ({ ...variables }) => {
  const requestData = {
    loadingType: "create",
    isLoading: SystemNotificationsLoading,
    successFunction: CreateSystemNotificationSuccess,
    url: `/admin/notifications`,
    data: variables?.body,
    action: variables?.action,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
