import dayjs from "dayjs";
import Cookies from "js-cookie";
import utc from "dayjs/plugin/utc";

export const formatCompactNumber = (number) => {
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
  }
};

export const formatNumberWithOptions = (number, style = "currency", currency = "USD") => {
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style,
    currency,
  };
  return number.toLocaleString("en-US", options);
};

export const removeDuplicateObjects = (array, identifier) => {
  const uniqueObjects = {};
  array.forEach((obj) => {
    uniqueObjects[obj[identifier]] = obj;
  });

  return Object.values(uniqueObjects);
};

export const isScrolledToBottom = (e, extra = 0) => {
  const bottom = e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop + extra;
  return bottom;
};

export const formatDate = (date = new Date(), format = "DD-MM-YYYY hh:mm A") => {
  dayjs.extend(utc);
  return dayjs.utc(date).format(format);
};
