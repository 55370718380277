import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_attributes
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Country name  (EN)",
        width: "47%",
        placeholder:"Country name  (EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Country name (AR)",
        width: "47%",
        placeholder:"Country name (AR)"

      },

    ],
  },
];

export const validationSchema = () =>
Yup.object().shape({
  en: Yup.object().shape({
    name: Yup.string().required("required"),
  }),
  ar: Yup.object().shape({
    name: Yup.string().required("required"),
  }),
});
