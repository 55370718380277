/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  reviews: [],
  details: {},
  count: 0,
  isloading: { loading: null, loadingType: "" },
  error: null,
};

export default function reviewsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: payload?.data?.reviews,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_REVIEWS_DETAILS_SUCCESS: {
      return {
        ...state,
        details: payload?.data?.reviews_details,
      };
    }
    case Types.REVIEWS_LOADING: {
      return {
        ...state,
        isloading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
