import CloseIcon from "@mui/icons-material/Close";
import { Divider, Modal, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch, useSelector } from "react-redux";
import { ToggleCancellationReasonModal } from "services/modules/modal/Actions";

const CancellationReasonModal = ({ cancellation }) => {
  const dispatch = useDispatch();
  const { red } = useThemePalette();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    py: 4,
    outline: "none",
    maxHeight: 700,
  };

  const isOpenCancellationReasonModal = useSelector((state) => state.modal.isOpenCancellationReasonModal);
  const handleClose = () => {
    dispatch(ToggleCancellationReasonModal());
  };
  return (
    <Modal
      open={isOpenCancellationReasonModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Stack gap={3} alignItems="center" justifyContent="center" sx={{ width: "100%", pb: 3 }} direction="row">
          <Stack sx={{ flexGrow: 1, textAlign: "center" }}>
            <Typography sx={{ color: red, fontSize: "24px", fontWeight: 500, ml: 5 }}>Cancelation reason</Typography>
          </Stack>
          <CloseIcon onClick={handleClose} fontSize="medium" sx={{ alignSelf: "end", px: 2, cursor: "pointer" }} />
        </Stack>
        <Divider sx={{ border: "1px solid #F5F5F5" }} />
        <Stack sx={{ p: 2, overflowY: "auto" }} gap={3}>
          <Stack>
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>Reason</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "18px" }}>{cancellation?.reason?.name || "-"}</Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>Free text</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "18px", wordBreak: "break-word", lineHeight: "23px" }}>
              {cancellation?.text || "-"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default CancellationReasonModal;
