const lookupTypes = {
  GET_MAIN_CATEGORIES_SUCCESS: "GET_MAIN_CATEGORIES_SUCCESS",
  GET_MAIN_CATEGORIES_DETAIL: "GET_MAIN_CATEGORIES_DETAIL",
  GET_SUB_CATEGORIES_SUCCESS: "GET_SUB_CATEGORIES_SUCCESS",
  CREATE_SUB_CATEGORIES_SUCCESS: "CREATE_SUB_CATEGORIES_SUCCESS",
  GET_SCROLL_SUB_CATEGORIES_SUCCESS: "GET_SCROLL_SUB_CATEGORIES_SUCCESS",

  GET_COUNTRIES_SUCCESS:"GET_COUNTRIES_SUCCESS",
  GET_SCROLL_COUNTRIES_SUCCESS: "GET_SCROLL_COUNTRIES_SUCCESS",
  GET_COUNTRY_DETAILS_SUCCESS:"GET_COUNTRY_DETAILS_SUCCESS",

  GET_SUB_CATEGORIES_DETAILS_SUCCESS:"GET_SUB_CATEGORIES_DETAILS_SUCCESS",
  ADD_SUB_CATEGORIES_SUCCESS:"ADD_SUB_CATEGORIES_SUCCESS",
  LOOKUP_LOADING: "GET_MAIN_CATEGORIES_LOADING",

  ADD_FREETEXT_QUESTIONS_SUCCESS: "ADD_FREETEXT_QUESTIONS_SUCCESS",
  GET_FREETEXT_QUESTIONS_SUCCESS: "GET_FREETEXT_QUESTIONS_SUCCESS",
  GET_FREETEXT_QUESTION_DETAIL_SUCCESS: "GET_FREETEXT_QUESTION_DETAIL_SUCCESS",

  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_DETAILS: "GET_CITIES_DETAILS",

  ADD_REPORT_REASON_SUCCESS:"ADD_REPORT_REASON_SUCCESS",
  GET_REPORT_REASONS_SUCCESS: "GET_REPORT_REASONS_SUCCESS",
  GET_REPORT_REASON_DETAIL_SUCCESS:"GET_REPORT_REASON_DETAIL_SUCCESS",

  ADD_MULTIPLE_QUESTIONS_SUCCESS:"ADD_MULTIPLE_QUESTIONS_SUCCESS",
  GET_MULTIPLE_QUESTIONS_SUCCESS: "GET_MULTIPLE_QUESTIONS_SUCCESS",
  GET_MULTIPLE_QUESTION_DETAIL_SUCCESS:"GET_MULTIPLE_QUESTION_DETAIL_SUCCESS",

};

export default lookupTypes;
